<template>
    <v-container fluid full-height grid-list-md>
         <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSave">
            <v-layout row wrap>
                <v-flex xs6>
                    <div class="text-xs-left">
                        <v-breadcrumbs :items="breadcrumbs" divider=">">
                            <template slot="item" slot-scope="props">
                                <router-link :to="'/'+props.item.to" :disabled="props.item.disabled"
                                             v-bind:class="{ 'v-breadcrumbs__item v-breadcrumbs__item--disabled': props.item.disabled }">
                                    {{props.item.text}}
                                </router-link>
                            </template>
                        </v-breadcrumbs>
                    </div>
                </v-flex>
                <v-flex xs6>
                    <div class="text-xs-right">
                        <v-btn outline color="primary" @click="goBack">
                            Go Back
                            <v-icon right>arrow_back</v-icon>
                        </v-btn>
                    </div>
                </v-flex>
                 <v-flex xs6 offset-sm3>
                    <v-card>
                        <v-layout row wrap class="pa-3">
                            <v-flex sm12 v-if="responseError">
                                <span class="red--text">{{responseError}}</span>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="prefix"
                                        label="Prefix"
                                        v-model="systemSerialize.prefix"
                                        type="text"
                                        :disabled="systemSerialize.id?true:false"
                                        :rules="[
                                                        () => !!systemSerialize.prefix || 'Prefix is required',
                                                    ]">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12>
                                <v-select
                                        :items="shopNameList"
                                        v-model="systemSerialize.shopName"
                                        label="Select shop name"
                                        :rules="[
                                                        () => !!systemSerialize.shopName || 'Shop Name is required'
                                                        ]"
                                    >
                                </v-select>
                            </v-flex>
                            <v-flex sm12>
                                <v-switch
                                        :label="`Auto Reset`"
                                        :value="autoResetFlag"
                                        v-model="autoResetFlag"
                                ></v-switch>
                            </v-flex>
                            <v-flex sm6 v-if="systemSerialize.id">
                                <v-switch
                                        :label="`Status is ${statusFlag?'Active': 'Inactive'}`"
                                        :value="statusFlag"
                                        v-model="statusFlag"
                                ></v-switch>
                            </v-flex>
                            <v-flex sm12>
                                <div class="text-xs-center">
                                    <v-btn color="error" @click="clear">
                                        Reset
                                        <v-icon right>clear</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" :loading="saveLoading" :disabled="!valid" type="submit">
                                        Save
                                        <v-icon right>save</v-icon>
                                    </v-btn>

                                </div>
                            </v-flex>
                        </v-layout>
                    </v-card>
                 </v-flex>
            </v-layout>
         </v-form>
    </v-container>
</template>

<script>
    import {systemSerializeService} from "../../services/system-serialize-service";
    import {shopService} from "../../services/shop-service";
    import {SystemSerialize} from "../../models/model";
    import VSwitch from "vuetify/lib/components/VSwitch/VSwitch";

    export default {
        name: "SystemSerializeDetail",
        components: {VSwitch},
        data: () => ({
            valid: true,
            breadcrumbs:[
                {
                    text: 'System Serialize',
                    disabled: false,
                    to: 'system-serializes'
                },
                {
                    text: 'System Serialize Detail',
                    disabled: true
                }
            ],
            systemSerialize: new SystemSerialize(),
            oldSystemSerialize: new SystemSerialize(),
            statusFlag: true,
            autoResetFlag: false,
            saveLoading: false,
            responseError: '', 
            modelError: '',
            modelSystemSerialize: new SystemSerialize(),
            shopNameList: []
        }),
        created() {
            const id = this.$route.params.id;
            if (id == 'new') {
                // new user
            }
            else {
                this.getSystemSerializeDetail(id);
            }
            this.getAllShops();
        },
        methods: {
            onSave() {
                if(this.$refs.form.validate()) {
                    this.saveLoading = true;
                    this.systemSerialize.isAutoReset = this.autoResetFlag ? 'true' : 'false';
                    this.systemSerialize.status = this.statusFlag ? 'ACTIVE' : 'INACTIVE';

                    let systemSerializeObj = {
                        id: this.systemSerialize.id ? this.systemSerialize.id : '',
                        prefix: this.systemSerialize.prefix,
                        shopName: this.systemSerialize.shopName,
                        isAutoReset: this.systemSerialize.isAutoReset,
                        status: this.systemSerialize.status
                    };
                    systemSerializeService.saveSystemSerialize(systemSerializeObj)
                    .then(res => {
                        console.log(res);
                        this.goBack();
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.data) {
                            this.responseError = err.response.data.message;
                        }
                        this.saveLoading = false;
                    });
                }
            },
            getSystemSerializeDetail(id) {
                systemSerializeService.getSystemSerializeDetail(id)
                    .then(res => {
                        this.systemSerialize = res.data;
                        this.oldSystemSerialize = Object.assign({}, res.data);
                        this.autoResetFlag = this.systemSerialize.isAutoReset == 1 ? true : false;
                        this.statusFlag = this.systemSerialize.status == 'ACTIVE' ? true : false;
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            getAllShops() {
                shopService.getAllShops()
                    .then(res => {
                        let shopList = res.data;
                        for (let shop of shopList) {
                            this.shopNameList.push(shop.name);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            clear() {
                this.$refs.form.resetValidation();
                if (!this.systemSerialize.id) {
                    this.systemSerialize = new SystemSerialize();
                    this.statusFlag = true;
                    this.autoResetFlag = false;
               }
                else {
                    this.systemSerialize = Object.assign({}, this.oldSystemSerialize);
                    this.autoResetFlag = this.systemSerialize.isAutoReset == 1 ? true : false;
                    this.statusFlag = this.systemSerialize.status == 'ACTIVE' ? true : false;
              }
            },
            goBack() {
                this.$router.push('/system-serializes');
            }
        }
    }
</script>

