<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs6>
        <div>
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex xs6>
        <div class="text-xs-right">
          <v-btn color="primary" @click="onRegister">
            New Township
            <v-icon right>add_circle</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-card>
          <v-layout row wrap class="pa-3">
            <v-flex sm3>
              <v-text-field
                name="townshipcode"
                label="Township Code"
                type="text"
                v-model="queryParams.code"
              ></v-text-field>
            </v-flex>
            <v-flex sm3 offset-sm1>
              <v-text-field
                name="townshipName"
                label="Township Name"
                type="text"
                v-model="queryParams.name"
              ></v-text-field>
            </v-flex>
            <v-flex sm3 offset-sm1 pt-2>
              <v-btn outline color="primary" @click="onSearch">
                Search
                <v-icon right>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="townshipList"
            class="elevation-1"
            :loading="showListLoading"
            :total-items="totalRows"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.code }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.deliveryFee }}</td>
              <td>{{ props.item.description }}</td>
              <td>{{ props.item.updatedDateInMilliSeconds | format-ms-date }}</td>
              <td>{{ props.item.updatedBy }}</td>
              <td>
                <v-icon v-if="props.item.deliveryAvailable" medium color="green">check_circle</v-icon>
                <v-icon v-else medium color="red">cancel</v-icon>
              </td>
              <td>
                <v-icon v-if="props.item.status=='ACTIVE'" medium color="green">check_circle</v-icon>
                <v-icon v-else medium color="red">cancel</v-icon>
              </td>
              <td>
                <v-btn flat icon color="blue lighten-2" @click="onEdit(props.item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:no-data>no data to show</template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { townshipService } from "../../services/township-services";
export default {
  data: () => ({
    headers: [
      { text: "Township Code", align: "left", value: "code" },
      { text: "Township Name", value: "name" },
      { text: "Delivery Fee", value: "deliveryFee" },
      { text: "Description", value: "description" },
      { text: "Updated Date", value: "updatedDate" },
      { text: "Updated By", value: "updatedBy" },
      { text: "Delivery Available", value: "deliveryAvailable" },
      { text: "Status", value: "status"},
      { text: "Action", value: "name" }
    ],
    breadcrumbs: [
      {
        text: "General",
        disabled: true
      },
      {
        text: "Township",
        disabled: true
      }
    ],
    queryParams: {
      name: "",
      code: ""
    },
    townshipList: [],
    showListLoading: false,
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 50,
      sortBy: "name",
      totalItems: 0
    },
    totalRows: 0,
    rowsPerPageItems: [25, 50, 100]
  }),
  watch: {
    pagination: {
      handler() {
        this.getTownshipList();
      },
      deep: true
    }
  },
  created() {
    //for deep linking
    if (this.$route.query.name) {
      this.queryParams.name = this.$route.query.name;
    }
    if (this.$route.query.code) {
      this.queryParams.code = this.$route.query.code;
    }
    if (this.$route.query.page) {
      this.pagination.page = this.$route.query.page;
    }
    if (this.$route.query.size) {
      this.pagination.size = this.$route.query.size;
    }
  },
  mounted() {
    this.removeQueryParams();
  },
  methods: {
    onSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.$router.replace({ name: "townships", query: this.queryParams });
      this.getTownshipList();
    },
    onRegister() {
      this.$router.push("/township/new");
    },
    onEdit(township) {
      // go to details page
      this.$router.push("/township/" + township.id);
    },
    getTownshipList() {
      this.showListLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";
      townshipService
        .getTownshipList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        )
        .then(res => {
          if (res.data) {
            this.townshipList = res.data.townshipList;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    removeQueryParams: function() {
      this.queryParams.name = "";
      this.queryParams.code = "";
    }
  }
};
</script>