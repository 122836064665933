<template>
    <v-container fluid full-height grid-list-md>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSave">
            <v-layout row wrap>

                <v-flex xs6>
                    <div class="text-xs-left">
                        <v-breadcrumbs :items="breadcrumbs" divider=">">
                            <template slot="item" slot-scope="props">
                                <router-link :to="'/'+props.item.to" :disabled="props.item.disabled"
                                             v-bind:class="{ 'v-breadcrumbs__item v-breadcrumbs__item--disabled': props.item.disabled }">
                                    {{props.item.text}}
                                </router-link>
                            </template>
                        </v-breadcrumbs>
                    </div>
                </v-flex>
                <v-flex xs6>
                    <div class="text-xs-right">
                        <v-btn outline color="primary" @click="goBack">
                            Go Back
                            <v-icon right>arrow_back</v-icon>
                        </v-btn>
                    </div>
                </v-flex>
                <v-flex xs6 offset-sm3>
                    <v-card>
                        <v-layout row wrap class="pa-3">
                            <v-flex sm12 v-if="responseError">
                                <span class="red--text">{{responseError}}</span>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="tableNo"
                                        label="Table No"
                                        v-model="tableInfo.tableNo"
                                        type="text"
                                        :rules="[
                                                        () => !!tableInfo.tableNo || 'Table No is required',
                                                    ]">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="flowLocation"
                                        label="Flow Location"
                                        v-model="tableInfo.flowLocation"
                                        type="text"
                                        :rules="[
                                                        () => !!tableInfo.flowLocation || 'Flow Location is required',
                                                    ]">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12>
                                <v-select
                                        :items="shopNameList"
                                        v-model="tableInfo.shopName"
                                        label="Select shop name"
                                        :rules="[
                                                        () => !!tableInfo.shopName || 'Shop Name is required'
                                                        ]"
                                ></v-select>
                            </v-flex>
                            <v-flex sm6 v-if="tableInfo.id">
                                <v-switch
                                        :label="`Status is ${statusFlag?'Active': 'Inactive'}`"
                                        :value="statusFlag"
                                        v-model="statusFlag"
                                ></v-switch>
                            </v-flex>
                            <v-flex sm12>
                                <div class="text-xs-center">
                                    <v-btn color="error" @click="clear">
                                        Reset
                                        <v-icon right>clear</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" :loading="saveLoading" :disabled="!valid" type="submit">
                                        Save
                                        <v-icon right>save</v-icon>
                                    </v-btn>

                                </div>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
    import {tableInfoService} from "../../services/table-info-service";
    import {TableInfo} from "../../models/model";
    import VSwitch from "vuetify/lib/components/VSwitch/VSwitch";

    export default {
        name: "TableInfoDetail",
        components: {VSwitch},
        data: () => ({
            valid: true,
            breadcrumbs: [
                {
                    text: 'Table Info',
                    disabled: false,
                    to: 'table-infos'
                },
                {
                    text: 'Table Info Detail',
                    disabled: true
                }
            ],
            tableInfo: new TableInfo(),
            oldTableInfo: new TableInfo(),
            statusFlag: true,
            shopNameList: [],
            saveLoading: false,
            responseError: '',
            modalError: '',
            modalTableInfo: new TableInfo()
        }),
        created() {
            const id = this.$route.params.id;
            if (id == 'new') {
                // new table info
            }
            else {
                this.getTableInfoDetail(id);
            }
            this.getAllShops();
        },
        methods: {
            getAllShops() {
                tableInfoService.getAllShops()
                    .then(res => {
                        let shopList = res.data;
                        for (let shop of shopList) {
                            this.shopNameList.push(shop.name);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })

            },
            getTableInfoDetail(id) {
                tableInfoService.getTableInfoDetail(id)
                    .then(res => {
                        this.tableInfo = res.data;
                        this.oldTableInfo = Object.assign({}, res.data);
                        this.statusFlag = this.tableInfo.status == 'ACTIVE' ? true : false;

                    })
                    .catch(err => {
                        console.log(err);
                    })

            },
            onSave() {
                if (this.$refs.form.validate()) {
                    this.saveLoading = true;
                    this.tableInfo.status = this.statusFlag ? 'ACTIVE' : 'INACTIVE';

                    let tableInfoObj = {
                        id: this.tableInfo.id ? this.tableInfo.id : '',
                        tableNo: this.tableInfo.tableNo,
                        flowLocation: this.tableInfo.flowLocation,
                        shopName: this.tableInfo.shopName,
                        status: this.tableInfo.status
                    };
                    tableInfoService.saveTableInfo(tableInfoObj)
                        .then(res => {
                            console.log(res);
                            this.goBack();
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.data) {
                                this.responseError = err.response.data.message;
                            }
                            this.saveLoading = false;
                        });
                }

            },
            clear() {
                this.$refs.form.resetValidation();
                if (!this.tableInfo.id) {
                    this.tableInfo = new TableInfo();
                    this.statusFlag = true;
                }
                else {
                    this.tableInfo = Object.assign({}, this.oldTableInfo);
                    this.statusFlag = this.tableInfo.status == 'ACTIVE' ? true : false;
                }

                this.responseError = '';
            },
            goBack() {
                this.$router.push('/table-infos');
            }
        } 
    }
</script>
