<template>
  <v-container fluid full-height grid-list-md>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSave">
      <v-layout row wrap>
        <v-flex xs6>
          <div class="text-xs-left">
            <v-breadcrumbs :items="breadcrumbs" divider=">">
              <template slot="item" slot-scope="props">
                <router-link
                  :to="'/' + props.item.to"
                  :disabled="props.item.disabled"
                  v-bind:class="{
                    'v-breadcrumbs__item v-breadcrumbs__item--disabled':
                      props.item.disabled,
                  }"
                  >{{ props.item.text }}</router-link
                >
              </template>
            </v-breadcrumbs>
          </div>
        </v-flex>
        <v-flex xs6>
          <div class="text-xs-right">
            <v-btn outline color="primary" @click="goBack">
              Go Back
              <v-icon right>arrow_back</v-icon>
            </v-btn>
          </div>
        </v-flex>
        <v-flex>
          <v-card>
            <v-layout row wrap class="pa-3">
              <v-flex sm12 v-if="responseError">
                <span class="red--text">* {{ responseError }}</span>
              </v-flex>
              <v-flex sm6>
                <v-menu
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  :disabled="true"
                >
                  <v-text-field
                    slot="activator"
                    label="Order Date"
                    type="text"
                    prepend-icon="event"
                    v-model="orderDate"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="orderDate"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex sm6>
                <v-menu
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  :disabled="true"
                >
                  <v-text-field
                    slot="activator"
                    label="Customer Request Date"
                    type="text"
                    prepend-icon="event"
                    v-model="csRequestDate"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="csRequestDate"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex sm4>
                <v-text-field
                  name="orderNo"
                  label="Order No"
                  v-model="orderHeader.orderNo"
                  type="text"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm4>
                <v-select
                  :items="statusList"
                  label="Status"
                  v-model="orderHeader.status"
                  item-text="label"
                  item-value="value"
                  :disabled="status == 'CONFIRM' || status == 'CANCEL'"
                ></v-select>
              </v-flex>
              <v-flex sm4>
                <v-menu
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  :disabled="status == 'CONFIRM' || status == 'CANCEL'"
                >
                  <v-text-field
                    slot="activator"
                    label="Estimate Delivery Date"
                    type="text"
                    prepend-icon="event"
                    v-model="estimateDeliveryDate"
                    :error-messages="eDeliDateError"
                    :disabled="orderHeader.status != 'CONFIRM'"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="estimateDeliveryDate"
                    :min="minDate"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex sm6>
                <v-text-field
                  name="customerName"
                  label="Customer Name"
                  v-model="orderHeader.customerName"
                  type="text"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm6>
                <v-text-field
                  name="customerPhoneNo"
                  label="Customer Phone No"
                  v-model="orderHeader.customerPhoneNo"
                  type="text"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm6>
                <v-text-field
                  name="deliveryAddress"
                  label="Delivery Address"
                  v-model="orderHeader.deliveryAddress"
                  type="text"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm6>
                <v-text-field
                  name="billingdeliveryAddress"
                  label="Billing Address"
                  v-model="orderHeader.billingAddress"
                  type="text"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm3>
                <v-text-field
                  name="totalAmount"
                  label="Total Amount"
                  v-model="totalAmount"
                  type="text"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm3>
                <v-text-field
                  name="discountAmount"
                  label="Discount Percentage"
                  v-model="orderHeader.discountAmount"
                  type="number"
                  min="0"
                  max="99"
                  @change="OnChange"
                  :rules="[
                    (v) =>
                      !!(v >= 0 && v <= 99) ||
                      'Discount % must be between 0 to 99',
                  ]"
                ></v-text-field>
              </v-flex>
              <v-flex sm3>
                <v-text-field
                  name="deliveryFee"
                  label="deliveryFee"
                  v-model="orderHeader.deliveryFee"
                  type="number"
                  min="0"
                  readonly
                ></v-text-field>
              </v-flex>

              <v-flex sm3>
                <v-text-field
                  name="netAmount"
                  label="Net Amount"
                  v-model="netAmount"
                  type="number"
                  min="0"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm4>
                <v-text-field
                  name="payment currency"
                  label="payment currency"
                  v-model="orderHeader.currencyCode"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm4>
                <v-text-field
                  name="exchange rate"
                  label="exchange rate"
                  v-model="orderHeader.exchangeRate"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm4>
                <v-text-field
                  name="Grand Total"
                  label="Grand Total"
                  v-model="orderHeader.customerTotalAmount"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  name="remark"
                  label="Customer Remark"
                  v-model="orderHeader.remark"
                  type="text"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  name="adminRemark"
                  label="Admin Remark"
                  v-model="orderHeader.adminRemark"
                  type="text"
                  :rules="[
                    (v) =>
                      v.length <= 250 ||
                      'Remark must be less than 250 characters',
                  ]"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 v-if="orderHeader.approvedPerson">
                <v-text-field
                  name="approvedPerson"
                  label="Approved Person"
                  v-model="orderHeader.approvedPerson"
                  type="text"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex sm6 v-if="orderHeader.approvedPerson">
                <v-menu
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  :disabled="true"
                >
                  <v-text-field
                    slot="activator"
                    label="Approved Date"
                    type="text"
                    prepend-icon="event"
                    v-model="approvedDate"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="approvedDate"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex>
                <v-layout row wrap class="light--text">
                  <v-flex xs2>Stock Name</v-flex>
                  <v-flex xs2>Order Qty</v-flex>
                  <v-flex xs2>Delivery Qty</v-flex>
                  <v-flex xs2>Defect Qty</v-flex>
                  <v-flex xs2>Price</v-flex>
                  <v-flex xs2>Remark</v-flex>
                </v-layout>
                <v-layout
                  row
                  wrap
                  v-for="(
                    orderHeaderDetail, index
                  ) in orderHeader.orderHeaderDetails"
                  :key="index"
                >
                  <v-flex xs2>
                    <v-text-field
                      name="stockName"
                      v-model="orderHeaderDetail.stock.name"
                      type="text"
                      readonly
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      name="orderQty"
                      v-model="orderHeaderDetail.orderQty"
                      type="number"
                      readonly
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      name="deliveryQty"
                      v-model="orderHeaderDetail.deliveryQty"
                      type="number"
                      min="0"
                      :max="orderHeaderDetail.orderQty"
                      disabled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      name="defectQty"
                      v-model="orderHeaderDetail.defectQty"
                      type="number"
                      min="0"
                      :max="orderHeaderDetail.orderQty"
                      disabled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      name="price"
                      v-model="orderHeaderDetail.price"
                      type="number"
                      readonly
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      name="remark"
                      v-model="orderHeaderDetail.remark"
                      type="text"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex sm12>
                <div class="text-xs-center">
                  <v-btn
                    color="primary"
                    :loading="saveLoading"
                    :disabled="
                      !valid || status == 'CONFIRM' || status == 'CANCEL'
                    "
                    type="submit"
                  >
                    Save
                    <v-icon right>save</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-form>
    <!-- Invoice print hidden area -->
    <invoice id="invoicePrint" :orderData="orderData"></invoice>
  </v-container>
</template>
<script>
import { orderDeliveryService } from "../../services/order-delivery-service";
import { OrderHeader } from "../../models/model";
import { OrderHeaderDetail } from "../../models/model";
import { commonService } from "../../services/common-service";
import { currencyService } from "../../services/currency-service";
import invoice from "./invoice";

export default {
  name: "OrderDetail",
  components: { invoice },
  data: () => ({
    valid: true,
    breadcrumbs: [
      {
        text: "Order List",
        disabled: false,
        to: "orders",
      },
      {
        text: "Order Detail",
        disabled: true,
      },
    ],
    responseError: "",
    saveLoading: false,
    minDate: new Date().toISOString().slice(0, 10),
    orderHeader: new OrderHeader(),
    orderHeaderDetail: new OrderHeaderDetail(),
    orderDate: "",
    totalAmount: 0.0,
    netAmount: 0,
    approvedDate: "",
    csRequestDate: "",
    estimateDeliveryDate: "",
    status: "",
    statusList: [
      { label: "Pending", value: "PENDING" },
      { label: "Confirm", value: "CONFIRM" },
      { label: "Cancel", value: "CANCEL" },
    ],
    eDeliDateError: "",
    orderData: {},
    baseCurrency: {},
  }),
  watch: {
    estimateDeliveryDate: {
      handler(value) {
        if (value) this.eDeliDateError = "";
      },
    },
  },
  created() {
    const id = this.$route.params.id;
    this.getOrderDetail(id);
  },
  mounted() {
    this.getBaseCurrency();
  },
  methods: {
    goBack() {
      this.$router.push("/orders");
    },
    OnChange() {
      this.orderHeader.discount =
        (this.orderHeader.discountAmount / 100.0) * this.totalAmount;

      const _netAmount =
        this.totalAmount -
        this.orderHeader.discount +
        this.orderHeader.deliveryFee;
      const _totAmount = _netAmount * this.orderHeader.exchangeRate;

      this.netAmount = Math.round((_netAmount + Number.EPSILON) * 100) / 100;
      this.orderHeader.customerTotalAmount =
        Math.round((_totAmount + Number.EPSILON) * 100) / 100;
    },
    // OnQtyChange(orderHeaderDetail) {
    //   orderHeaderDetail.deliveryQty =
    //     orderHeaderDetail.deliveryQty - orderHeaderDetail.defectQty;
    // },
    getOrderDetail(id) {
      orderDeliveryService
        .getOrderDetail(id)
        .then((res) => {
          this.orderHeader = res.data;
          this.orderHeader.currencyCode = this.orderHeader.currencyDTO.code;
          if (!this.orderHeader.adminRemark) this.orderHeader.adminRemark = "";
          // for delivery note
          this.orderHeader.orderDate = "";
          this.orderHeader.csRequestDate = "";
          this.orderHeader.estimateDeliveryDate = "";
          this.orderHeader.approvedDate = "";

          this.orderDate = commonService.formatMsToDateOnly(
            this.orderHeader.orderDateInMilliSeconds
          );
          this.orderHeader.orderDate = this.orderDate;

          this.csRequestDate = commonService.formatMsToDateOnly(
            this.orderHeader.csRequestDateInMilliSeconds
          );
          this.orderHeader.csRequestDate = this.csRequestDate;

          if (this.orderHeader.estimateDeliveryDateInMilliSeconds) {
            if (this.orderHeader.status == "CONFIRM") {
              this.estimateDeliveryDate = commonService.formatMsToDateOnly(
                this.orderHeader.estimateDeliveryDateInMilliSeconds
              );
              this.orderHeader.estimateDeliveryDate = this.estimateDeliveryDate;
            }
          }

          let _deepCp = JSON.parse(
            JSON.stringify({ value: this.orderHeader.totalAmount })
          );
          this.netAmount = _deepCp.value;
         

          if (this.orderHeader.approvedDateInMilliSeconds) {
            this.approvedDate = commonService.formatMsToDateOnly(
              this.orderHeader.approvedDateInMilliSeconds
            );
            this.orderHeader.approvedDate = this.approvedDate;
          }

          this.status = this.orderHeader.status;

          this.totalAmount = 0;
          this.orderHeader.orderHeaderDetails.forEach((i) => {
            this.totalAmount += i.price * i.orderQty;
            // if (i.stock.isPromo && i.stock.promotion.status === "ACTIVE") {
            //   this.totalAmount += i.stock.promotionPrice * i.orderQty;
            // } else {
            //   this.totalAmount += i.stock.unitPrice * i.orderQty;
            // }
          });

          // if (this.orderHeader.status == "PENDING") {
          //   for (let detail of res.data.orderHeaderDetails) {
          //     detail.deliveryQty = detail.orderQty;
          //     detail.defectQty = 0;
          //   }
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSave() {
      let user = JSON.parse(localStorage.getItem("user"));
      this.orderHeader.shopName = user.shopName;
      this.orderHeader.address = user.address;
      this.orderHeader.phoneNo = user.phoneNo;

      if (this.orderHeader.status == "CONFIRM") {
        if (!this.estimateDeliveryDate) {
          this.eDeliDateError =
            "Required estimate delivery date to confirm order.";
          return;
        }
      }
      if (this.$refs.form.validate()) {
        this.saveLoading = true;
        // save edd only on status CONFIRM. if(save edd on every status) remove (&& && this.orderHeader.status == "CONFIRM")
        if (this.estimateDeliveryDate && this.orderHeader.status == "CONFIRM") {
          this.orderHeader.estimateDeliveryDateInMilliSeconds = commonService.convertToMiliseconds(
            this.estimateDeliveryDate
          );
          // change format to same with order date
          this.estimateDeliveryDate = commonService.formatMsToDateOnly(
            this.orderHeader.estimateDeliveryDateInMilliSeconds
          );
          this.orderHeader.estimateDeliveryDate = this.estimateDeliveryDate;
        }
        this.orderHeader.totalAmount = this.netAmount;
        this.orderData = this.orderHeader;
        this.orderData.printNetAmount = this.netAmount;
        this.orderData.printTotalAmount = this.totalAmount;
        this.orderData.baseCurrencyCode = this.baseCurrency.code;

        orderDeliveryService
          .saveOrder(this.orderHeader)
          .then(() => {
            if (this.orderHeader.status == "CONFIRM")
              this.printInvoice("invoicePrint");
          })
          .then(() => {
            this.goBack();
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data) {
              this.responseError = err.response.data.message;
            }
            this.saveLoading = false;
          });
      }
    },
    printInvoice(divName) {
      var d = new Date();
      var t = d.toLocaleTimeString();
      var mydate = new Date().toString("MMMM yyyy");
      document.getElementById("date").innerHTML = mydate.substr(0, 16) + t;
      var printContents = document.getElementById(divName).innerHTML;

      var myWindow = window.open("", "MsgWindow", "width=350,height=500");
      myWindow.document.write(printContents);
      myWindow.print();
      myWindow.close();
    },
    getBaseCurrency() {
      let isBase = true;
      currencyService
        .getBaseCurrency(isBase)
        .then((res) => {
          this.baseCurrency = res.data[0];
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
