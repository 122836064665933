<template>
  <v-container fluid full-height grid-list-md>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSave">
      <v-layout row wrap>
        <v-flex xs6>
          <div class="text-xs-left">
            <v-breadcrumbs :items="breadcrumbs" divider=">">
              <template slot="item" slot-scope="props">
                <router-link
                  :to="'/'+props.item.to"
                  :disabled="props.item.disabled"
                  v-bind:class="{ 'v-breadcrumbs__item v-breadcrumbs__item--disabled': props.item.disabled }"
                >{{props.item.text}}</router-link>
              </template>
            </v-breadcrumbs>
          </div>
        </v-flex>
        <v-flex xs6>
          <div class="text-xs-right">
            <v-btn outline color="primary" @click="goBack">
              Go Back
              <v-icon right>arrow_back</v-icon>
            </v-btn>
          </div>
        </v-flex>
        <v-flex xs6 offset-sm3>
          <v-card>
            <v-layout row wrap class="pa-3">
              <v-flex sm12 v-if="responseError">
                <span class="red--text">{{responseError}}</span>
              </v-flex>
              <v-flex sm12>
                <div class="dropbox">
                  <input type="file" @change="onFileChanged" class="input-file" />
                  <p v-if="!category.imageUrl">
                    Drag your file(s) here to begin
                    <br />or click to browse
                  </p>
                  <img v-if="category.imageUrl" :src="category.imageUrl" />
                </div>
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  name="name"
                  label="Category Name"
                  v-model="category.name"
                  type="text"
                  :rules="[
                                                        () => !!category.name || 'Category name is required',
                                                    ]"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 v-if="category.id">
                <v-switch
                  :label="`Status is ${statusFlag?'Active': 'Inactive'}`"
                  :value="statusFlag"
                  v-model="statusFlag"
                ></v-switch>
              </v-flex>
              <v-flex sm12>
                <div class="text-xs-center">
                  <v-btn color="error" @click="clear">
                    Reset
                    <v-icon right>clear</v-icon>
                  </v-btn>
                  <v-btn color="primary" :loading="saveLoading" :disabled="!valid" type="submit">
                    Save
                    <v-icon right>save</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { categoryService } from "../../services/category-service";
import { commonService } from "../../services/common-service";
import { Category } from "../../models/model";
import VSwitch from "vuetify/lib/components/VSwitch/VSwitch";

export default {
  name: "CategoryDetail",
  components: { VSwitch },
  data: () => ({
    valid: true,
    breadcrumbs: [
      {
        text: "Category",
        disabled: false,
        to: "categories"
      },
      {
        text: "Category Detail",
        disabled: true
      }
    ],
    category: new Category(),
    oldCategory: new Category(),
    statusFlag: true,
    saveLoading: false,
    responseError: ""
  }),
  created() {
    const id = this.$route.params.id;
    if (id == "new") {
      // new category
    } else {
      this.getCategoryDetail(id);
    }
  },
  methods: {
    onSave() {
      if (this.$refs.form.validate()) {
        this.saveLoading = true;
        this.category.status = this.statusFlag ? "ACTIVE" : "INACTIVE";
        if (!this.category.image) {
          delete this.category["image"];
        }
        if (!this.category.imageUrl) {
          delete this.category["imageUrl"];
        }
        // let categoryObj = {
        //   id: this.category.id ? this.category.id : "",
        //   name: this.category.name,
        //   status: this.category.status
        // };
        categoryService
          .saveCategory(this.category)
          .then(res => {
            console.log(res);
            this.goBack();
          })
          .catch(err => {
            console.log(err);
            if (err.response.data) {
              this.responseError = err.response.data.message;
            }
            this.saveLoading = false;
          });
      }
    },
    getCategoryDetail(id) {
      categoryService
        .getCategoryDetail(id)
        .then(res => {
          this.category = res.data;
          this.oldCategory = Object.assign({}, res.data);
          this.statusFlag = this.category.status == "ACTIVE" ? true : false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    clear() {
      this.$refs.form.resetValidation();
      if (!this.category.id) {
        this.category = new Category();
        this.statusFlag = true;
      } else {
        this.category = Object.assign({}, this.oldCategory);
        this.statusFlag = this.category.status == "ACTIVE" ? true : false;
      }
    },
    goBack() {
      this.$router.push("/categories");
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.category.imageUrl = URL.createObjectURL(file); // to show preview image
      commonService.getBase64(file).then(data => {
        this.category.image = data;
      });
    }
  }
};
</script>

