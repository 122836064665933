export const AdminMenus = [
  { header: "General" },
  { icon: "home", text: "Home", to: "/dashboard" },
  // { icon: "home", text: "City", to: "/cities" },
  // { icon: "home", text: "Township", to: "/townships" },
  { icon: "settings", text: "System Setting", to: "/system-setting" },
  { icon: "monetization_on", text: "Currency", to: "/currency" },
  // { icon: "monetization_on", text: "Exchange", to: "/exchange" },
  { icon: "widgets", text: "Attachment", to: "/attachment" },
  { header: "User Management" },
  { icon: "people", text: "User", to: "/users" },
  { icon: "widgets", text: "Category", to: "/categories" },
  { icon: "local_grocery_store", text: "Stock", to: "/stocks" },
  // {
  //   icon: "format_list_numbered",
  //   text: "System Serialize",
  //   to: "/system-serializes"
  // },
  // {
  //   icon: "tablet",
  //   text: "Table Information",
  //   to: "/table-infos"
  // },
  // { icon: "kitchen", text: "Kitchen", to: "/kitchens" },
  // { header: "Order Management" },
  // { icon: "receipt", text: "Order List", to: "/orders" },
  { header: "Company Service" },
  { icon: "receipt", text: "Service Category", to: "/service-category" },
  { icon: "receipt", text: "Service", to: "/service" },
  { header: "Information" },
  { icon: "redeem", text: "Information", to: "/information" },
  // { header: "Reports" },
  // { icon: "report", text: "Product Order Report", to: "/product-order-report" },
  // { icon: "report", text: "Order Report", to: "/order-report" },
  // { icon: "report", text: "Menu Item Report", to: "/menu-report" },
  // { icon: "report", text: "Counter Cash Report", to: "/counter-cash-report" },
  // { icon: "report", text: "Cash In Hand Report", to: "/cash-in-hand-report" },
];

export const ManagerMenus = [
  { header: "General" },
  { icon: "home", text: "Home", to: "/dashboard" },
  { header: "Tax Management" },
  { icon: "receipt", text: "Tax", to: "/taxs" },
  { header: "Counter Cash Management" },
  { icon: "credit_card", text: "Counter Cash", to: "/counter-cashs" },
];

export const SaleMenus = [
  { header: "General" },
  { icon: "home", text: "Home", to: "/dashboard" },
  { icon: "local_grocery_store", text: "Sale", to: "/initial-sale" },
];

export const SysAdminMenus = [
  { header: "General" },
  { icon: "home", text: "Home", to: "/dashboard" },
  // { icon: "home", text: "City", to: "/cities" },
  // { icon: "home", text: "Township", to: "/townships" },
  { icon: "settings", text: "System Setting", to: "/system-setting" },
  { icon: "widgets", text: "Attachment", to: "/attachment" },
  { icon: "monetization_on", text: "Currency", to: "/currency" },
  // { icon: "monetization_on", text: "Exchange", to: "/exchange" },
  { header: "User Management" },
  { icon: "people", text: "User", to: "/users" },
  { icon: "widgets", text: "Category", to: "/categories" },
  { icon: "local_grocery_store", text: "Stock", to: "/stocks" },
  // { header: "Order Management" },
  // { icon: "receipt", text: "Order List", to: "/orders" },
  { header: "Company Service" },
  { icon: "receipt", text: "Service Category", to: "/service-category" },
  { icon: "receipt", text: "Service", to: "/service" },
  { header: "Information" },
  { icon: "redeem", text: "Information", to: "/information" },
  // { header: "Reports" },
  // { icon: "report", text: "Product Order Report", to: "/product-order-report" },
  // { icon: "report", text: "Order Report", to: "/order-report" },

];
