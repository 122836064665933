<template>
    <v-container fluid full-height grid-list-md>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-layout row wrap class="pa-4">
                        <v-flex sm3>
                            <v-menu
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                min-width="290px">
                                <v-text-field
                                    slot="activator"
                                    v-model="queryParams.date"
                                    label="Date"
                                    prepend-icon="event"
                                    readonly></v-text-field>
                                <v-date-picker v-model="queryParams.date"></v-date-picker>
                            </v-menu>
                        </v-flex>
                        <v-flex sm3>
                            <v-text-field
                                name="counter"
                                label="Counter Name"
                                v-model="queryParams.counterName"
                                type="text">
                            </v-text-field>
                        </v-flex>
                        <v-flex sm3 v-if="user.role.roleName == 'ADMIN'">
                            <v-select
                                :items="shopList"
                                label="Choose Shop..."
                                item-text="name"
                                menu-props="auto"
                                item-value="id"
                                v-model="queryParams.shopId">
                            </v-select>
                        </v-flex>
                        <v-flex sm3>
                            <v-btn outline color="primary" @click="onSearch">
                                Search
                                <v-icon right>search</v-icon>
                            </v-btn>
                        </v-flex>
                        <v-flex d-flex xs12>
                            <v-card>
                                <v-card-text class="font-weight-bold">
                                    <div>
                                        <v-btn color="primary" @click="printAllCashInHand('printCashInHand')">PRINT ALL</v-btn>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex>
                            <v-data-table 
                                :headers="DetailHeaders" 
                                :items="cashInHandDetailList"
                                class="elevation-1"
                                :loading="showListLoading"
                                :total-items="totalRows"
                                :rows-per-page-items="rowsPerPageItems"
                                :pagination.sync="pagination">
                                <template slot="items" slot-scope="props">
                                    <td>{{ props.item.counterName }}</td>
                                    <td>{{ props.item.createdDateInMilliSeconds | format-ms-date}}</td>
                                    <td>{{ props.item.saleTotalAmount}}</td>
                                    <td>{{ props.item.floatTotalAmount }}</td>
                                    <td>{{ props.item.pickupTotalAmount }}</td>
                                    <td>{{ props.item.totalCashInHandAmount }}</td>
                                </template>
                            </v-data-table>
                        </v-flex>

                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
        <!-- <summary report> -->
        <div id="printCashInHand" style="bsale:1px solid black;width:300px;" v-show="false">
            <div style="text-align:center">
                <p style="font-size:18px;margin:1px">Cash In Hand Summary</p>
            </div>
            <div style="text-align:center">
                <p style="font-size:15px;margin:1px">
                Report Date : [{{queryParams.date}}]
                </p>
            </div>
            <br>
            <div style="padding-left:5px;padding-right:5px">
                <table style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;border-top:1px solid black">
                    <thead style="border-bottom:1px solid black;border-top:1px solid black">
                        <tr>
                            <th style="text-align:left">Counter Name</th>
                            <th style="text-align:left">Sale Total Amount</th>
                            <th style="text-align:left">Float Total Amount</th>
                            <th style="text-align:left">PickUp Total Amount</th>
                            <th style="text-align:left">Cash In Hand Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in allCashInHandReportList" :key="index">
                            <td style="text-align:left">{{item.createdDateInMilliSeconds | format-ms-date}}</td>
                            <td style="text-align:left">{{item.saleTotalAmount}}</td>
                            <td style="text-align:left">{{item.floatTotalAmount}}</td>
                            <td style="text-align:left">{{item.pickupTotalAmount}}</td>
                            <td style="text-align:left">{{item.totalCashInHandAmount}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </v-container>
</template>
<script>
    import { commonService } from "../../services/common-service";
    import { reportService } from "../../services/report-service";
    import { shopService } from "../../services/shop-service";
    export default{
        components: {},
        data: () => ({
        user: JSON.parse(localStorage.getItem("user")),
        showListLoading: false,
        date: commonService.formatDate(new Date(), "YYYY-MM-DD"),
        cashInHandDetailList: [],
        allCashInHandReportList: [],
        shopList: [],
        totalRows: 0,
        queryParams: {
            date: commonService.formatDate(new Date(), "YYYY-MM-DD"),  
            counterName: "",
            shopId: ""
        },

    DetailHeaders: [
        { text: "Counter Name", align: "left", value: "b.counterName"},
        { text: "Sale Date", value: "b.createdDate" },
        { text: "Sale Total Amount", value: "b.saleTotalAmount" },
        { text: "Float Total Amount", value: "a.floatTotalAmount" },
        { text: "PickUp Total Amount", value: "a.pickUpTotalAmount" },
        { text: "Cash In Hand Total Amount", value: "cashInHandAmount" ,sortable: false}
    ],
    rowsPerPageItems: [25, 50, 100],
    pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 50,
        totalItems: 0,
        sortBy: "b.counterName"
        },
    }),
    watch: {
        pagination: {
            handler() {
                this.getCashInHandReport();
                this.getAllCashInHandReportList();
        },
      deep: true
    }
  },
    created() {
        this.onSearch();      
        if (this.$route.query.counterName) {
            this.queryParams.counterName = this.$route.query.counterName;
        }
        if (this.$route.query.shopId) {
            this.queryParams.shopId = this.$route.query.shopId;
        }
        if (this.$route.query.date) {
            this.queryParams.date = this.$route.query.date;
        }
        if (this.$route.query.page) {
            this.pagination.page = this.$route.query.page;
        }
        if (this.$route.query.size) {
            this.pagination.size = this.$route.query.size;
        }
    },
    methods: {
        onSearch() {
            let user = JSON.parse(localStorage.getItem("user"));
            if(user.role.roleName == 'ADMIN'){
                this.getAllShop();
            }
            if(user.role.roleName != 'ADMIN'){
                this.queryParams.shopId = user.shopId;
            }
            this.queryParams.page = this.pagination.page;
            this.queryParams.size = this.pagination.rowsPerPage;
            this.$router.replace({
            name: "cash-in-hand-report",
            query: this.queryParams
            });
            this.getCashInHandReport();     
            this.getAllCashInHandReportList();  
        },
        getAllShop() {
            shopService.getAllShops()
                .then(res => {
                let tempList = res.data;
                for (let temp of tempList) {
                let shop = {
                    id: temp.id,
                    name: temp.name
                };
                this.shopList.push(shop);
            }
            })
            .catch(err => {
            console.log(err);
            });
        },
        getCashInHandReport(){
            this.showListLoading = true;
            let order = this.pagination.descending ? "desc" : "asc";
            reportService.getCashInHandReport(
                this.queryParams,
                this.pagination.page,
                this.pagination.rowsPerPage,
                this.pagination.sortBy ? this.pagination.sortBy + "," + order : ""
            )
            .then(res => {
                if (res.data) {
                    this.cashInHandDetailList = res.data.cashInHandDetailDTOList;
                    this.totalRows = res.data.totalElements;
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.showListLoading = false;
            });
        },
        printAllCashInHand(divName) {
            var printContents = document.getElementById(divName).innerHTML;
            var originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            location.reload(true);
        },
        getAllCashInHandReportList() {
            this.showListLoading = true;
            reportService
                .getAllCashInHandReportList(this.queryParams)
                .then(res => {
                    if(res.data) {
                        this.allCashInHandReportList = res.data.cashInHandDetailDTOList;
                        console.log(this.allCashInHandReportList.length);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.showListLoading = false;
                });
        },
        clear() {
        this.queryParams.data = commonService.formatDate(
            new Date(),
            "YYYY-MM-DD"
        );
    }
    }
}

</script>
