import axios from '../axios-auth'

export const counterCashService = {
    getCounterCashList,
    getCounterCashDetail,
    saveCounterCash,
    counterCashSummary,
    counterCashSummaryDetail
};

function getCounterCashList(searchParams, page, size ,sortedBy){
    if(!page){
        page = 0;
    }
    else{
        page -= 1;
    }
    if(!size){
        size = 50;
    }
    let url = '/auth/counter-cashs?page='+page+'&size='+size+'&sort='+sortedBy;
    if (searchParams.shopId) {
        url += '&shopId=' + searchParams.shopId;
    }    
    if (searchParams.cashType) {
        url += '&cashType=' + searchParams.cashType;
    }
    if (searchParams.counterId) {
        url += '&counterId=' + searchParams.counterId;
    }
    return axios.get(url);
}

function getCounterCashDetail(id) {
    return axios.get('/auth/counter-cash/'+id);
}

function saveCounterCash(counterCash) {
    if(counterCash.id){
        //edit
        return axios.put('/auth/updateCounterCash',counterCash);
    }
    else{
        // create new
        return axios.post('/auth/countercash',counterCash);
    }

}

function counterCashSummary(searchParams){
    let url = '/auth/counterCashSummary?';

    if (searchParams.cashType) {
        url += '&cashType=' + searchParams.cashType;
    } 
    if (searchParams.shopName) {
        url += '&shopName=' + searchParams.shopName;
    }
    if (searchParams.counterName) {
        url += '&counterName=' + searchParams.counterName;
    }
    if (searchParams.fromDate) {
        url += '&fromDate=' + searchParams.fromDate;
    }
    if (searchParams.toDate) {
        url += '&toDate=' + searchParams.toDate;
    }
    return axios.get(url);
}

function counterCashSummaryDetail(searchParams,page, size ,sortedBy){
    if(!page){
        page = 0;
    }
    else{
        page -= 1;
    }
    if(!size){
        size = 50;
    }
    let url = '/auth/counterCashSummaryDetail?page='+page+'&size='+size+'&sort='+sortedBy;
    if (searchParams.cashType) {
        url += '&cashType=' + searchParams.cashType;
    }
    if (searchParams.counterName) {
        url += '&counterName=' + searchParams.counterName;
    }
    if (searchParams.shopName) {
        url += '&shopName=' + searchParams.shopName;
    }
    if (searchParams.fromDate) {
        url += '&fromDate=' + searchParams.fromDate;
    }
    if (searchParams.toDate) {
        url += '&toDate=' + searchParams.toDate;
    }
    return axios.get(url);
}
