<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs6>
        <div class="text-xs-left">
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-card>
          <v-layout row wrap class="pa-3">
            <v-flex sm3>
              <v-menu
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="queryParams.fromDate"
                  label="From Order Date"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker v-model="queryParams.fromDate"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex sm3>
              <v-menu
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="queryParams.toDate"
                  label="To Order Date"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker v-model="queryParams.toDate"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex sm3>
              <v-text-field
                name="name"
                label="Customer Name"
                type="text"
                v-model="queryParams.customerName"
              ></v-text-field>
            </v-flex>
            <v-flex sm3>
              <v-text-field
                name="name"
                label="Customer PhoneNo"
                type="text"
                v-model="queryParams.customerPhoneNo"
              ></v-text-field>
            </v-flex>
            <v-flex sm3>
              <v-text-field name="name" label="Order No" type="text" v-model="queryParams.orderNo"></v-text-field>
            </v-flex>
            <v-flex sm3>
              <v-select
                label="Currency"
                v-model="queryParams.currencyId"
                :items="currencyList"
                item-value="id"
                item-text="code"
              ></v-select>
            </v-flex>
            <v-flex sm3>
              <v-select
                :items="statusList"
                label="Status"
                v-model="queryParams.status"
                item-text="label"
                item-value="value"
              ></v-select>
            </v-flex>
            <v-flex sm3>
              <v-btn outline color="primary" @click="OnSearch">
                Search
                <v-icon right>search</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs12>
              <v-btn :loading="printAllLoading" color="success" @click="exportAllOrder()">EXPORT ALL</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 class="mt-1">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="orderList"
            class="elevation-1"
            :loading="showListLoading"
            :total-items="totalRows"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.orderDateInMilliSeconds | format-ms-date }}</td>
              <td>{{ props.item.orderNo }}</td>
              <td>
                <v-flex class="warpBreak">{{ props.item.customerName }}</v-flex>
              </td>
              <td>{{ props.item.customerPhoneNo }}</td>
              <td>{{ props.item.csRequestDateInMilliSeconds | format-ms-date }}</td>
              <td>
                <v-flex class="warpBreak">{{ props.item.deliveryAddress }}</v-flex>
              </td>
              <td>
                <v-flex class="warpBreak">{{ props.item.billingAddress }}</v-flex>
              </td>
              <td>{{ props.item.estimateDeliveryDateInMilliSeconds | format-ms-date }}</td>
              <td>{{ props.item.approvedPerson }}</td>
              <td>{{ props.item.approvedDateInMilliSeconds | format-ms-date }}</td>
              <td>{{ props.item.deliveryFee }}</td>
              <td>{{ props.item.discountAmount }}</td>
              <td>{{ props.item.currencyDTO.code }}</td>
              <td>{{ props.item.exchangeRate }}</td>
              <td>{{ props.item.totalAmount | numFormat('0.00') }}</td>
              <td>{{ props.item.customerTotalAmount | numFormat('0.00')}}</td>
              <td>
                <v-flex class="warpBreak">{{ props.item.remark }}</v-flex>
              </td>
              <td>
                <v-flex class="warpBreak">{{ props.item.adminRemark }}</v-flex>
              </td>
              <td>
                <span v-if="props.item.status==='CONFIRM'">
                  <v-chip icon small color="success" dark>
                    <v-avatar left>
                      <v-icon>visibility</v-icon>
                    </v-avatar>CONFIRM
                  </v-chip>
                </span>
                <span v-else>
                  <span v-if="props.item.status==='PENDING'">
                    <v-chip icon small color="warning" dark>
                      <v-avatar left>
                        <v-icon>visibility</v-icon>
                      </v-avatar>PENDING
                    </v-chip>
                  </span>
                  <span v-else>
                    <v-chip icon small color="error" dark>
                      <v-avatar left>
                        <v-icon>visibility_off</v-icon>
                      </v-avatar>CANCEL
                    </v-chip>
                  </span>
                </span>
              </td>
              <td>
                <v-btn
                  outline
                  title="Re-Print"
                  small
                  icon
                  v-if="props.item.status==='CONFIRM'"
                  @click="reprint(props.item)"
                >
                  <v-icon>print</v-icon>
                </v-btn>
                <v-btn flat icon color="blue lighten-2" @click="onEdit(props.item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Invoice print hidden area -->
    <ReprintInvoice id="invoicePrint" :orderData="reprintData"></ReprintInvoice>
  </v-container>
</template>
<script>
import { orderDeliveryService } from "../../services/order-delivery-service";
import ReprintInvoice from "./ReprintInvoice";
import { commonService } from "../../services/common-service";
import { currencyService } from "../../services/currency-service";

export default {
  name: "orderList",
  components: { ReprintInvoice },
  data: () => ({
    showListLoading: false,
    printAllLoading: false,
    breadcrumbs: [
      {
        text: "Order Management",
        disabled: true,
      },
      {
        text: "Order List",
        disabled: true,
      },
    ],
    headers: [
      { text: "Order Date", align: "left", value: "orderDate" },
      { text: "Order No", align: "left", value: "orderNo" },
      { text: "Customer Name", value: "customerName" },
      { text: "Customer Phone NO", value: "customerPhoneNo" },
      { text: "Customer Request Date", value: "csRequestDate" },
      { text: "Delivery Address", value: "deliveryAddress", sortable: false },
      { text: "Billing Address", value: "billingAddress", sortable: false },
      { text: "Estimate Delivery Date", value: "estimateDeliveryDate" },
      { text: "Approved Person", value: "approvedPerson" },
      { text: "Approved Date", value: "approvedDate" },
      { text: "Delivery Fee", value: "deliveryFee" },
      { text: "Discount %", value: "discountAmount" },
      { text: "Currency", value: "Currency" },
      { text: "Exchange Rate", value: "Exchange Rate" },
      { text: "Net Amount", value: "totalAmount" },
      { text: "Grand Total", value: "customerTotalAmount" },
      { text: "Remark", value: "remark", sortable: false },
      { text: "Admin Remark", value: "adminRemark", sortable: false },
      { text: "Status", value: "status" },
      { text: "Action", value: "name", sortable: false },
    ],
    orderList: [],
    totalRows: 0,
    rowsPerPageItems: [25, 50, 100],
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 50,
      sortBy: "orderDate",
      totalItems: 0,
    },
    queryParams: {
      fromDate: commonService.formatDate(new Date(), "YYYY-MM-DD"),
      toDate: commonService.formatDate(new Date(), "YYYY-MM-DD"),
      status: "",
      customerName: "",
      customerPhoneNo: "",
      orderNo: "",
      currencyId: "",
    },
    statusList: [
      { label: "Pending", value: "PENDING" },
      { label: "Confirm", value: "CONFIRM" },
      { label: "Cancel", value: "CANCEL" },
    ],
    reprintData: {},
    baseCurrency: {},
    currencyList: [],
  }),
  watch: {
    pagination: {
      handler() {
        this.getOrderList();
      },
      deep: true,
    },
  },
  created() {
    //for deep linking
    if (this.$route.query.fromOrderDate) {
      this.queryParams.fromDate = this.$route.query.fromOrderDate;
    }
    if (this.$route.query.toOrderDate) {
      this.queryParams.toDate = this.$route.query.toOrderDate;
    }
    if (this.$route.query.status) {
      this.queryParams.status = this.$route.query.status;
    }
    if (this.$route.query.customerName) {
      this.queryParams.customerName = this.$route.query.customerName;
    }
    if (this.$route.query.customerPhoneNo) {
      this.queryParams.customerPhoneNo = this.$route.query.customerPhoneNo;
    }
    if (this.$route.query.orderNo) {
      this.queryParams.orderNo = this.$route.query.orderNo;
    }
    if (this.$route.query.currencyId) {
      this.queryParams.currencyId = this.$route.query.currencyId;
    }
    if (this.$route.query.page) {
      this.pagination.page = this.$route.query.page;
    }
    if (this.$route.query.size) {
      this.pagination.size = this.$route.query.size;
    }
  },
  mounted() {
    this.getBaseCurrency();
    this.getActiveCurrencyList();
  },
  methods: {
    OnSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.$router.replace({ name: "orders", query: this.queryParams });
      this.getOrderList();
    },
    getOrderList() {
      this.showListLoading = true;
      this.printAllLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";
      orderDeliveryService
        .getOrderList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        )
        .then((res) => {
          if (res.data) {
            this.orderList = res.data.orderList;

            this.orderList.map((order) => {
              return (order.baseCurrencyCode = this.baseCurrency.code);
            });

            this.totalRows = res.data.totalElements;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
          this.printAllLoading = false;
        });
    },
    onEdit(order) {
      // go to details page
      this.$router.push("/order/" + order.id);
    },
    reprint(item) {
      let promise = new Promise((resolve, reject) => {
        item.printTotalAmount = 0;
        item.orderHeaderDetails.forEach((i) => {
          item.printTotalAmount += i.price * i.orderQty;
        });
        item.printNetAmount = item.totalAmount;
        item.orderDate = commonService.formatMsToDateOnly(
          item.orderDateInMilliSeconds
        );
        item.estimateDeliveryDate = commonService.formatMsToDateOnly(
          item.estimateDeliveryDateInMilliSeconds
        );
        this.reprintData = item;
        this.reprintData.baseCurrencyCode = this.baseCurrency.code;

        if (this.reprintData) resolve();
        else reject();
      }).catch((err) => {
        console.log(err);
      });

      promise.then(() => {
        this.printInvoice("invoicePrint");
      });
    },
    exportAllOrder() {
      this.printAllLoading = true;
      orderDeliveryService
        .getOrderExcelExport(this.queryParams)
        .then((res) => {
          commonService.downloadE(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.printAllLoading = false;
        });
    },
    printInvoice(divName) {
      var d = new Date();
      var t = d.toLocaleTimeString();
      var mydate = new Date().toString("MMMM yyyy");
      document.getElementById("date").innerHTML = mydate.substr(0, 16) + t;
      var printContents = document.getElementById(divName).innerHTML;

      var myWindow = window.open("", "MsgWindow", "width=350,height=500");
      myWindow.document.write(printContents);
      myWindow.print();
      myWindow.close();
    },
    getBaseCurrency() {
      let isBase = true;
      currencyService
        .getBaseCurrency(isBase)
        .then((res) => {
          this.baseCurrency = res.data[0];
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getActiveCurrencyList() {
      currencyService
        .getActiveCurrency()
        .then((res) => {
          this.currencyList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
<style scoped>
.warpBreak {
  width: 250px;
  overflow-wrap: break-word;
}
</style>