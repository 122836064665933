<template>
  <v-container fluid full-height grid-list-md>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSave">
      <v-layout row wrap>
        <v-flex xs6>
          <div class="text-xs-left">
            <v-breadcrumbs :items="breadcrumbs" divider=">">
              <template slot="item" slot-scope="props">
                <router-link
                  :to="'/'+props.item.to"
                  :disabled="props.item.disabled"
                  v-bind:class="{ 'v-breadcrumbs__item v-breadcrumbs__item--disabled': props.item.disabled }"
                >{{props.item.text}}</router-link>
              </template>
            </v-breadcrumbs>
          </div>
        </v-flex>
        <v-flex xs6>
          <div class="text-xs-right">
            <v-btn outline color="primary" @click="goBack">
              Go Back
              <v-icon right>arrow_back</v-icon>
            </v-btn>
          </div>
        </v-flex>
        <v-flex xs6 offset-sm3>
          <v-card>
            <v-layout row wrap class="pa-3">
              <v-flex sm12 v-if="responseError">
                <span class="red--text">{{responseError}}</span>
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  name="code"
                  label="City Code"
                  v-model="city.code"
                  type="text"
                  :rules="[() => !!city.code || 'City code is required']"
                ></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  name="name"
                  label="City Name"
                  v-model="city.name"
                  type="text"
                  :rules="[() => !!city.name || 'City name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-textarea name="description" label="Description" v-model="city.description"></v-textarea>
              </v-flex>
              <v-flex sm12>
                <v-switch
                  :label="`Status is ${statusFlag?'Active': 'Inactive'}`"
                  :value="statusFlag"
                  v-model="statusFlag"
                ></v-switch>
              </v-flex>
              <v-flex sm12>
                <div class="text-xs-center">
                  <v-btn color="error" @click="clear">
                    Reset
                    <v-icon right>clear</v-icon>
                  </v-btn>
                  <v-btn color="primary" :loading="saveLoading" :disabled="!valid" type="submit">
                    Save
                    <v-icon right>save</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { cityService } from "../../services/city-service";
import { City } from "../../models/model";

export default {
  name: "CityDetail",
  data: () => ({
    valid: true,
    statusFlag: true,
    breadcrumbs: [
      {
        text: "Cities",
        disabled: false,
        to: "cities",
      },
      {
        text: "City Detail",
        disabled: true,
      },
    ],
    city: new City(),
    oldCity: new City(),
    saveLoading: false,
    responseError: "",
  }),
  created() {
    const id = this.$route.params.id;
    if (id == "new") {
      // new category
    } else {
      this.getCityDetail(id);
    }
  },
  methods: {
    onSave() {
      if (this.$refs.form.validate()) {
        this.saveLoading = true;
        this.city.status = this.statusFlag ? "ACTIVE" : "INACTIVE";
        let cityObj = {
          id: this.city.id ? this.city.id : "",
          name: this.city.name,
          code: this.city.code,
          description: this.city.description,
          status: this.city.status,
        };
        cityService
          .saveCity(cityObj)
          .then((res) => {
            console.log(res);
            this.goBack();
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data) {
              this.responseError = err.response.data.message;
            }
            this.saveLoading = false;
          });
      }
    },
    getCityDetail(id) {
      cityService
        .getCityDetail(id)
        .then((res) => {
          this.city = res.data;
          this.statusFlag = this.city.status == "ACTIVE" ? true : false;
          this.oldCity = Object.assign({}, res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clear() {
      this.$refs.form.resetValidation();
      if (!this.city.id) {
        this.city = new City();
      } else {
        this.city = Object.assign({}, this.oldCity);
        this.statusFlag = this.city.status == "ACTIVE" ? true : false;
      }
    },
    goBack() {
      this.$router.push("/cities");
    },
  },
};
</script>

