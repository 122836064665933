var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","full-height":"","grid-list-md":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('div',{staticClass:"text-xs-left"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":">"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('router-link',{class:{ 'v-breadcrumbs__item v-breadcrumbs__item--disabled': props.item.disabled },attrs:{"to":'/'+props.item.to,"disabled":props.item.disabled}},[_vm._v("\n                                "+_vm._s(props.item.text)+"\n                            ")])]}}])})],1)]),_c('v-flex',{attrs:{"xs6":""}},[_c('div',{staticClass:"text-xs-right"},[_c('v-btn',{attrs:{"outline":"","color":"primary"},on:{"click":_vm.goBack}},[_vm._v("\n                        Go Back\n                        "),_c('v-icon',{attrs:{"right":""}},[_vm._v("arrow_back")])],1)],1)]),_c('v-flex',{attrs:{"xs6":"","offset-sm3":""}},[_c('v-card',[_c('v-layout',{staticClass:"pa-3",attrs:{"row":"","wrap":""}},[(_vm.responseError)?_c('v-flex',{attrs:{"sm12":""}},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.responseError))])]):_vm._e(),_c('v-flex',{attrs:{"sm12":""}},[_c('v-text-field',{attrs:{"name":"oldPassword","label":"Old Password","type":"password","rules":[
                                                    function () { return !!_vm.user.oldPassword || 'Old Password is required'; },
                                                    function () { return _vm.user.oldPassword && _vm.user.oldPassword.length > 6 || 'Old Password length must be greater than 6 characters'; }

                                                ]},model:{value:(_vm.user.oldPassword),callback:function ($$v) {_vm.$set(_vm.user, "oldPassword", $$v)},expression:"user.oldPassword"}})],1),_c('v-flex',{attrs:{"sm12":""}},[_c('v-text-field',{attrs:{"name":"newPassword","label":"Password","type":"password","rules":[
                                                    function () { return !!_vm.user.newPassword || 'New Password is required'; },
                                                    function () { return _vm.user.newPassword && _vm.user.newPassword.length > 6 || 'New Password length must be greater than 6 characters'; }

                                                ]},model:{value:(_vm.user.newPassword),callback:function ($$v) {_vm.$set(_vm.user, "newPassword", $$v)},expression:"user.newPassword"}})],1),_c('v-flex',{attrs:{"sm12":""}},[_c('v-text-field',{attrs:{"name":"confirmPassword","label":"Confirm Password","type":"password","rules":[
                                                    function () { return !!_vm.user.confirmPassword || 'Confirm Password is required'; },
                                                    function (){ return _vm.user.newPassword == _vm.user.confirmPassword || 'New password and confirm password does not match'; }
                                                ]},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}})],1),_c('v-flex',{attrs:{"sm12":""}},[_c('div',{staticClass:"text-xs-center"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.clear}},[_vm._v("\n                                    Reset\n                                    "),_c('v-icon',{attrs:{"right":""}},[_vm._v("clear")])],1),_c('v-btn',{attrs:{"color":"primary","loading":_vm.saveLoading,"disabled":!_vm.valid,"type":"submit"}},[_vm._v("\n                                    Save\n                                    "),_c('v-icon',{attrs:{"right":""}},[_vm._v("save")])],1)],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }