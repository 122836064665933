<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    transition="slide-y-reverse-transition"
    persistent
    max-width="1000px"
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Upload File</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn :disabled="saveLoading" icon @click="done">
            <v-icon>cancel</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-flex xs12>
          <v-card>
            <v-layout row>
              <v-flex xs4 style="display: flex;">
                <v-btn @click="downloadTemplate">
                  <v-icon left>get_app</v-icon>Excel Template
                </v-btn>
              </v-flex>
              <v-flex xs4 pt-2>
                <input
                  type="file"
                  id="file"
                  ref="file"
                  v-on:change="onChangeFileUpload()"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </v-flex>
              <v-flex xs2 style="display: flex;">
                <v-btn @click="submitForm(),responseError=''" :loading="saveLoading">
                  <v-icon left>cloud_upload</v-icon>Upload
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
          <v-card>
            <span v-if="responseError" class="red--text">
              <ul style="list-style:none">
                <li v-for="res in responseError" :key="res">{{ res }}</li>
              </ul>
            </span>
          </v-card>
        </v-flex>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { stockService } from "../../services/stock-service";
import { commonService } from "../../services/common-service";
export default {
  props: {
    isOpen: Boolean
  },
  data: () => ({
    responseError: "",
    file: "",
    saveLoading: false
  }),
  watch: {
    isOpen: function() {
      if (this.isOpen) {
        this.init();
      }
    }
  },
  methods: {
    init: function() {
      this.responseError = "";
      this.$refs.file.value = "";
      this.file = "";
    },
    done: function() {
      this.$emit("change");
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    downloadTemplate() {
      stockService
        .getStockExcelTemplate()
        .then(res => {
          commonService.downloadE(res);
        })
        .catch(err => {
          if (err.response) {
            this.responseError = err.response.data.message;
          }
        });
    },
    submitForm() {
      if (this.file) {
        this.saveLoading = true;
        let formData = new FormData();
        formData.append("file", this.file);
        stockService
          .saveStockExcel(formData)
          .then(res => {
            this.done();
            console.log(res.data);
          })
          .catch(err => {
            if (err.response) {
              this.responseError = err.response.data.message.split(".");
              console.log(err.response.data);
            }
          })
          .finally(() => {
            this.saveLoading = false;
          });
      }
    }
  }
};
</script>