import axios from "../axios-auth";

export const promotionService = {
    getPromotionList,
    registerPromotion,
    getPromotionDetail,
    getPromotionByStatus,
    getAllPromotionCategories
};



function getPromotionList(searchParams, page, size, sortedBy) {
    let url = '';
    if (!page) {
        page = 0;
    }
    else {
        page -= 1;
    }
    if (!size) {
        size = 50;
    }
    if (sortedBy == 'null,asc') {
        url = '/auth/promotions?page=' + page + '&size=' + size;
    } else {
        url = '/auth/promotions?page=' + page + '&size=' + size + '&sort=' + sortedBy;
    }
    
    return axios.get(url);
}

function registerPromotion(promotion) {
    if (!promotion.id) {
        // new promotion
        return axios.post("/auth/promotion", promotion);
    } else {
        // edit
        return axios.put("/auth/promotion", promotion);
    }
}

function getPromotionDetail(id) {
    return axios.get('/auth/promotion/' + id);
}


function getPromotionByStatus(status) {
    return axios.get("/promotions-by-status/" + status);
}


function getAllPromotionCategories() {
    return axios.get("/all-promotion-categories");
}
