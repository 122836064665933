import axios from "../axios-auth";

export const townshipService = {
  getTownshipList,
  saveTownship,
  getTownshipDetail,
  getTownships, // for public
  getTownshipByCityId
};

function getTownshipList(searchParams, page, size, sortedBy) {
  let url = '';
  if (!page) {
    page = 0;
  }
  else {
    page -= 1;
  }
  if (!size) {
    size = 50;
  }
  if (sortedBy == 'null,asc') {
    url = '/auth/all-township?page=' + page + '&size=' + size;
  } else {
    url = '/auth/all-township?page=' + page + '&size=' + size + '&sort=' + sortedBy;
  }
  if (searchParams.name) {
    url += '&name=' + searchParams.name;
  }
  if (searchParams.code) {
    url += '&code=' + searchParams.code;
  }
  return axios.get(url);
}

function saveTownship(township) {
  if (!township.id) {
    // new township
    return axios.post("/auth/township", township);
  } else {
    // edit
    return axios.put("/auth/township", township);
  }
}

function getTownshipDetail(id) {
  return axios.get('/auth/township/' + id);
}

// for public
function getTownships() {
  return axios.get('/townships');
}

function getTownshipByCityId(id) {
  return axios.get('/township-by-city/' + id);
}