import moment from "moment";

export const commonService = {
  getBase64,
  getNotContainValues,
  formatDate,
  convertToMiliseconds,
  formatMsToDateOnly,
  downloadE
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result;
      resolve(encoded);
    };
    reader.onerror = error => reject(error);
  });
}

/**
 * Get not contain value from first array
 * @param arr1
 * @param arr2
 * @returns {Array}
 */
function getNotContainValues(arr1, arr2) {
  let notContains = [];
  arr1.forEach(e1 => {
    let isFound = false;
    arr2.forEach(e2 => {
      if (e1.id && e2.id) {
        if (e1.id == e2.id) {
          isFound = true;
        }
      } else if (e1.id && !e2.id) {
        if (e1.id == e2) {
          isFound = true;
        }
      } else {
        if (e1 == e2) {
          isFound = true;
        }
      }
    });
    if (!isFound) {
      if (e1.id) {
        notContains.push(e1.id);
      } else {
        notContains.push(e1);
      }
    }
  });
  return notContains;
}

function formatDate(dateStr, format) {
  return moment(String(dateStr)).format(format);
}

function convertToMiliseconds(dateStr) {
  return moment(String(dateStr)).format("x");
}

function formatMsToDateOnly(ms) {
  return moment.unix(ms / 1000).format("DD-MM-YYYY");
}

function downloadE(res) {  
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(res.blob);
    return;
  }
  var link = document.createElement("a");
  document.body.appendChild(link);
  var disposition = res.headers['content-disposition']
  var str = disposition.split("=");
  var fileName = str[1];
  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(res.blob);

  link.href = data;
  link.download = fileName;
  //window.open(link.href, fileName, "width=500,height=500");
  link.click();

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}