import axios from '../axios-auth'

export const userService = {
	login,
	getUserList,
	saveUser,
	getUserDetail,
	getAllRoles,
	changePasswordById,
	changePassword,
	visitCounter,
	visitorCount
};

function login(user) {
	return axios.post('/login', user);
}

function getUserList(searchParams, page, size, sortedBy) {
	let url = '';
	if (!page) {
		page = 0;
	}
	else {
		page -= 1;
	}
	if (!size) {
		size = 50;
	}
	if (sortedBy == 'null,asc') {
		url = '/auth/users?page=' + page + '&size=' + size;
	} else {
		url = '/auth/users?page=' + page + '&size=' + size + '&sort=' + sortedBy;
	}
	if (searchParams.name) {
		url += '&name=' + searchParams.name;
	}
	if (searchParams.status) {
		url += '&status=' + searchParams.status;
	}
	if (searchParams.role) {
		url += '&role=' + searchParams.role;
	}
	return axios.get(url);
}

function saveUser(user) {
	if (user.id) {
		//edit
		return axios.put('/auth/updateuser', user);
	}
	else {
		// create new
		return axios.post('/auth/user', user);
	}

}


function changePasswordById(user) {
	let obj = {
		"id": user.id,
		"newPassword": user.password
	};
	return axios.put('/auth/change-password-by-id', obj);
}

function changePassword(user) {
	let obj = {
		"oldPassword": user.oldPassword,
		"newPassword": user.newPassword
	};
	return axios.put('/auth/change-password-by-current-user', obj);
}

function getUserDetail(id) {
	return axios.get('/auth/user/' + id);
}

function getAllRoles() {
	return axios.get('/auth/roles');
}

function visitCounter(isPublic) {
	return axios.post('/incrementVisitorCount?isPublic=' + isPublic)

}

function visitorCount(queryParams) {
	let params = {
		fromDate: queryParams.fromDate,
		toDate: queryParams.toDate
	}
	return axios.get('/auth/visitor-counts', { params })
}