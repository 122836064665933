<template>
    <v-container fluid full-height grid-list-md>
         <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSave">
            <v-layout row wrap>
                <v-flex xs6>
                    <div class="text-xs-left">
                        <v-breadcrumbs :items="breadcrumbs" divider=">">
                            <template slot="item" slot-scope="props">
                                <router-link :to="'/'+props.item.to" :disabled="props.item.disabled"
                                             v-bind:class="{ 'v-breadcrumbs__item v-breadcrumbs__item--disabled': props.item.disabled }">
                                    {{props.item.text}}
                                </router-link>
                            </template>
                        </v-breadcrumbs>
                    </div>
                </v-flex>
                <v-flex xs6>
                    <div class="text-xs-right">
                        <v-btn outline color="primary" @click="goBack">
                            Go Back
                            <v-icon right>arrow_back</v-icon>
                        </v-btn>
                    </div>
                </v-flex>
                 <v-flex xs6 offset-sm3>
                    <v-card>
                        <v-layout row wrap class="pa-3">
                            <v-flex sm12 v-if="responseError">
                                <span class="red--text">{{responseError}}</span>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="kitchenName"
                                        label="Kitchen Name"
                                        v-model="kitchen.kitchenName"
                                        type="text"
                                        :rules="[
                                                        () => !!kitchen.kitchenName || 'Kitchen name is required',
                                                    ]">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="printerName"
                                        label="Printer Name"
                                        v-model="kitchen.printerName"
                                        type="text">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="ipAddress"
                                        label="IP Address"
                                        v-model="kitchen.ipAddress"
                                        type="text">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12>
                                <v-select
                                        :items="shopNameList"
                                        v-model="kitchen.shopName"
                                        label="Select shop name"
                                        @change="onChange"
                                        :rules="[
                                                        () => !!kitchen.shopName || 'Shop Name is required'
                                                        ]"
                                ></v-select>
                            </v-flex>
                            <v-flex sm12 v-if="kitchen.shopName">
                                <v-btn-toggle v-for="(category, index) in categoryList" :key="index">
                                    <v-btn                                        
                                    @click="getStockByCategory(category, kitchen.shopName, kitchen.id)"
                                    >{{category}}</v-btn>
                                </v-btn-toggle>
                                <v-layout row>
                                    <v-flex sm6>
                                    <v-card>
                                        <v-spacer></v-spacer>
                                        <v-list>
                                        <v-list-tile v-for="(item, index) in this.kitchen.kitchenStocks" :key="index">
                                            <v-list-tile-action>
                                                <v-icon color="pink">star</v-icon>
                                            </v-list-tile-action>
                                            <v-list-tile-content>
                                                <v-list-tile-title v-text="item.stockName"></v-list-tile-title>
                                            </v-list-tile-content>
                                        </v-list-tile>
                                        </v-list>
                                    </v-card>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex sm12>
                                <v-textarea 
                                    name="description" 
                                    label="Description" 
                                    v-model="kitchen.description">
                                </v-textarea>
                            </v-flex>
                            <v-flex sm6 v-if="kitchen.id">
                                <v-switch
                                        :label="`Status is ${statusFlag?'Active': 'Inactive'}`"
                                        :value="statusFlag"
                                        v-model="statusFlag"
                                ></v-switch>
                            </v-flex>
                            <v-flex sm12>
                                <div class="text-xs-center">
                                    <v-btn color="error" @click="clear">
                                        Reset
                                        <v-icon right>clear</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" :loading="saveLoading" :disabled="!valid" type="submit">
                                        Save
                                        <v-icon right>save</v-icon>
                                    </v-btn>

                                </div>
                            </v-flex>
                        </v-layout>
                    </v-card>
                 </v-flex>
            </v-layout>
        </v-form>
        <!----------------- For New Dialog --------------------->
        <v-dialog v-model="openDialog" persistent max-width="500px">
            <v-form ref="modalForm" v-model="modalValid" lazy-validation @submit.prevent="saveCategory">
                <v-card>
                    <v-card-title primary-title class="headline blue darken-2">
                        <span class="white--text">Stock List</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <div class="ma-3">
                        <v-flex sm12 v-if="modalError">
                            <span class="red--text">{{modalError}}</span>
                        </v-flex>
                        <v-flex sm12>
                            <div v-for="(stock, index) in stockList" :key="index">
                                <input type="checkbox" v-model="selected" :value="stock.name" @click="addToCart(stock.name)"> 
                                <v-btn>{{stock.name}}</v-btn> 
                            </div>
                        </v-flex> 
                    </div>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" flat @click.native="closeModal">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>

<script>
    import {kitchenService} from "../../services/kitchen-service";
    import {shopService} from "../../services/shop-service";
    import {Kitchen} from "../../models/model";
    import VSwitch from "vuetify/lib/components/VSwitch/VSwitch";

    export default {
        name: "KitchenDetail",
        components: {VSwitch},
        data: () => ({
            valid: true,
            breadcrumbs:[
                {
                    text: 'Kitchen',
                    disabled: false,
                    to: 'kitchens'
                },
                {
                    text: 'Kitchen Detail',
                    disabled: true
                }
            ],
            queryParams: {
                categoryName: "",
                shopName: "",
                kitchenId: "",
                status: "ACTIVE",
                menu: true
            },
            openDialog: false,
            modalValid: true,
            kitchen: new Kitchen(),
            oldKitchen: new Kitchen(),
            statusFlag: true,
            shopNameList: [],
            categoryList: [],
            stockList: [],
            selected: [],
            select: false,
            saveLoading: false,
            modalError: "",
            responseError: '',
            modelKitchen: new Kitchen()
        }),
        created() {
            const id = this.$route.params.id;
            if (id == 'new') {
                // new user
            }
            else {
                this.getKitchenDetail(id);
            }
            this.getCategoriesByStatus();
            this.getAllShops();
        },
        methods: {
            onSave() {
                if(this.$refs.form.validate()) {
                    this.saveLoading = true;
                    this.kitchen.status = this.statusFlag ? 'ACTIVE' : 'INACTIVE';

                    let kitchenObj = {
                        id: this.kitchen.id ? this.kitchen.id : '',
                        kitchenName: this.kitchen.kitchenName,
                        printerName: this.kitchen.printerName,
                        description: this.kitchen.description,
                        ipAddress: this.kitchen.ipAddress,
                        shopName: this.kitchen.shopName,
                        status: this.kitchen.status,
                        kitchenStocks: this.kitchen.kitchenStocks
                    };
                    kitchenService.saveKitchen(kitchenObj)
                    .then(res => {
                        console.log(res);
                        this.goBack();
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.data) {
                            this.responseError = err.response.data.message;
                        }
                        this.saveLoading = false;
                    });
                }
            },
            getKitchenDetail(id) {
                kitchenService.getKitchenDetail(id)
                    .then(res => {
                        this.kitchen = res.data;
                        let stockList = this.kitchen.kitchenStocks;
                        for (let stock of stockList) {
                            this.selected.push(stock.stockName);
                        }
                        this.oldKitchen = Object.assign({}, res.data);
                        this.statusFlag = this.kitchen.status == 'ACTIVE' ? true : false;
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            getAllShops() {
                shopService.getAllShops()
                    .then(res => {
                        let shopList = res.data;
                        for (let shop of shopList) {
                            this.shopNameList.push(shop.name);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            getCategoriesByStatus() {
                kitchenService.getCategoriesByStatus("ACTIVE")
                    .then(res => {
                        let categoryList = res.data;
                        for (let category of categoryList) {
                            this.categoryList.push(category.name);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            getStockByCategory(categoryName, shopName, kitchenId) {
                this.queryParams.categoryName = categoryName;
                this.queryParams.shopName = shopName;
                this.queryParams.kitchenId = kitchenId;
                kitchenService.getStocksByCategory(this.queryParams)
                    .then(res => {
                        this.stockList = res.data;
                    })
                    .catch(err => {
                        console.log(err);
                    });
                this.openDialog = true;
            },
            onChange() {
                this.kitchen.kitchenStocks = [];
                this.selected = [];
            },
            addToCart(stock) {
                let item = { stockName: ""};
                item.stockName = stock;
                let kitStock = this.kitchen.kitchenStocks;
                for (let ks of kitStock) {
                    if (ks.stockName == item.stockName) {
                        this.select = true;
                    } 
                }
                if ( this.select == false) {
                    this.kitchen.kitchenStocks.push(item);
                } else {
                    for (let index = 0; index < kitStock.length; index++) {
                        if (kitStock[index].stockName === item.stockName) {
                            this.kitchen.kitchenStocks.splice(index, 1);
                            this.select = false;
                            break;
                        }                        
                    }
                }
            },
            init() {
                //for deep linking
                if (this.$route.query.categoryName) {
                    this.queryParams.categoryName = this.$route.query.categoryName;
                }
                if (this.$route.query.shopName) {
                    this.queryParams.shopName = this.$route.query.shopName;
                }
                if (this.$route.query.kitchenId) {
                    this.queryParams.kitchenId = this.$route.query.kitchenId;
                }
                if (this.$route.query.status) {
                    this.queryParams.status = this.$route.query.status;
                }
                if (this.$route.query.menu) {
                    this.queryParams.menu = this.$route.query.menu;
                }
            },
            closeModal() {
                this.$refs.modalForm.resetValidation();
                this.modalError = "";
                this.openDialog = false;
            },
            clear() {
                this.$refs.form.resetValidation();
                if (!this.kitchen.id) {
                    this.kitchen = new Kitchen();
                    this.statusFlag = true;
               }
                else {
                    this.kitchen = Object.assign({}, this.oldKitchen);
                    this.statusFlag = this.kitchen.status == 'ACTIVE' ? true : false;
              }
            },
            goBack() {
                this.$router.push('/kitchens');
            }
        }
    }
</script>

