<template>
    <v-container fluid full-height grid-list-md>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSave">
            <v-layout row wrap>
                <v-flex xs6>
                    <div class="text-xs-left">
                        <v-breadcrumbs :items="breadcrumbs" divider=">">
                            <template slot="item" slot-scope="props">
                                <router-link :to="'/'+props.item.to" :disabled="props.item.disabled"
                                             v-bind:class="{ 'v-breadcrumbs__item v-breadcrumbs__item--disabled': props.item.disabled }">
                                    {{props.item.text}}
                                </router-link>
                            </template>
                        </v-breadcrumbs>
                    </div>
                </v-flex>
                <v-flex xs6>
                    <div class="text-xs-right">
                        <v-btn outline color="primary" @click="goBack">
                            Go Back
                            <v-icon right>arrow_back</v-icon>
                        </v-btn>
                    </div>
                </v-flex>
                <v-flex xs6 offset-sm3>
                    <v-card>
                        <v-layout row wrap class="pa-3">
                            <v-flex sm12 v-if="responseError">
                                <span class="red--text">{{responseError}}</span>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="taxName"
                                        label="Tax Name"
                                        v-model="tax.taxName"
                                        type="text"
                                        :rules="[
                                                        () => !!tax.taxName || 'Tax Name is required',
                                                    ]">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="percentage"
                                        label="Tax percentage"
                                        min = 0
                                        max = 100
                                        oninput="this.value = Math.abs(this.value)"
                                        v-model="tax.taxPercentage"
                                        type="number"
                                        :rules="[
                                                        () => !!tax.taxPercentage || 'Percentage is required',
                                                    ]">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12>
                                <v-select
                                        name = "taxType"
                                        label="Tax Type"
                                        :items="taxTypeList"
                                        v-model="tax.taxType"
                                        item-text="label"
                                        item-value="value"
                                        :rules="[
                                                        () => !!tax.taxType || ' Please Select Tax Type'
                                                        ]">
                                </v-select>
                            </v-flex>
                            <v-flex sm12>
                                <v-textarea 
                                    name="description" 
                                    label="Description" 
                                    v-model="tax.description">
                                </v-textarea>
                            </v-flex>
                            <v-flex sm6>
                                <v-switch
                                        :label="`Status is ${statusFlag?'Active': 'Inactive'}`"
                                        :value="statusFlag"
                                        v-model="statusFlag"
                                ></v-switch>
                            </v-flex>
                            <v-flex sm12>
                                <div class="text-xs-center">
                                    <v-btn color="error" @click="clear">
                                        Reset
                                        <v-icon right>clear</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" :loading="saveLoading" :disabled="!valid" type="submit">
                                        Save
                                        <v-icon right>save</v-icon>
                                    </v-btn>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-form>        
    </v-container>
</template>

<script>
    import { taxManagementService } from "../../services/tax-management-service";
    import {TaxManagement} from "../../models/model";
    import VSwitch from "vuetify/lib/components/VSwitch/VSwitch";


export default {
        name: "TaxDetail",
        components: {VSwitch},
        data: () => ({
            openDialog: false,
            valid: true,
            modalValid: true,
            breadcrumbs: [
                {
                    text: 'Taxs',
                    disabled: false,
                    to: 'taxs'
                },
                {
                    text: 'Tax Management Detail',
                    disabled: true
                }
            ],
            tax: new TaxManagement(),
            oldTax: new TaxManagement(),
            statusFlag: true,
            roleTypeList: [],
            saveLoading: false,
            responseError: '',
            modalError: '',
            modalTax: new TaxManagement(),
            taxTypeList: [
                { label: 'Add On', value: 'ADD_ON'},
                { label: 'Inclusive', value: 'INCLUSIVE' }
            ],
        }),
        created() {
            const id = this.$route.params.id;
            if (id == 'new') {
                // new tax
            }
            else {
                this.getTaxDetail(id);
            }
        },
        methods: {  
            getTaxDetail(id) {
            taxManagementService.getTaxDetail(id)
            .then(res => {
                this.tax = res.data;
                this.oldTax = Object.assign({}, res.data);
                this.statusFlag = this.tax.status == 'ACTIVE' ? true : false;
                })
                .catch(err => {
                    console.log(err);
                })

            },
            onSave() {
                if (this.$refs.form.validate()) {
                    this.saveLoading = true;
                    this.tax.status = this.statusFlag ? 'ACTIVE' : 'INACTIVE';

                    let taxObj = {
                        id: this.tax.id ? this.tax.id : '',
                        taxName: this.tax.taxName,
                        taxPercentage: this.tax.taxPercentage,
                        taxType: this.tax.taxType,
                        description: this.tax.description,
                        status: this.tax.status
                    };
                    taxManagementService.saveTax(taxObj)
                        .then(res => {
                            console.log(res);
                            this.goBack();
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.data) {
                                this.responseError = err.response.data.message;
                            }
                            this.saveLoading = false;
                        });
                }

            },
            clear() {
                this.$refs.form.resetValidation();
                if (!this.tax.id) {
                    this.tax = new TaxManagement();
                    this.statusFlag = true;
                }
                else {
                    this.tax = Object.assign({}, this.oldTax);
                    this.statusFlag = this.tax.status == 'ACTIVE' ? true : false;
                }

                this.responseError = '';
            },
            goBack() {
                this.$router.push('/taxs');
            }        }

}
</script>

