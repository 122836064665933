<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs6>
        <div class="text-xs-left">
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex xs6>
        <div class="text-xs-right">
          <v-btn color="primary" @click="onRegister">
            New System Serialize
            <v-icon right>add_circle</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-card>
          <v-layout row wrap class="pa-3">
            <v-flex sm3>
              <v-text-field name="prefix" label="Prefix" type="text" v-model="queryParams.prefix"></v-text-field>
            </v-flex>
            <v-flex sm3>
              <v-select
                :items="statusList"
                label="Status"
                v-model="queryParams.status"
                item-text="label"
                item-value="value"
              ></v-select>
            </v-flex>
            <v-flex sm3>
              <v-btn outline color="primary" @click="onSearch">
                Search
                <v-icon right>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 class="mt-1">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="systemSerializeList"
            class="elevation-1"
            :loading="showListLoading"
            :total-items="totalRows"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.prefix }}</td>
              <td>{{ props.item.serializeNo }}</td>
              <td>{{ props.item.shopName }}</td>
              <td>{{ props.item.isAutoReset }}</td>
              <td>{{ props.item.serializedDateInMilliSeconds | format-date-only }}</td>
              <td>{{ props.item.createdDateInMilliSeconds | format-ms-date }}</td>
              <td>{{ props.item.createdBy }}</td>
              <td>
                <v-icon v-if="props.item.status=='ACTIVE'" medium color="green">check_circle</v-icon>
                <v-icon v-else medium color="red">cancel</v-icon>
              </td>
              <td>
                <v-btn flat icon color="blue lighten-2" @click="onEdit(props.item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { systemSerializeService } from "../../services/system-serialize-service";

export default {
  name: "SystemSerializeList",

  components: {},
  data: () => ({
    showListLoading: false,
    breadcrumbs: [
      {
        text: "User Management",
        disabled: true
      },
      {
        text: "System Serialize",
        disabled: true
      }
    ],
    headers: [
      { text: "Prefix", align: "left", value: "prefix" },
      { text: "Serialize No", value: "serializeNo" },
      { text: "Shop Name", value: "shopName" },
      { text: "Auto Reset", value: "isAutoReset" },
      { text: "Serialized Date", value: "createdDate" },
      { text: "Created Date", value: "createdDate" },
      { text: "Created By", value: "createdBy" },
      { text: "Status", value: "status", sortable: false },
      { text: "Action", value: "name", sortable: false }
    ],
    rowsPerPageItems: [25, 50, 100],
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 50,
      sortBy: "prefix",
      totalItems: 0
    },
    systemSerializeList: [],
    totalRows: 0,
    statusList: [
      { label: "Active", value: "ACTIVE" },
      { label: "Inactive", value: "INACTIVE" }
    ],
    queryParams: {
      prefix: "",
      serializeNo: "",
      isAutoReset: "",
      status: ""
    }
  }),
  watch: {
    pagination: {
      handler() {
        this.getSystemSerializeList();
      },
      deep: true
    }
  },
  created() {
    //for deep linking
    if (this.$route.query.prefix) {
      this.queryParams.prefix = this.$route.query.prefix;
    }
    if (this.$route.query.status) {
      this.queryParams.status = this.$route.query.status;
    }
    if (this.$route.query.page) {
      this.pagination.page = this.$route.query.page;
    }
    if (this.$route.query.size) {
      this.pagination.size = this.$route.query.size;
    }
  },
  methods: {
    onSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.getSystemSerializeList();
    },
    getSystemSerializeList() {
      this.showListLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";
      systemSerializeService
        .getSystemSerializeList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        )
        .then(res => {
          if (res.data) {
            this.systemSerializeList = res.data.systemSerializeList;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    onRegister() {
      this.$router.push("/system-serialize/new");
    },
    onEdit(systemSerialize) {
      // go to details page
      this.$router.push("/system-serialize/" + systemSerialize.id);
    }
  }
};
</script>