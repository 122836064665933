<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="login">
    <v-app id="login" class="primary">
      <v-content>
        <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4 lg4>
              <v-card class="elevation-1 pa-3">
                <v-card-text>
                  <div class="layout column align-center">
                    <img
                      src="../../assets/images/login-logo.png"
                      alt="Vue Material Admin"
                      height="135"
                    />
                  </div>
                  <span class="red--text" v-if="responseError">{{responseError}}</span>
                  <v-text-field
                    append-icon="person"
                    ref="username"
                    name="username"
                    label="Username"
                    type="text"
                    v-model="user.username"
                    :rules="rules.usernameRules"
                  ></v-text-field>
                  <v-text-field
                    append-icon="lock"
                    name="password"
                    label="Password"
                    id="password"
                    type="password"
                    v-model="user.password"
                    :rules="rules.passwordRules"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn
                    block
                    type="submit"
                    color="primary"
                    :loading="loading"
                    :disabled="!valid"
                  >Login</v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-content>
    </v-app>
  </v-form>
</template>

<script>
import VLabel from "vuetify/lib/components/VLabel/VLabel";
import { userService } from "../../services/user-service";
import { LoginRules } from "../../helpers/validation-rules";

export default {
  components: { VLabel },
  data: () => ({
    loading: false,
    responseError: "",
    user: {
      username: "",
      password: ""
    },
    valid: true,
    rules: new LoginRules()
  }),

  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        userService
          .login(this.user)
          .then(res => {
            localStorage.setItem("user", JSON.stringify(res.data.user));
            let user = res.data.user;
            let userRole = user.role.roleName;
            if (userRole == "ADMIN" || userRole == "SYSADMIN") {
              this.$router.push("/dashboard");
            } else if (userRole == "CUSTOMER") {
              this.$router.push("/order");
            }
          })
          .catch(error => {
            if (error.response.data) {
              this.responseError = error.response.data.message;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}

.header-font {
  font-size: 38px;
  font-weight: 100;
}

.container.fluid {
  margin-bottom: 0px !important;
}
</style>
