<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <div class="text-xs-left">
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-card>
          <v-layout row wrap class="pa-3">
            <v-flex sm3>
              <v-menu
                ref="menu"
                v-model="fromTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="queryParams.fromTime"
                    label="From Time"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="fromTime"
                  format="24hr"
                  v-model="queryParams.fromTime"
                  full-width
                  @click:minute="$refs.menu.save(queryParams.fromTime)"
                ></v-time-picker>
              </v-menu>
            </v-flex>
            <v-flex sm3>
              <v-menu
                ref="menu1"
                v-model="toTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="queryParams.toTime"
                    label="From Time"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="toTime"
                  format="24hr"
                  v-model="queryParams.toTime"
                  full-width
                  @click:minute="$refs.menu1.save(queryParams.toTime)"
                ></v-time-picker>
              </v-menu>
            </v-flex>
            <v-flex sm3>
              <v-text-field name="saleNo" label="Sale No" v-model="queryParams.saleNo" type="text"></v-text-field>
            </v-flex>
            <v-flex sm3>
              <v-btn outline color="primary" @click="onSearch">
                Search
                <v-icon right>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 class="mt-1">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="saleReportByCounterList"
            class="elevation-1"
            :loading="showListLoading"
            :total-items="totalRows"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
            expand
          >
            <template slot="items" slot-scope="props">
              <tr @click="props.expanded = !props.expanded">
                <td>{{ props.item.createdDateInMilliSeconds | format-ms-date }}</td>
                <td>{{ props.item.saleNo }}</td>
                <td>{{ props.item.counterName }}</td>
                <td>{{ props.item.netAmount | numFormat }}</td>
                <td>{{ props.item.taxAmount | numFormat }}</td>
                <td>{{ props.item.totalAmount | numFormat}}</td>
                <td>{{ props.item.payAmount | numFormat}}</td>
                <td>{{ props.item.payChange | numFormat }}</td>
              </tr>  
            </template>
            <template slot="expand" slot-scope="props">
              <v-card class="elevation-1">
                <v-card-text>
                  <v-data-table
                    :headers="subHeaders"
                    :items="props.item.saleItems"
                    item-key="props.item.id"
                    hide-actions
                    class="elevation-10"
                  >
                    <template slot="items" slot-scope="subprops">
                      <td class="text-xs">{{ subprops.item.stockName }}</td>
                      <td class="text-xs">{{ subprops.item.unitPrice }}</td>
                      <td class="text-xs">{{ subprops.item.qty }}</td>
                      <td class="text-xs">{{ subprops.item.totalPrice }}</td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { reportService } from "../../services/report-service";

export default {
  name: "SaleReportByCounterList",

  components: {},
  data: () => ({
    time: false,
    fromTime: false,
    toTime: false,
    showListLoading: false,
    breadcrumbs: [
      {
        text: "Reports",
        disabled: true
      },
      {
        text: "Sale Report",
        disabled: true
      }
    ],
    headers: [
      { text: "Sale Date", align: "left", value: "createdDate" },
      { text: "Sale No", value: "saleNo" },
      { text: "Counter", value: "counterName" },
      { text: "Net Amount", value: "netAmount" },
      { text: "Tax", value: "tax" },
      { text: "Total", value: "totalAmount" },
      { text: "Recived", value: "recived" },
      { text: "Pay Change", value: "payChange" }
    ],
    subHeaders: [
      { text: "Item Name", sortable: false, value: "itemName" },
      { text: "Unit Price", sortable: false, value: "unitPrice" },
      { text: "Qty", sortable: false, value: "qty" },
      { text: "Total Price", sortable: false, value: "totalPrice" }
    ],
    rowsPerPageItems: [25, 50, 100],
    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 50,
      sortBy: "saleNo",
      totalItems: 0
    },
    saleReportByCounterList: [],
    totalRows: 0,
    queryParams: {
      fromTime: "00:00",
      toTime: "23:59",
      saleNo: ""
    }
  }),
  watch: {
    pagination: {
      handler() {
        this.getSaleReportByCounterList();
      },
      deep: true
    }
  },
  created() {
    this.onSearch();
    //for deep linking
    if (this.$route.query.saleNo) {
      this.queryParams.saleNo = this.$route.query.saleNo;
    }
    if (this.$route.query.fromTime) {
      this.queryParams.fromTime = this.$route.query.fromTime;
    }
    if (this.$route.query.toTime) {
      this.queryParams.toTime = this.$route.query.toTime;
    }
    if (this.$route.query.page) {
      this.pagination.page = this.$route.query.page;
    }
    if (this.$route.query.size) {
      this.pagination.size = this.$route.query.size;
    }
  },
  methods: {
    onSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.$router.replace({
        name: "sale-report-by-counter",
        query: this.queryParams
      });
      this.getSaleReportByCounterList();
    },
    getSaleReportByCounterList() {
      this.showListLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";
      reportService
        .getSaleReportByCounterList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        )
        .then(res => {
          if (res.data) {
            this.saleReportByCounterList = res.data.saleList;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    }
  }
};
</script>
