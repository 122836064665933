<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <div class="text-xs-left">
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex sm4>
            <v-menu
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="queryParams.fromOrderDate"
                label="From Date"
                prepend-icon="event"
                readonly
              ></v-text-field>
              <v-date-picker v-model="queryParams.fromOrderDate"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex sm4>
            <v-menu
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="queryParams.toOrderDate"
                label="To Date"
                prepend-icon="event"
                readonly
              ></v-text-field>
              <v-date-picker v-model="queryParams.toOrderDate"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex sm4>
            <v-text-field
              name="productName"
              label="Product Name"
              v-model="queryParams.productName"
              type="text"
            ></v-text-field>
          </v-flex>
          <v-flex sm4>
            <v-text-field name="sku" label="SKU" v-model="queryParams.sku" type="text"></v-text-field>
          </v-flex>
          <v-flex sm4>
            <v-select
              :items="categoryList"
              label="Choose category..."
              item-text="name"
              item-value="id"
              menu-props="auto"
              v-model="queryParams.categoryId"
            ></v-select>
          </v-flex>
          <v-flex sm4>
            <v-btn outline color="primary" @click="onSearch">
              Search
              <v-icon right>search</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex d-flex xs12>
        <v-card>
          <v-card-text class="font-weight-bold">
            <div>
              <v-btn color="primary" @click="toPrint">PRINT ALL</v-btn>
              <v-btn color="success" @click="toExport">EXPORT ALL </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 class="mt-1">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="productOrderReportList"
            class="elevation-1"
            :loading="showListLoading"
            :total-items="totalRows"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.productName }}</td>
              <td>{{ props.item.sku }}</td>
              <td>{{ props.item.categoryName }}</td>
              <td>{{ props.item.totalQty }}</td>
              <td>{{ props.item.totalPrice | numFormat}}</td>
              <td>{{ props.item.totalAmount | numFormat}}</td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <summary report> -->
    <div id="printProductOrders" style="bsale:1px solid black;width:300px;" v-show="false">
      <div style="text-align:center">
        <p style="font-size:18px;margin:1px">Product Order Report</p>
      </div>
      <div style="text-align:center">
        <p
          style="font-size:15px;margin:1px"
        >From: [{{queryParams.fromOrderDate}}] To: [{{queryParams.toOrderDate}}]</p>
      </div>
      <br />
      <div style="padding-left:5px;padding-right:5px">
        <table
          style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;border-top:1px solid black"
        >
          <thead style="border-bottom:1px solid black;border-top:1px solid black">
            <tr>
              <th style="text-align:left">Product Name</th>
              <th style="text-align:left">SKU</th>
              <th style="text-align:left">Category</th>
              <th style="text-align:left">Qty</th>
              <th style="text-align:left">Price</th>
              <th style="text-align:left">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allProductOrderReportList" :key="index">
              <td style="text-align:left">{{item.productName}}</td>
              <td style="text-align:left">{{item.sku}}</td>
              <td style="text-align:left">{{item.categoryName}}</td>
              <td style="text-align:left">{{item.totalQty}}</td>
              <td style="text-align:left">{{item.totalPrice | numFormat}}</td>
              <td style="text-align:left">{{item.totalAmount | numFormat}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-container>
</template>

<script>
import { commonService } from "../../services/common-service";
import { reportService } from "../../services/report-service";
import { categoryService } from "../../services/category-service";

export default {
  components: {},
  data: () => ({
    showListLoading: false,
    categoryList: [],
    productOrderReportList: [],
    allProductOrderReportList: [],
    breadcrumbs: [
      {
        text: "Reports",
        disabled: true
      },
      {
        text: "Product Order Report",
        disabled: true
      }
    ],
    headers: [
      {
        text: "Product Name",
        align: "left",
        value: "productName",
        sortable: false
      },
      { text: "SKU", align: "left", value: "sku", sortable: false },
      {
        text: "Category",
        align: "left",
        value: "categoryName",
        sortable: false
      },
      { text: "Qty", value: "totalQty", sortable: false },
      { text: "Price", value: "totalPrice", sortable: false },
      { text: "Total Amount", value: "totalAmount", sortable: false }
    ],
    rowsPerPageItems: [25, 50, 100],
    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 25,
      totalItems: 0
    },
    totalRows: 0,
    queryParams: {
      fromOrderDate: commonService.formatDate(new Date(), "YYYY-MM-DD"),
      toOrderDate: commonService.formatDate(new Date(), "YYYY-MM-DD"),
      productName: "",
      categoryId: "",
      sku: ""
    },
    isSearch: false
  }),
  watch: {
    pagination: {
      handler() {
        this.onSearch();
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    //for deep linking
    if (this.$route.query.categoryId) {
      this.queryParams.categoryId = this.$route.query.categoryId;
    }
    if (this.$route.query.productName) {
      this.queryParams.productName = this.$route.query.productName;
    }
    if (this.$route.query.sku) {
      this.queryParams.sku = this.$route.query.sku;
    }
    if (this.$route.query.fromOrderDate) {
      this.queryParams.fromOrderDate = this.$route.query.fromOrderDate;
    }
    if (this.$route.query.toOrderDate) {
      this.queryParams.toOrderDate = this.$route.query.toOrderDate;
    }
    if (this.$route.query.page) {
      this.pagination.page = this.$route.query.page;
    }
    if (this.$route.query.size) {
      this.pagination.size = this.$route.query.size;
    }
    this.getCategories();
  },
  methods: {
    onSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.$router.replace({
        name: "product-order-report",
        query: this.queryParams
      });

      this.getProductOrderReport();
    },
    getCategories() {
      categoryService
        .getAllCategories()
        .then(res => {
          this.categoryList = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getProductOrderReport() {
      this.showListLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";

      reportService
        .getProductOrderReportList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        )
        .then(res => {
          if (res.data) {
            this.productOrderReportList = res.data.productOrderReportList;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    toPrint() {
      this.getAllProductOrderReport();
    },
    toExport() {
      reportService
        .excelExportProductOrderReportList(this.queryParams)
        .then(res => {
          commonService.downloadE(res);
        })
        .catch(err => {
          console.log(err);
        });
    },
    printAllProductReport(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      location.reload(true);
    },
    getAllProductOrderReport() {
      this.showListLoading = true;
      reportService
        .getProductOrderReportList(this.queryParams)
        .then(res => {
          if (res.data) {
            this.allProductOrderReportList = res.data.productOrderReportList;
          }
        })
        .then(() => {
          this.printAllProductReport("printProductOrders");
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    clear() {
      this.queryParams = {
        productName: "",
        categoryId: "",
        sku: ""
      };
      this.onSearch();
    }
  }
};
</script>
