<template>
  <div id="orderNotePrint" style="bsale:1px solid black;width:300px;" v-show="false">
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">SHAKE</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">Order Note</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">{{sale.saleType}}</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">Invoice #: {{sale.saleNo}}</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px" id="orderNoteDate"></p>
    </div>
    <div style="float:left;padding-left:11px">
      <p style="font-size:11px;margin:0px" id="counter">Table #: {{sale.tableNo}}</p>
    </div>
    <div style="float:right;padding-right:11px">
      <p style="font-size:11px;margin:0px" id="casher">Casher: {{sale.username}}</p>
    </div>
    <div style="clear:both"></div>
    <div style="height:10px"></div>
    <div style="padding-left:5px;padding-right:5px">
      <table
        style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;"
      >
        <thead style="border-bottom:1px solid black;border-top:1px solid black">
          <tr>
            <th></th>
            <th style="text-align:center">Qty</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in sale.saleItems" :key="index">
            <td style="text-align:left">{{item.stockName}}</td>
            <td style="text-align:center">{{item.qty}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "order-note",
  props: { data: Object },
  data: function() {
    return {
      sale: this.data
    };
  }
};
</script>

<style>
</style>
