import axios from "../axios-auth";

export const tableInfoService = {
  getAllShops,
  saveTableInfo,
  getTableInfoList,
  getTableInfoDetail,
  getTableInfosByStatusAndShop
};

function getAllShops() {
  return axios.get("/auth/shops");
}

function saveTableInfo(tableInfo) {
  if (tableInfo.id) {
    //edit
    return axios.put("/auth/table-info", tableInfo);
  } else {
    // create new
    return axios.post("/auth/table-info", tableInfo);
  }
}

function getTableInfoList(searchParams, page, size, sortedBy) {
  let url = '';
  if (!page) {
    page = 0;
  } else {
    page -= 1;
  }
  if (!size) {
    size = 50;
  }
  if (sortedBy == 'null,asc') {
    url = "/auth/table-infos?page=" + page + "&size=" + size;
  } else {
    url = "/auth/table-infos?page=" + page + "&size=" + size + "&sort=" + sortedBy;
  }
  if (searchParams.tableNo) {
    url += "&tableNo=" + searchParams.tableNo;
  }
  if (searchParams.flowLocation) {
    url += "&flowLocation=" + searchParams.flowLocation;
  }
  if (searchParams.shop) {
    url += "&shop=" + searchParams.shop;
  }
  if (searchParams.status) {
    url += "&status=" + searchParams.status;
  }
  return axios.get(url);
}

function getTableInfoDetail(id) {
  return axios.get("/auth/table-info/" + id);
}

function getTableInfosByStatusAndShop(status, shopId) {
  return axios.get(
    "/auth/table-infos-by-status-shop?status=" + status + "&shopId=" + shopId
  );
}
