<template>
  <div>
    <app-navigation-drawer v-if="authGuard.isAuthenticated()"></app-navigation-drawer>
    <v-content>
     <slot></slot>
    </v-content>
  </div>
</template>

<script>
import NavigationDrawer from "./NavigationDrawer";
import { AuthGuard } from "../../helpers/auth-guard";

export default {
  data: () => ({
    authGuard: AuthGuard
  }),
  components: {
    appNavigationDrawer: NavigationDrawer
  },
  props: {
    source: String
  }
};
</script>
<style>
@import "../../assets/css/style.css";

/*.container.fluid{*/
/*margin-bottom: 40px;*/
/*}*/
</style>