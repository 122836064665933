<template>

    <v-container fluid full-height grid-list-md>
        <v-layout row wrap>
            <v-flex xs6>
                <div class="text-xs-left">
                    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
                </div>
            </v-flex>
            <v-flex xs6>
                <div class="text-xs-right">
                    <v-btn color="primary" @click="onRegister">
                        New Counter Cash
                        <v-icon right>add_circle</v-icon>
                    </v-btn>
                </div>
            </v-flex>
            <v-flex xs12>
                <v-card>
                    <v-layout row wrap class="pa-3">
                        <v-flex sm4>
                            <v-select
                                :items="cashTypeList"
                                label="Cash Type"
                                v-model="queryParams.cashType"
                                item-text="label"
                                item-value="value">
                            </v-select>
                        </v-flex>
                        <v-flex sm4>
                            <v-select
                                :items="counterList"
                                label="Counter Name"
                                item-text="name"
                                item-value="id"
                                v-model="queryParams.counterId">
                            </v-select>
                        </v-flex>
                        <v-flex sm4>
                            <v-btn outline color="primary" @click="onSearch">
                                Search
                                <v-icon right>search</v-icon>
                            </v-btn>
                        </v-flex>
                        <v-flex xs12 class="mt-1">
                            <v-card>
                                <v-data-table
                                    :headers="headers"
                                    :items="counterCashDTOList"
                                    class="elevation-1"
                                    :loading="showListLoading"
                                    :total-items="totalRows"
                                    :rows-per-page-items="rowsPerPageItems"
                                    :pagination.sync="pagination">
                                        <template slot="items" slot-scope="props">
                                            <td>{{ props.item.counterName }}</td>
                                            <td>{{ props.item.cashType }}</td>
                                            <td>{{ props.item.amount | numFormat}}</td>
                                            <td>{{ props.item.createdDateInMilliSeconds | format-ms-date }}</td>
                                            <td>{{ props.item.createdBy }}</td>
                                            <td>{{ props.item.description }}</td>
                                            <td>
                                                <v-btn flat icon color="blue lighten-2" @click="onEdit(props.item)">
                                                    <v-icon>edit</v-icon>
                                                </v-btn>
                                            </td>
                                      </template>
                                </v-data-table>
                            </v-card>
                        </v-flex>

                    </v-layout>
                </v-card>
            </v-flex>

        </v-layout>
    </v-container>
</template>

<script>
import { counterCashService } from "../../services/counter-cash-service";
import { counterService } from "../../services/counter-service";

export default {
  name: "CounterCashList",

  components: {},
  data: () => ({
    showListLoading: false,
    breadcrumbs: [
      {
        text: 'Counter Cash',
        disabled: true
      },
      {
        text: 'CounterCashs',
        disabled: true
      }
    ],
    headers: [
        { text: 'Counter Name', value: 'counterName' },
        { text: 'Cash Type', value: 'cashType' },
        { text: 'Amount', value: 'amount' },
        { text: 'Created Date', value: 'createdDate' },
        { text: 'Created By', value: 'createdBy' },
        { text: 'Description', value: 'description' },
        { text: 'Action', value: 'name', sortable: false }
    ],
    rowsPerPageItems: [25, 50, 100],
    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 50,
      totalItems: 0
    },
    counterCashDTOList: [],
    totalRows: 0,
    counterList: [],
    cashTypeList: [
      { label: 'Float', value: 'FLOAT'},
      { label: 'Pick Up', value: 'PICK_UP' }
    ],
    queryParams: {
      cashType: '',
      counterId: '',
      shopId: ''
    }
  }),
    watch: {
        pagination: {
            handler() {
                this.getCounterCashList();
            },
            deep: true
        }
    },
    created() {
        this.getCountersByShopId();
        //for deep linking
        let user = JSON.parse(localStorage.getItem("user"));
        this.queryParams.shopId = user.shopId;
        if (this.$route.query.cashType) {
            this.queryParams.cashType = this.$route.query.cashType;
        }
        if(this.$route.query.counterId){
            this.queryParams.counterId = this.$route.query.counterId;
        }
        if (this.$route.query.page) {
            this.pagination.page = this.$route.query.page;
        }
        if (this.$route.query.size) {
            this.pagination.size = this.$route.query.size;
        }
    },
    methods: {
        getCountersByShopId() {
        let user = JSON.parse(localStorage.getItem("user"));
            counterService
            .getCountersByShopId(user.shopId)
            .then(res => {
                let tempList = res.data;
                for (let temp of tempList) {
                   let counter = {
                        id: temp.id,
                        name: temp.name
                };
                this.counterList.push(counter);
             }
        })
        .catch(err => {
          console.log(err);
        });
    },

    onSearch() {
        this.queryParams.page = this.pagination.page;
        this.queryParams.size = this.pagination.rowsPerPage;
        this.$router.replace({ name: "counter-cashs", query: this.queryParams });
        this.getCounterCashList();
    },
    getCounterCashList(){
        this.showListLoading = true;
        let order = this.pagination.descending ? "desc" : "asc";
        counterCashService
        .getCounterCashList(
            this.queryParams,
            this.pagination.page,
            this.pagination.rowsPerPage,
            this.pagination.sortBy ? this.pagination.sortBy + "," + order : ""
        )
        .then(res => {
          console.log(res);
          if (res.data) {
            this.counterCashDTOList = res.data.counterCashDTOList;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    onRegister() {
      this.$router.push('/countercash/new');
    },
    onEdit(counterCash) {
      // go to details page
      this.$router.push('/countercash/' + counterCash.id);
    }  
}

}
</script>

<style scoped>

</style>




