<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <div class="text-xs-left">
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex d-flex xs12 sm8 md4>
        <v-layout row wrap>
          <v-flex d-flex>
            <v-menu
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="queryParams.fromDate"
                label="From Date"
                prepend-icon="event"
                readonly
              ></v-text-field>
              <v-date-picker v-model="queryParams.fromDate"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex sm12>
            <v-text-field
              name="counter"
              label="Counter Name"
              v-model="queryParams.counterName"
              type="text"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex d-flex xs12 sm8 md4>
        <v-layout row wrap>
          <v-flex d-flex>
            <v-menu
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="queryParams.toDate"
                label="To Date"
                prepend-icon="event"
                readonly
              ></v-text-field>
              <v-date-picker v-model="queryParams.toDate"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex sm12>
            <v-select
              :items="cashTypeList"
              label="Cash Type"
              v-model="queryParams.cashType"
              item-text="label"
              item-value="value"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex d-flex xs12 sm8 md4>
        <v-layout row wrap>
          <v-flex d-flex>
            <v-select
              :items="shopList"
              label="Choose Shop..."
              item-text="name"
              menu-props="auto"
              v-model="queryParams.shopName"
            ></v-select>
          </v-flex>
          <v-flex sm12>
            <v-btn outline color="primary" @click="onSearch">
              Search
              <v-icon right>search</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex d-flex xs12>
        <v-card>
          <v-card-title class="blue darken-3">
            <span class="headline white--text">Summary</span>
          </v-card-title>
          <v-card-text class="font-weight-bold">
            <div>
              <span>Total Counter Cash:</span>
              <v-chip>
                <span>{{totalCounterCashAmount | numFormat}}</span>
              </v-chip>
              <v-btn color="primary" @click="printAllCounterCash('printCounterCash')">PRINT ALL</v-btn>
            </div>
          </v-card-text>
          <v-data-table :headers="summaryHeaders" :items="counterCashSummaryList" :rows-per-page-items="rowsPerPageItems2" :pagination.sync="pagination2"
            expand>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.counterName }}</td>
              <td>{{ props.item.cashType }}</td>
              <td>{{ props.item.counterCashAmount | numFormat}}</td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-flex xs12 class="mt-1">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="counterCashSummaryDetailList"
            class="elevation-1"
            :loading="showListLoading"
            :total-items="totalRows"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.createdDateInMilliSeconds | format-ms-date }}</td>
              <td>{{ props.item.counterName }}</td>
              <td>{{ props.item.cashType }}</td>
              <td>{{ props.item.counterCashAmount | numFormat}}</td>
              <td>{{ props.item.description }}</td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <All Counter Cash Report> -->
    <div id="printCounterCash" style="bsale:1px solid black;width:300px;" v-show="false">
      <div style="text-align:center">
        <p style="font-size:18px;margin:1px">Counter Cash Summary</p>
      </div>
      <div style="text-align:center">
        <p style="font-size:15px;margin:1px">
          From: [{{queryParams.fromDate}}]  To: [{{queryParams.toDate}}]
        </p>
      </div>
      <div style="text-align:left">
        <p style="font-size:15px;margin:0px">Total Counter Cash: {{totalCounterCashAmount | numFormat}}</p>
      </div>
      <br>
      <div style="padding-left:5px;padding-right:5px">
        <table
          style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;"
        >
          <thead style="border-bottom:1px solid black;border-top:1px solid black">
            <tr>
              <th style="text-align:left">Counter Name</th>
              <th style="text-align:left">Cash Type</th>
              <th style="text-align:left">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in counterCashSummaryList" :key="index">
              <td style="text-align:left">{{item.counterName}}</td>
              <td style="text-align:left">{{item.cashType}}</td>
              <td style="text-align:left">{{item.counterCashAmount | numFormat}}</td>
            </tr>
          </tbody>
        </table>
        <div style="height:10px"></div>
        <table
          style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;border-top:1px solid black"
        >
          <thead style="border-bottom:1px solid black;border-top:1px solid black">
            <tr>
              <th style="text-align:left">Date</th>
              <th style="text-align:left">Counter Name</th>
              <th style="text-align:left">Cash Type</th>
              <th style="text-align:left">Amount</th>
              <th style="text-align:left">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allCounterCashReportList" :key="index">
              <td style="text-align:left">{{item.createdDateInMilliSeconds | format-ms-date}}</td>
              <td style="text-align:left">{{item.counterName}}</td>
              <td style="text-align:left">{{item.cashType}}</td>
              <td style="text-align:left">{{item.counterCashAmount | numFormat}}</td>
              <td style="text-align:left">{{item.description}}</td>
            </tr>
          </tbody>
          
        </table>
      </div>
    </div>
  </v-container>
</template>
<script>
import { commonService } from "../../services/common-service";
import { counterCashService } from "../../services/counter-cash-service";
import { shopService } from "../../services/shop-service";
import { reportService } from "../../services/report-service";
export default {
  name: "CounterCashReportList",
  components: {},
  data: () => ({
    showListLoading: false,
    dialog: false,
    allCounterCashReportList: [],
    breadcrumbs: [
      {
        text: "Reports",
        disabled: true
      },
      {
        text: "Counter Cash Report",
        disabled: true
      }
    ],
    headers: [
      { text: "Date", align: "left", value: "createdDate" },
      { text: "Counter Name", value: "counterName" },
      { text: "CashType", value: "cashType" },
      { text: "Amount", value: "amount" },
      { text: "Description", value: "description" }

    ],
    summaryHeaders: [
      { text: "Counter Name", align: "left", value: "counterName" },
      { text: "Cash Type", value: "cashType" },
      { text: "Total Amount", value: "counterCashAmount" }
    ],
    rowsPerPageItems: [25, 50, 100],
    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 50,
      totalItems: 0
    },
    rowsPerPageItems2: [25, 50, 100],
    pagination2: {
      descending: false,
      page: 1,
      rowsPerPage: 50,
      totalItems: 0
    },
    shopList: [],
    totalRows: 0,
    totalCounterCashAmount: 0,
    counterCashSummaryList: [],
    counterCashSummaryDetailList: [],
    cashTypeList: [
      { label: "Float", value: "FLOAT" },
      { label: "Pick Up", value: "PICK_UP" }
    ],
    queryParams: {
      fromDate: commonService.formatDate(new Date(), "YYYY-MM-DD"),
      toDate: commonService.formatDate(new Date(), "YYYY-MM-DD"),
      counterName: "",
      shopName: "",
      cashType: ""
    }
  }),
  watch: {
    pagination: {
      handler() {
        this.counterCashReportList();
        this.counterCashSummaryDetail();
        this.getAllCounterCashReportList();
      },
      deep: true
    }
  },
  created() {
    this.onSearch();
    if (this.$route.query.cashType) {
      this.queryParams.cashType = this.$route.query.cashType;
    }
    if (this.$route.query.counterName) {
      this.queryParams.counterName = this.$route.query.counterName;
    }
    if (this.$route.query.shopName) {
      this.queryParams.shopName = this.$route.query.shopName;
    }
    if (this.$route.query.fromDate) {
      this.queryParams.fromDate = this.$route.query.fromDate;
    }
    if (this.$route.query.toDate) {
      this.queryParams.toDate = this.$route.query.toDate;
    }
    if (this.$route.query.page) {
      this.pagination.page = this.$route.query.page;
    }
    if (this.$route.query.size) {
      this.pagination.size = this.$route.query.size;
    }
  },
  methods: {
    onSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.$router.replace({
        name: "counter-cash-report",
        query: this.queryParams
      });
      this.counterCashReportList();
      this.counterCashSummaryDetail();
      this.getAllShop();
      this.getAllCounterCashReportList()
    },
    counterCashSummaryDetail() {
      this.showListLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";
      counterCashService
        .counterCashSummaryDetail(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy ? this.pagination.sortBy + "," + order : ""
        )
        .then(res => {
          if (res.data) {
            this.counterCashSummaryDetailList =
              res.data.counterCashSummaryDetailList;
            this.counterCashAmount = res.data.counterCashAmount;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    getAllShop() {
      shopService
        .getAllShops()
        .then(res => {
          let tempList = res.data;
          for (let temp of tempList) {
            let shop = {
              id: temp.id,
              name: temp.name
            };
            this.shopList.push(shop);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    counterCashReportList() {
      this.showListLoading = true;
      counterCashService
        .counterCashSummary(this.queryParams)
        .then(res => {
          if (res.data) {
            this.counterCashSummaryList = res.data.counterCashSummaryList;
            this.totalCounterCashAmount = res.data.totalCounterCashAmount;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    printAllCounterCash(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      location.reload(true);
    },
    getAllCounterCashReportList() {
      this.showListLoading = true;
      reportService
        .getAllCounterCashReportList(this.queryParams)
        .then(res => {
          if(res.data) {
            this.allCounterCashReportList = res.data.counterCashSummaryDetailList;
            console.log(this.allCounterCashReportList.length);
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    clear() {
      this.queryParams.data = commonService.formatDate(
        new Date(),
        "YYYY-MM-DD"
      );
    }
  }
};
</script>

