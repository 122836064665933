<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <div class="text-xs-left">
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex sm4>
            <v-menu
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="queryParams.fromDate"
                label="From Date"
                prepend-icon="event"
                readonly
              ></v-text-field>
              <v-date-picker v-model="queryParams.fromDate"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex sm4>
            <v-menu
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="queryParams.toDate"
                label="To Date"
                prepend-icon="event"
                readonly
              ></v-text-field>
              <v-date-picker v-model="queryParams.toDate"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex sm4>
            <v-text-field
              name="saleNo"
              label="Menu Name"
              v-model="queryParams.menuName"
              type="text"
            ></v-text-field>
          </v-flex>
          <v-flex sm4>
            <v-text-field
              name="saleNo"
              label="Category"
              v-model="queryParams.categoryName"
              type="text"
            ></v-text-field>
          </v-flex>
          <v-flex sm4>
            <v-select
              :items="shopList"
              label="Choose Shop..."
              item-text="name"
              menu-props="auto"
              v-model="queryParams.shopName"
            ></v-select>
          </v-flex>
          <v-flex sm4>
            <v-btn outline color="primary" @click="onSearch">
              Search
              <v-icon right>search</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex d-flex xs12>
        <v-card>
          <v-card-text class="font-weight-bold">
            <div>
              <v-btn color="primary" @click="printAllMenu('printMenu')">PRINT ALL</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 class="mt-1">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="menuReportList"
            class="elevation-1"
            :loading="showListLoading"
            :total-items="totalRows"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.categoryName }}</td>
              <td>{{ props.item.menuName }}</td>
              <td>{{ props.item.shopName }}</td>
              <td>{{ props.item.qty }}</td>
              <td>{{ props.item.totalPrice | numFormat}}</td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <summary report> -->
    <div id="printMenu" style="bsale:1px solid black;width:300px;" v-show="false">
      <div style="text-align:center">
        <p style="font-size:18px;margin:1px">Sale Summary</p>
      </div>
      <div style="text-align:center">
        <p style="font-size:15px;margin:1px">
          From: [{{queryParams.fromDate}}]  To: [{{queryParams.toDate}}]
        </p>
      </div>
      <br>
      <div style="padding-left:5px;padding-right:5px">
        <table style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;border-top:1px solid black">
          <thead style="border-bottom:1px solid black;border-top:1px solid black">
            <tr>
              <th style="text-align:left">Category</th>
              <th style="text-align:left">Menu Name</th>
              <th style="text-align:left">Shop Name</th>
              <th style="text-align:left">Qty</th>
              <th style="text-align:left">NTotal Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allMenuReportList" :key="index">
              <td style="text-align:left">{{item.categoryName}}</td>
              <td style="text-align:left">{{item.menuName}}</td>
              <td style="text-align:left">{{item.shopName}}</td>
              <td style="text-align:left">{{item.qty}}</td>
              <td style="text-align:left">{{item.totalPrice | numFormat}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-container>
</template>

<script>
import { commonService } from "../../services/common-service";
import { reportService } from "../../services/report-service";
import { shopService } from "../../services/shop-service";

export default {
  name: "MenuReportList",

  components: {},
  data: () => ({
    showListLoading: false,
    shopList: [],
    allMenuReportList: [],
    breadcrumbs: [
      {
        text: "Reports",
        disabled: true
      },
      {
        text: "Menu Report",
        disabled: true
      }
    ],
    headers: [
      { text: "Category", align: "left", value: "c.name" },
      { text: "Menu Name", align: "left", value: "s.name" },
      { text: "Shop Name", align: "left", value: "sp.name" },
      { text: "Qty", value: "sumQty" },
      { text: "Total Amount", value: "sumTotalPrice" }
    ],
    rowsPerPageItems: [25, 50, 100],
    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 50,
      sortBy: "s.name",
      totalItems: 0
    },
    menuReportList: [],
    totalRows: 0,
    queryParams: {
      fromDate: commonService.formatDate(new Date(), "YYYY-MM-DD"),
      toDate: commonService.formatDate(new Date(), "YYYY-MM-DD"),
      menuName: "",
      categoryName:"",
      shopName: ""
    }
  }),
  watch: {
    pagination: {
      handler() {
        this.getMenuReportList();
        this.getAllMenuReportList();
      },
      deep: true
    }
  },
  created() {
    //for deep linking
    if (this.$route.query.categoryName) {
      this.queryParams.categoryName = this.$route.query.categoryName;
    }
    if (this.$route.query.menuName) {
      this.queryParams.menuName = this.$route.query.menuName;
    }
    if (this.$route.query.shopName) {
      this.queryParams.shopName = this.$route.query.shopName;
    }
    if (this.$route.query.fromDate) {
      this.queryParams.fromDate = this.$route.query.fromDate;
    }
    if (this.$route.query.toDate) {
      this.queryParams.toDate = this.$route.query.toDate;
    }
    if (this.$route.query.page) {
      this.pagination.page = this.$route.query.page;
    }
    if (this.$route.query.size) {
      this.pagination.size = this.$route.query.size;
    }
    this.getAllShop();
  },
  methods: {
    onSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.$router.replace({ name: "menu-report", query: this.queryParams });
      this.getMenuReportList();
      this.getAllMenuReportList();
    },
    getAllShop() {
      shopService
        .getAllShops()
        .then(res => {
          let tempList = res.data;
          for (let temp of tempList) {
            let shop = {
              id: temp.id,
              name: temp.name
            };
            this.shopList.push(shop);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getMenuReportList() {
      this.showListLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";
      reportService
        .getMenuReportList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        )
        .then(res => {
          if (res.data) {
            this.menuReportList = res.data.menuReportList;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    printAllMenu(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      location.reload(true);
    },
    getAllMenuReportList() {
      this.showListLoading = true;
      reportService
        .getAllMenuReportList(this.queryParams)
        .then(res => {
          if(res.data) {
            this.allMenuReportList = res.data.menuReportList;
            console.log(this.allMenuReportList.length);
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    clear() {
      this.queryParams.data = commonService.formatDate(
        new Date(),
        "YYYY-MM-DD"
      );
    }
  }
};
</script>
