<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <div class="text-xs-left">
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex sm3>
            <v-menu
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="queryParams.fromOrderDate"
                label="From Date"
                prepend-icon="event"
                readonly
              ></v-text-field>
              <v-date-picker v-model="queryParams.fromOrderDate"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex sm3>
            <v-menu
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="queryParams.toOrderDate"
                label="To Date"
                prepend-icon="event"
                readonly
              ></v-text-field>
              <v-date-picker v-model="queryParams.toOrderDate"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex sm3>
            <v-text-field name="orderNo" label="Order No" v-model="queryParams.orderNo" type="text"></v-text-field>
          </v-flex>
          <v-flex sm3>
            <v-select
              label="Currency"
              v-model="queryParams.currencyId"
              :items="currencyList"
              item-value="id"
              item-text="code"
            ></v-select>
          </v-flex>
          <v-flex sm3>
            <v-btn outline color="primary" @click="onSearch">
              Search
              <v-icon right>search</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex d-flex xs12>
        <v-card>
          <v-card-title class="blue darken-3">
            <span class="headline white--text">Order Summary</span>
          </v-card-title>
          <v-card-text class="font-weight-bold">
            <div>
              <span>Total Delivery Fee:</span>
              <v-chip>
                <span>{{ orderSummaryList.totalDeliveryAmount | numFormat}}</span>
              </v-chip>

              <span>Total Amount:</span>
              <v-chip>
                <span>{{ orderSummaryList.totalAmount | numFormat}}</span>
              </v-chip>

              <span>Total Discount Amount:</span>
              <v-chip>
                <span>{{ orderSummaryList.totalDiscountAmount | numFormat}}</span>
              </v-chip>

              <span>Total Net Amount:</span>
              <v-chip>
                <span>{{ orderSummaryList.totalNetAmount | numFormat}}</span>
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 class="mt-1">
        <v-card>
          <v-card-title>
            <!-- Order Report
            <v-spacer></v-spacer>-->
            <v-btn
              color="primary"
              dark
              @click="printAllReport('printAllReport')"
              :loading="printAllLoading"
            >Print All</v-btn>
            <v-btn
              color="success"
              dark
              @click="exportAllReport"
              :loading="exportAllLoading"
            >EXPORT All</v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="orderReportList"
            class="elevation-1"
            :loading="showListLoading"
            :total-items="totalRows"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
            expand
          >
            <template slot="items" slot-scope="props">
              <tr @click="props.expanded = !props.expanded">
                <td>{{ props.item.orderDateInMilliSeconds | format-date-only }}</td>
                <td>{{ props.item.orderNo }}</td>
                <td>{{ props.item.customerName }}</td>
                <td>{{ props.item.customerPhoneNo }}</td>
                <td>{{ props.item.deliveryAddress }}</td>
                <td>{{ props.item.billingAddress }}</td>
                <td>{{ props.item.remark }}</td>
                <td>{{ props.item.deliveryFee }}</td>
                <td>{{ props.item.discountAmount }}</td>
                <td>{{props.item.currencyDTO.code}}</td>
                <td>{{props.item.exchangeRate}}</td>
                <td>{{ props.item.totalAmount }}</td>
                <td>{{props.item.customerTotalAmount}}</td>
                <td>{{ props.item.approvedPerson }}</td>
                <td>{{ props.item.approvedDateInMilliSeconds | format-date-only }}</td>
              </tr>
            </template>
            <template slot="expand" slot-scope="props">
              <v-card class="elevation-1">
                <v-card-text>
                  <v-data-table
                    :headers="subHeaders"
                    :items="props.item.orderHeaderDetails"
                    item-key="props.item.id"
                    hide-actions
                    class="elevation-10"
                  >
                    <template slot="items" slot-scope="subprops">
                      <td class="text-xs">{{ subprops.item.stock.name }}</td>
                      <td class="text-xs">{{ subprops.item.stock.sku }}</td>
                      <td class="text-xs">{{ subprops.item.stock.categoryName }}</td>
                      <td class="text-xs">{{ subprops.item.stock.unitPrice }}</td>
                      <td class="text-xs">{{ subprops.item.stock.maxOrderQty }}</td>
                      <td class="text-xs">{{ subprops.item.remark }}</td>
                      <td>
                        <v-icon
                          v-if="subprops.item.stock.status=='CONFIRM'"
                          medium
                          color="green"
                        >check_circle</v-icon>
                        <v-icon
                          v-if="subprops.item.stock.status=='PENDING'"
                          medium
                          color="yellow"
                        >cancel</v-icon>
                        <v-icon
                          v-if="subprops.item.stock.status=='CANCEL'"
                          medium
                          color="red"
                        >cancel</v-icon>
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- reprint sale slip hidden area -->
    <div id="printAllReport" style="bsale:1px solid black;width:300px;" v-show="false">
      <div style="text-align:center">
        <p style="font-size:18px;margin:1px">Order Report</p>
      </div>
      <div style="text-align:center">
        <p
          style="font-size:15px;margin:1px"
        >From: [{{queryParams.fromOrderDate}}] To: [{{queryParams.toOrderDate}}]</p>
      </div>
      <br />
      <div style="text-align:left">
        <p style="font-size:15px;margin:0px">Total Amount: {{totalAmount | numFormat}}</p>
      </div>
      <div style="text-align:left">
        <p style="font-size:15px;margin:0px">Total NetAmount: {{totalNetAmount | numFormat}}</p>
      </div>
      <div style="text-align:left">
        <p
          style="font-size:15px;margin:0px"
        >Total Discount Amount: {{totalDiscountAmount | numFormat}}</p>
      </div>
      <div style="text-align:left">
        <p style="font-size:15px;margin:0px">Total Delivery Fee: {{totalDeliveryAmount | numFormat}}</p>
      </div>
      <br />
      <div style="padding-left:5px;padding-right:5px">
        <table
          style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;"
        >
          <thead style="border-bottom:1px solid black;border-top:1px solid black">
            <tr>
              <th style="text-align:left">Order Date</th>
              <th style="text-align:left">Order No</th>
              <th style="text-align:left">Customer Name</th>
              <th style="text-align:left">Customer PhoneNo</th>
              <th style="text-align:left">Delivery Address</th>
              <th style="text-align:left">Billing Address</th>
              <th style="text-align:left">Delivery Fee</th>
              <th style="text-align:left">Discount Amount</th>
              <th style="text-align:left">Currency</th>
              <th style="text-align:left">Exchange Rate</th>
              <th style="text-align:left">Total Amount</th>
              <th style="text-align:left">Grand Total</th>
              <th style="text-align:left">Approved By</th>
              <th style="text-align:left">Approved Date</th>
              <th style="text-align:left">Remark</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allOrderReportList" :key="index">
              <td style="text-align:left">{{item.orderDateInMilliSeconds | format-date-only }}</td>
              <td style="text-align:left">{{item.orderNo}}</td>
              <td style="text-align:left">{{item.customerName}}</td>
              <td style="text-align:left">{{item.customerPhoneNo}}</td>
              <td style="text-align:left">{{item.deliveryAddress}}</td>
              <td style="text-align:left">{{item.billingAddress}}</td>
              <td style="text-align:left">{{item.deliveryFee}}</td>
              <td style="text-align:left">{{item.discountAmount}}</td>
              <td style="text-align:left">{{item.currencyDTO.code}}</td>
              <td style="text-align:left">{{item.exchangeRate}}</td>
              <td style="text-align:left">{{item.totalAmount}}</td>
              <td style="text-align:left">{{item.customerTotalAmount}}</td>
              <td style="text-align:left">{{item.approvedPerson}}</td>
              <td style="text-align:left">{{item.approvedDateInMilliSeconds | format-date-only}}</td>
              <td style="text-align:left">{{item.remark}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <summary report> -->
    <!-- <div id="printSaleSummary" style="bsale:1px solid black;width:300px;" v-show="false">
      <div style="text-align:center">
        <p style="font-size:18px;margin:1px">Sale Summary</p>
      </div>
      <div style="text-align:center">
        <p
          style="font-size:15px;margin:1px"
        >From: [{{queryParams.fromDate}}] To: [{{queryParams.toDate}}]</p>
      </div>
      <div style="text-align:left">
        <p style="font-size:15px;margin:0px">Total Tax: {{totalCounterTaxAmount | numFormat}}</p>
      </div>
      <div style="text-align:left">
        <p style="font-size:15px;margin:0px">Total Sale: {{totalCounterAmount | numFormat}}</p>
      </div>
      <br />
      <div style="padding-left:5px;padding-right:5px">
        <table
          style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;"
        >
          <thead style="border-bottom:1px solid black;border-top:1px solid black">
            <tr>
              <th style="text-align:left">Shop Name</th>
              <th style="text-align:left">Counter Name</th>
              <th style="text-align:left">Counter Tax Amount</th>
              <th style="text-align:left">Counter Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in saleSummaryByCounterList" :key="index">
              <td style="text-align:left">{{item.shopName}}</td>
              <td style="text-align:left">{{item.counterName}}</td>
              <td style="text-align:left">{{item.counterTaxAmount | numFormat}}</td>
              <td style="text-align:left">{{item.counterAmount | numFormat}}</td>
            </tr>
          </tbody>
        </table>
        <div style="height:10px"></div>
        <table
          style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;border-top:1px solid black"
        >
          <thead style="border-bottom:1px solid black;border-top:1px solid black">
            <tr>
              <th style="text-align:left">Sale Date</th>
              <th style="text-align:left">Sale No</th>
              <th style="text-align:left">Shop Name</th>
              <th style="text-align:left">Counter Name</th>
              <th style="text-align:left">Net Amount</th>
              <th style="text-align:left">Tax Amount</th>
              <th style="text-align:left">Total Amount</th>
              <th style="text-align:left">Discount Amount</th>
              <th style="text-align:left">Pay Amount</th>
              <th style="text-align:left">Pay Change</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allSaleReportList" :key="index">
              <td style="text-align:left">{{item.createdDateInMilliSeconds | format-date-only}}</td>
              <td style="text-align:left">{{item.saleNo}}</td>
              <td style="text-align:left">{{item.shopName}}</td>
              <td style="text-align:left">{{item.counterName}}</td>
              <td style="text-align:left">{{item.netAmount | numFormat}}</td>
              <td style="text-align:left">{{item.taxAmount | numFormat}}</td>
              <td style="text-align:left">{{item.totalAmount | numFormat}}</td>
              <td style="text-align:left">{{item.disocuntAmount | numFormat}}</td>
              <td style="text-align:left">{{item.payAmount | numFormat}}</td>
              <td style="text-align:left">{{item.payChange | numFormat}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>-->
  </v-container>
</template>

<script>
import { commonService } from "../../services/common-service";
import { reportService } from "../../services/report-service";
import { currencyService } from "../../services/currency-service";
export default {
  data: () => ({
    orderSummaryList: [],
    orderReportList: [],
    allOrderReportList: [],
    currencyList: [],
    totalDeliveryAmount: "",
    totalAmount: "",
    totalNetAmount: "",
    totalDiscountAmount: "",
    headers: [
      { text: "Order Date ", align: "left", value: "order_date" },
      { text: "Order No", value: "order_no" },
      { text: "Customer Name", value: "c.name" },
      { text: "Customer PhoneNo", value: "c.name" },
      { text: "Delivery Address", value: "d_address" },
      { text: "Billing Address", value: "B_address" },
      { text: "Remark", value: "remark" },
      { text: "Delivery Fee", value: "deliverFee", sortable: false },
      { text: "Discount Amount", value: "Discount Amount" },
      { text: "Currency", value: "Currency" },
      { text: "Exchange Rate", value: "Exchange Rate" },
      { text: "Total Amount", value: "Total Amount" },
      { text: "Grand Total", value: "Grand Total" },
      { text: "Approved By", value: "approve person" },
      { text: "Approved Date", value: "aprrove date" },
    ],
    subHeaders: [
      { text: "Stock Name ", align: "left", value: "sto_name" },
      { text: "sku", value: "sku" },
      { text: "Category Name", value: "Category Name" },
      { text: "Unit Price", value: "Unit Price" },
      { text: "Max Order Qty", value: "maxOrderQty" },
      { text: "remark", value: "remark" },
      { text: "status", value: "status" },
    ],
    breadcrumbs: [
      {
        text: "Reports",
        disabled: true,
      },
      {
        text: "Order Report",
        disabled: true,
      },
    ],
    queryParams: {
      fromOrderDate: commonService.formatDate(new Date(), "YYYY-MM-DD"),
      toOrderDate: commonService.formatDate(new Date(), "YYYY-MM-DD"),
      orderNo: "",
      currencyId: "",
    },
    clonequeryParams: new Object(),
    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 50,
      sortBy: "orderNo",
      totalItems: 0,
    },
    pagination2: {
      descending: false,
      page: 1,
      rowsPerPage: 50,
      totalItems: 0,
    },
    totalRows: 0,
    showListLoading: false,
    rowsPerPageItems: [25, 50, 100],
    exportAllLoading: false,
    printAllLoading: false,
  }),
  watch: {
    pagination: {
      handler() {
        this.getOrderReportList();
        this.getOrderSummaryList();
      },
      deep: true,
    },
  },
  created() {
    this.onSearch();
    //for deep linking
    if (this.$route.query.orderNo) {
      this.queryParams.orderNo = this.$route.query.orderNo;
    }

    if (this.$route.query.currencyId) {
      this.queryParams.currencyId = this.$route.query.currencyId;
    }

    if (this.$route.query.fromOrderDate) {
      this.queryParams.fromOrderDate = this.$route.query.fromOrderDate;
    }
    if (this.$route.query.toOrderDate) {
      this.queryParams.toOrderDate = this.$route.query.toOrderDate;
    }
    if (this.$route.query.page) {
      this.pagination.page = this.$route.query.page;
    }
    if (this.$route.query.size) {
      this.pagination.size = this.$route.query.size;
    }
  },
  mounted() {
    this.getActiveCurrencyList();
  },
  methods: {
    onSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.$router.replace({ name: "order-report", query: this.queryParams });
      this.getOrderReportList();
      this.getOrderSummaryList();
      this.clonequeryParams = Object.assign({}, this.queryParams);
    },
    getOrderSummaryList() {
      reportService
        .getOrderSummaryReport(this.queryParams)
        .then((res) => {
          this.orderSummaryList = res.data;
          this.totalDeliveryAmount = res.data.totalDeliveryAmount;
          this.totalAmount = res.data.totalAmount;
          this.totalNetAmount = res.data.totalNetAmount;
          this.totalDiscountAmount = res.data.totalDiscountAmount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrderReportList() {
      this.showListLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";
      reportService
        .getOrderReportList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        )
        .then((res) => {
          if (res.data) {
            this.orderReportList = res.data.orderList;
            console.log("op", this.orderReportList);
            this.totalRows = res.data.totalElements;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    exportAllReport() {
      this.exportAllLoading = true;
      reportService
        .OrderReportExport(
          this.queryParams,
          this.pagination2.page,
          this.pagination2.rowsPerPage
        )
        .then((res) => {
          commonService.downloadE(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.exportAllLoading = false;
        });
    },
    printAllReport(divName) {
      this.printAllLoading = true;
      reportService
        .getOrderReportList(this.clonequeryParams)
        .then((res) => {
          if (res.data) {
            this.allOrderReportList = res.data.orderList;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          var printContents = document.getElementById(divName).innerHTML;
          var printWindow = window.open("", "MsgWindow");
          printWindow.document.write(printContents);
          setTimeout(() => {
            printWindow.print();
            printWindow.close();
          }, 600);
          this.printAllLoading = false;
        });
    },
    getActiveCurrencyList() {
      currencyService
        .getActiveCurrency()
        .then((res) => {
          this.currencyList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

