import axios from "../axios-auth";
import orginal_axios from "axios";

export const stockService = {
  getStockList,
  getStockDetail,
  saveStock,
  getStockByBarcodeAndStatus,
  getStockByCategory,
  getStocks, // for public
  getStockById, // for public
  getStockExcelTemplate,
  saveStockExcel,
  getStockExcelExport
};

function getStockList(searchParams, page, size, sortedBy) {
  if (!page) {
    page = 0;
  } else {
    page -= 1;
  }
  if (!size) {
    size = 50;
  }

  let params = {
    page: page,
    size: size,
    sort: sortedBy
  };

  if (searchParams.status) {
    params["status"] = searchParams.status;
  }
  if (searchParams.categoryName) {
    params["categoryName"] = searchParams.categoryName;
  }
  if (searchParams.exactCategoryName) {
    params["exactCategoryName"] = searchParams.exactCategoryName;
  }
  if (searchParams.barcode) {
    params["barcode"] = searchParams.barcode;
  }
  if (searchParams.sku) {
    params["sku"] = searchParams.sku;
  }
  if (searchParams.name) {
    params["name"] = searchParams.name;
  }

  return axios.get("/auth/stocks/", { params });
}

function getStockDetail(id) {
  return axios.get("/auth/stock/" + id);
}

function saveStock(stock) {
  if (!stock.id) {
    // new stock
    return axios.post("/auth/stock", stock);
  } else {
    // edit
    return axios.put("/auth/stock", stock);
  }
}

function getStockByBarcodeAndStatus(barcode, status) {
  return axios.get("/auth/stock?barcode=" + barcode + "&status=" + status);
}


// for public
function getStocks(searchParams, page, size, sortedBy) {
  if (!page) {
    page = 0;
  } else {
    page -= 1;
  }
  if (!size) {
    size = 50;
  }

  let params = {
    page: page,
    size: size,
    sort: sortedBy
  };

  if (searchParams.status) {
    params["status"] = searchParams.status;
  }
  if (searchParams.categoryName) {
    params["categoryName"] = searchParams.categoryName;
  }
  if (searchParams.exactCategoryName) {
    params["exactCategoryName"] = searchParams.exactCategoryName;
  }
  if (searchParams.barcode) {
    params["barcode"] = searchParams.barcode;
  }
  if (searchParams.sku) {
    params["sku"] = searchParams.sku;
  }
  if (searchParams.name) {
    params["name"] = searchParams.name;
  }

  if (searchParams.promotionId) {
    params["promotionId"] = searchParams.promotionId;
  }
  if (searchParams.isPromo) {
    params["isPromo"] = searchParams.isPromo;
  }
  return axios.get("/stocks", { params });
}

function getStockById(id) {
  return axios.get("/stock/" + id);
}

function getStockByCategory(searchParams, page, size, sortedBy) {
  if (!page) {
    page = 0;
  } else {
    page -= 1;
  }
  if (!size) {
    size = 10;
  }

  let params = {
    page: page,
    size: size,
    sort: sortedBy
  };

  //let status = "ACTIVE"
  if (searchParams.categoryName) {
    params["categoryName"] = searchParams.categoryName;
  }
  if (searchParams.status) {
    params["status"] = searchParams.status;
  }
  return axios.get("/stocks-by-category", { params });
}

function getStockExcelTemplate() {
  return orginal_axios({
    method: "GET",
    url: axios.defaults.baseURL + "/auth/stock-excel-template",
    responseType: "arraybuffer", // important
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }
  }).then(function (response) {
    return { blob: new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), headers: response.headers }
  });
}

function saveStockExcel(file) {
  return axios.post('/auth/stock-excel',
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}
function getStockExcelExport(searchParams) {
  let params = {
  };
  if (searchParams.status) {
    params["status"] = searchParams.status;
  }
  if (searchParams.categoryName) {
    params["categoryName"] = searchParams.categoryName;
  }
  if (searchParams.exactCategoryName) {
    params["exactCategoryName"] = searchParams.exactCategoryName;
  }
  if (searchParams.barcode) {
    params["barcode"] = searchParams.barcode;
  }
  if (searchParams.sku) {
    params["sku"] = searchParams.sku;
  }
  if (searchParams.name) {
    params["name"] = searchParams.name;
  }

  return orginal_axios({
    method: "GET",
    url: axios.defaults.baseURL + "/auth/stock-excel-export",
    params,
    responseType: "arraybuffer", // important
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }
  }).then(function (response) {
    return { blob: new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), headers: response.headers }
  });
}
