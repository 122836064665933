 <template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 sm4>
        <strong>Select the counters</strong>
        <v-flex v-for="(counter, index) in counterList" :key="index">
          <v-btn color="primary" @click="selectCounter(counter)">{{counter.name}}</v-btn>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { counterService } from "../../services/counter-service";

export default {
  name: "InitialSale",

  components: {},
  data: () => ({
    showListLoading: false,
    breadcrumbs: [
      {
        text: "Sale",
        disabled: true
      }
    ],
    counterList: []
  }),
  created() {
    this.getCountersByShopId();
  },
  methods: {
    getCountersByShopId() {
      let user = JSON.parse(localStorage.getItem("user"));
      counterService
        .getCountersByShopId(user.shopId)
        .then(res => {
          this.counterList = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    selectCounter(counter) {
      let sale = {
        counterId: 0,
        counterName: "",
        shopId: 0,
        shopName: ""
      };
      let user = JSON.parse(localStorage.getItem("user"));
      sale.counterId = counter.id;
      sale.counterName = counter.name;
      sale.shopId = user.shopId;
      sale.shopName = user.shopName;
      sale.phoneNo = user.phoneNo;
      sale.address = user.address;
      localStorage.setItem("sale", JSON.stringify(sale));
      this.$router.replace("/sales");
    }
  }
};
</script>