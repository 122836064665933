<template>
    <v-container fluid full-height grid-list-md>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSave">
            <v-layout row wrap>

                <v-flex xs6>
                    <div class="text-xs-left">
                        <v-breadcrumbs :items="breadcrumbs" divider=">">
                            <template slot="item" slot-scope="props">
                                <router-link :to="'/'+props.item.to" :disabled="props.item.disabled"
                                             v-bind:class="{ 'v-breadcrumbs__item v-breadcrumbs__item--disabled': props.item.disabled }">
                                    {{props.item.text}}
                                </router-link>
                            </template>
                        </v-breadcrumbs>
                    </div>
                </v-flex>
                <v-flex xs6>
                    <div class="text-xs-right">
                        <v-btn outline color="primary" @click="goBack">
                            Go Back
                            <v-icon right>arrow_back</v-icon>
                        </v-btn>
                    </div>
                </v-flex>

                <v-flex xs6 offset-sm3>
                    <v-card>
                        <v-layout row wrap class="pa-3">
                            <v-flex sm12 v-if="responseError">
                                <span class="red--text">{{responseError}}</span>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="oldPassword"
                                        label="Old Password"
                                        v-model="user.oldPassword"
                                        type="password"
                                        :rules="[
                                                        () => !!user.oldPassword || 'Old Password is required',
                                                        () => user.oldPassword && user.oldPassword.length > 6 || 'Old Password length must be greater than 6 characters'

                                                    ]">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="newPassword"
                                        label="Password"
                                        v-model="user.newPassword"
                                        type="password"
                                        :rules="[
                                                        () => !!user.newPassword || 'New Password is required',
                                                        () => user.newPassword && user.newPassword.length > 6 || 'New Password length must be greater than 6 characters'

                                                    ]">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        v-model="user.confirmPassword"
                                        type="password"
                                        :rules="[
                                                        () => !!user.confirmPassword || 'Confirm Password is required',
                                                        ()=> user.newPassword == user.confirmPassword || 'New password and confirm password does not match'
                                                    ]">
                                </v-text-field>
                            </v-flex>

                            <v-flex sm12>
                                <div class="text-xs-center">
                                    <v-btn color="error" @click="clear">
                                        Reset
                                        <v-icon right>clear</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" :loading="saveLoading" :disabled="!valid" type="submit">
                                        Save
                                        <v-icon right>save</v-icon>
                                    </v-btn>

                                </div>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>

            </v-layout>

        </v-form>
    </v-container>
</template>

<script>
    import {userService} from "../../services/user-service";
    import {User} from "../../models/model";
    export default {
        name: "ChangePassword",
        data: () => ({
            valid: true,
            breadcrumbs: [
                {
                    text: 'Change Password',
                    disabled: true
                }
            ],
            user: new User(),
            saveLoading: false,
            responseError: ''
        }),
        created() {
        },
        methods: {
            changePassword() {
                if (this.$refs.form.validate()) {
                    userService.changePassword(this.user)
                        .then(res => {
                            console.log(res);

                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
            },
            onSave() {
                if (this.$refs.form.validate()) {
                    this.saveLoading = true;

                    userService.changePassword(this.user)
                        .then(res => {
                            console.log(res);
                            this.$router.push('/dashboard');
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.data) {
                                this.responseError = err.response.data.message;
                            }
                            this.saveLoading = false;
                        });
                }

            },
            goBack(){
                this.$router.push('/dashboard');
            },
            clear() {
                this.$refs.form.resetValidation();
                this.user = new User();
                this.responseError = '';
            }
        }
    }
</script>

<style scoped>

</style>