/* Validation Rules For Login Form */
export function LoginRules() {
  return {
    usernameRules: usernameRules,
    passwordRules: passwordRules
  };
}

/* Validation Rules For Stock Form */
export function StockFormRules() {
  return {
    barcodeRules: barcodeRules,
    skuRules: skuRules,
    stockNameRules: stockNameRules,
    categoryNameRules: categoryNameRules,
    unitPriceRules: unitPriceRules,
    promoPriceRules,
    maxOrderQtyRules: maxOrderQtyRules,
    requiredRules
  };
}

/* Validation Rules For Login Form */
let usernameRules = [v => !!v || "Username is required"];

let passwordRules = [
  v => !!v || "Password is required",
  v =>
    (v && v.length > 6) || "Password length must be greater than 6 characters"
];

/* Validation Rules For Stock */

let barcodeRules = [
  v => !!v || "Barcode is required",
  v => (v && v.length <= 20) || "Barcode must be less than 20 characters"
];

let skuRules = [
  v => !!v || "SKU is required",
  v => (v && v.length <= 100) || "SKU must be less than 100 characters"
];

let stockNameRules = [
  v => !!v || "Name is required",
  v => (v && v.length <= 100) || "Name must be less than 100 characters"
];

let categoryNameRules = [
  v => !!v || "Category is required",
  v => (v && v.length <= 100) || "Category must be less than 100 characters"
];

let unitPriceRules = [v => !!v || "Unit_Price is required"];

let promoPriceRules = [v => !!v || "Promotion Price is required"];

let maxOrderQtyRules = [v => !!v || "Max Order Qty is required"];

let requiredRules = [v => !!v || "Promotion is required because isPromo flag is true"];
