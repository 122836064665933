<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <div class="text-xs-left">
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap class="pa-3">
          <v-flex sm4>
            <v-layout>
              <i class="material-icons">filter_center_focus</i>
              <v-text-field
                name="scanBarcode"
                label="Scan barcode....."
                v-model="scanBarcode"
                type="text"
                v-on:keyup.enter="trigger(scanBarcode)"
                autofocus
              ></v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs2 class="mt-1">
        <template>
          <strong>Choose Category</strong>
          <v-flex v-for="(category, index) in categoryList" :key="index">
            <v-btn
              color="primary"
              @click="onSearch(category.name)"
              :style="{width: '175px'}"
            >{{category.name}}</v-btn>
          </v-flex>
        </template>
      </v-flex>
      <v-flex xs6 class="mt-1">
        <v-card>
          <template>
            <v-container fluid grid-list-md>
              <strong>{{queryParams.exactCategoryName}} ></strong>
              <v-data-iterator
                :items="stockList"
                :total-items="totalRows"
                :rows-per-page-items="rowsPerPageItems"
                :pagination.sync="pagination"
                :loading="showListLoading"
                content-tag="v-layout"
                row
                wrap
              >
                <v-flex slot="item" slot-scope="props" xs12 sm6 md4 lg3>
                  <v-hover>
                    <v-card
                      slot-scope="{ hover }"
                      :class="`elevation-${hover ? 12 : 2}`"
                      class="mx-auto"
                    >
                      <v-img :aspect-ratio="16/9" :src="props.item.imageUrl"></v-img>
                      <v-card-title>
                        <div>
                          <span class="grey--text">{{props.item.sku}}</span>
                          <br>
                          <span style="font-size: 12px;">{{props.item.name}}</span>
                        </div>
                      </v-card-title>
                      <v-card-actions>
                        <v-btn
                          :ref="`bc-${props.item.barcode}`"
                          flat
                          color="orange"
                          @click="addToCart(props.item)"
                        >Add To Card</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-flex>
              </v-data-iterator>
            </v-container>
          </template>
        </v-card>
      </v-flex>
      <v-flex xs4 class="mt-1">
        <v-layout>
          <v-flex>
            <v-btn @click="showParkSales = !showParkSales">
              <i class="material-icons">reply</i>Retrieve Sale
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn @click="showNote = !showNote" :disabled="sale.saleItems.length === 0">
              <i class="material-icons">settings_backup_restore</i>Park Sale
            </v-btn>
          </v-flex>
        </v-layout>
        <strong>Payment ></strong>
        <v-layout>
          <v-flex xs12 sm6>
            <strong>Shop Name:</strong>
          </v-flex>
          <v-flex xs12 sm6>{{sale.shopName}}</v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 sm6>
            <strong>Counter Name:</strong>
          </v-flex>
          <v-flex xs12 sm6>{{sale.counterName}}</v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row>
          <v-flex xs12 sm3>Name</v-flex>
          <v-flex xs12 sm2 class="text-xs-right">Qty</v-flex>
          <v-flex xs12 sm3 class="text-xs-right">Price</v-flex>
          <v-flex xs12 sm3 class="text-xs-right">Amount</v-flex>
          <v-flex xs12 sm1></v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row v-for="(item, index) in sale.saleItems" :key="index" class="white">
          <v-flex xs12 sm4>{{item.stockName}}</v-flex>
          <v-flex xs12 sm2 class="text-xs-right">
            <v-text-field
              v-model="item.qty"
              min="0"
              type="number"
              oninput="this.value = Math.abs(this.value)"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm3 class="text-xs-right">{{item.unitPrice | numFormat}}</v-flex>
          <v-flex xs12 sm3 class="text-xs-right">{{item.qty * item.unitPrice | numFormat}}</v-flex>
          <v-flex xs12 sm1>
            <v-btn icon @click="removeItem(index)">
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row>
          <v-flex xs8>Net Amount:</v-flex>
          <v-flex xs4 class="text-xs-right">{{sale.netAmount | numFormat}}</v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs8>Tax({{sale.taxType}})({{sale.taxPercentage}}%):</v-flex>
          <v-flex xs4 class="text-xs-right">{{sale.taxAmount | numFormat}}</v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row>
          <v-flex xs8>Total Amount:</v-flex>
          <v-flex xs4 class="text-xs-right">{{sale.totalAmount | numFormat}}</v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row>
          <v-flex xs8>Received:</v-flex>
          <v-flex xs4 class="text-xs-right">
            <v-text-field
              v-model="payAmount"
              min="0"
              type="number"
              oninput="this.value = Math.abs(this.value)"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row>
          <v-flex xs8>Pay Change :</v-flex>
          <v-flex xs4 class="text-xs-right">{{payChangeAmount}}</v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row>
          <v-btn
            color="primary"
            @click="showTableInfoDialog('DINE_IN')"
            :disabled="sale.saleItems.length === 0"
          >DINE_IN</v-btn>
          <v-btn
            color="primary"
            @click="showTableInfoDialog('TAKE_AWAY')"
            :disabled="sale.saleItems.length === 0"
          >TAKE_AWAY</v-btn>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- Invoice print hidden area -->
    <invoice id="invoicePrint" :sale-data="sale"></invoice>

    <!-- Multi order note print hidden area -->
    <multi-order-note
      v-for="(orderNote, index) in sale.orderNotes"
      :key="index"
      :id="`orderNotePrint_${orderNote.kitchenName}`"
      :data="orderNote"
    ></multi-order-note>

    <!-- Order note print hidden area -->
    <order-note id="orderNotePrint" :data="sale"></order-note>

    <!--Park sales dialog-->
    <v-dialog v-model="showParkSales" persistent max-width="500">
      <v-card>
        <v-card-title>
          <h3>Park Sales</h3>
        </v-card-title>
        <v-card-text>
          <v-layout row v-for="(item, index) in parkItems" :key="index">
            <v-flex xs12 sm3 class="grey--text">Note: {{item.note}}</v-flex>
            <v-flex xs12 sm3>{{item.saleItems[0].sku}}</v-flex>
            <v-flex xs12 sm3>{{item.saleItems[0].stockName}}</v-flex>
            <v-flex xs12 sm3>
              <v-btn color="primary" @click="retrieveSale(index)">
                <v-icon>reply</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout row v-show="parkItems.length === 0">
            <v-flex>There is no park sale.</v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="showParkSales = !showParkSales">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Show note dialog-->
    <v-dialog v-model="showNote" persistent max-width="290">
      <v-form ref="noteDialog" @submit.prevent="parkSale">
        <v-card>
          <v-card-text>
            <v-text-field
              label="Note"
              required
              v-model="sale.note"
              :rules="[v => !!v || 'Note is required']"
            ></v-text-field>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" flat @click.native="closeModal">
                Cancel
                <v-icon right>clear</v-icon>
              </v-btn>
              <v-btn color="blue darken-1" flat type="submit">
                Save
                <v-icon right>save</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <!--Table info dialog-->
    <v-dialog v-model="showTableInfos" persistent max-width="600">
      <v-card>
        <v-card-title>
          <h3>Choose table</h3>
        </v-card-title>
        <v-card-text>
          <v-layout row>
            <v-flex>
              <v-btn
                v-for="(item, index) in tableInfos"
                :key="index"
                color="primary"
                @click="chooseTable(item.tableNo)"
              >{{item.tableNo}}</v-btn>
            </v-flex>
          </v-layout>
          <v-layout row v-show="tableInfos.length === 0">
            <v-flex>There is no table.</v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" flat @click="showTableInfos = !showTableInfos">
            Cancel
            <v-icon right>clear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import invoice from "./invoice";
import multiOrderNote from "./MultiOrderNote";
import orderNote from "./OrderNote";
import { stockService } from "../../services/stock-service";
import { categoryService } from "../../services/category-service";
import { taxManagementService } from "../../services/tax-management-service";
import { saleService } from "../../services/sale-service";
import { tableInfoService } from "../../services/table-info-service";
import { Sale } from "../../models/model";
import { TaxManagement } from "../../models/model";

export default {
  name: "StockList",
  components: { invoice, multiOrderNote, orderNote },
  data: () => ({
    showListLoading: false,
    ignorePagingWatch: true,
    breadcrumbs: [
      {
        text: "Sale",
        disabled: true
      }
    ],
    rowsPerPageItems: [20, 40, 60],
    pagination: {
      page: 1,
      rowsPerPage: 20,
      sortBy: "createdDate",
      totalItems: 0
    },
    stockList: [],
    categoryList: [],
    counterList: [],
    totalRows: 0,
    parkItems: [],
    showParkSales: false,
    showNote: false,
    showTableInfos: false,
    modalValid: true,
    scanBarcode: "",
    queryParams: {
      status: "ACTIVE",
      exactCategoryName: "",
      barcode: ""
    },
    taxManagement: new TaxManagement(),
    tableInfos: [],
    sale: new Sale(),
    payAmount: 0,
    saleType: "",
    tableNo: "",
    allowMultiKitchen: false
  }),
  computed: {
    payChangeAmount() {
      return this.payAmount - this.sale.totalAmount;
    }
  },
  watch: {
    pagination: {
      handler() {
        if (!this.ignorePagingWatch) {
          this.getStockList();
        }
      },
      deep: true
    },
    sale: {
      handler() {
        this.calculateAmount();
      },
      deep: true
    }
  },
  created() {
    this.init();
    this.getCategoriesByStatus()
      .then(this.getStockList)
      .then(() => {
        this.ignorePagingWatch = false;
      });
  },
  methods: {
    trigger(barcode) {
      this.getStockByBarcode(barcode);
    },
    onSearch(exactCategoryName) {
      if (exactCategoryName) {
        this.queryParams.exactCategoryName = exactCategoryName;
      }
      this.pagination.page = 1;
      this.pagination.rowsPerPage = 20;
      this.$router.replace({ name: "sales", query: this.queryParams });
      this.getStockList();
    },
    getStockList() {
      this.showListLoading = true;
      let sale = this.pagination.descending ? "desc" : "asc";
      stockService
        .getStockList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy ? this.pagination.sortBy + "," + sale : ""
        )
        .then(res => {
          if (res.data) {
            this.stockList = res.data.stocks;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    getCategoriesByStatus() {
      return categoryService
        .getCategoriesByStatus("ACTIVE")
        .then(res => {
          this.categoryList = res.data;
          if (!this.queryParams.exactCategoryName) {
            this.queryParams.exactCategoryName = this.categoryList[0].name;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getTax() {
      return taxManagementService
        .getTaxByStatus("ACTIVE")
        .then(res => {
          this.taxManagement = res.data;
          this.sale.taxId = this.taxManagement.taxId;
          this.sale.taxName = this.taxManagement.taxName;
          this.sale.taxType = this.taxManagement.taxType;
          this.sale.taxPercentage = this.taxManagement.taxPercentage;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getTableInfosByStatusAndShop() {
      let user = JSON.parse(localStorage.getItem("user"));
      tableInfoService
        .getTableInfosByStatusAndShop("ACTIVE", user.shopId)
        .then(res => {
          if (res.data) {
            this.tableInfos = res.data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    addToCart(stock) {
      if (this.isItemExist(stock)) {
        this.sale.saleItems.forEach(function(item) {
          if (item.stockId === stock.id) {
            item.qty = parseInt(item.qty) + 1;
          }
        });
      } else {
        let item = {
          stockId: 0,
          sku: "",
          stockName: "",
          qty: 0,
          unitPrice: 0,
          totalPrice: 0,
          kitchenName: ""
        };

        // Add unique kitchen id
        if (stock.kitchenName) {
            this.sale.kitchenGroupNames.push(stock.kitchenName);
        } else {
          this.sale.kitchenGroupNames.push('NK');
        }

        item.stockId = stock.id;
        item.sku = stock.sku;
        item.stockName = stock.name;
        item.qty = 1;
        item.unitPrice = stock.unitPrice;
        item.kitchenName = stock.kitchenName;
        this.sale.saleItems.push(item);
      }
    },
    calculateAmount() {
      let total = 0;
      this.sale.saleItems.forEach(item => {
        item.totalPrice = item.qty * item.unitPrice;
        total += item.totalPrice;
      });
      let commercialTax = total * (this.sale.taxPercentage / 100);
      let taxType = this.sale.taxType;
      if (taxType === "ADD_ON") {
        this.sale.netAmount = total;
        this.sale.totalAmount = total + commercialTax;
      } else {
        this.sale.netAmount = total - commercialTax;
        this.sale.totalAmount = total;
      }
      this.sale.taxAmount = commercialTax;
      this.payAmount = this.sale.totalAmount;
    },
    save() {
      this.sale.payChange = this.payChangeAmount;
      this.sale.payAmount = this.payAmount;
      this.sale.saleType = this.saleType;
      this.sale.tableNo = this.tableNo;

      let kitchenGroupNamesRaw = this.sale.kitchenGroupNames;
      let saleItems = this.sale.saleItems;

      for ( var i = kitchenGroupNamesRaw.length; i--;) {
        if ( kitchenGroupNamesRaw[i] === 'NK') {
          kitchenGroupNamesRaw.splice(i, 1);
        }
      }
      
      let kitchenGroupNames = kitchenGroupNamesRaw.filter((v, i, a) => a.indexOf(v) === i);

      saleService
        .saveSale(this.sale)
        .then(res => {
          this.sale.saleNo = res.data.saleNo;
          // Check multi kitchen is allowed
          if (this.allowMultiKitchen) {
            for (let i = 0; i < kitchenGroupNames.length; i++) {
              // Prepare data for order note
              let orderNote = {
                saleType: "",
                saleNo: "",
                tableNo: "",
                username: "",
                kitchenName: "",
                items: []
              };

              let kitchenGroupName = kitchenGroupNames[i];

              orderNote.saleType = this.sale.saleType;
              orderNote.saleNo = this.sale.saleNo;
              orderNote.tableNo = this.sale.tableNo;
              orderNote.username = this.sale.username;
              orderNote.kitchenName = kitchenGroupName;

              for (let j = 0; j < saleItems.length; j++) {
                let saleItem = saleItems[j];
                if (kitchenGroupName === saleItem.kitchenName) {
                  let item = {
                    stockId: 0,
                    sku: "",
                    stockName: "",
                    qty: 0,
                    unitPrice: 0,
                    totalPrice: 0
                  };

                  // Prepare data for orderNote's item
                  item.stockId = saleItem["stockId"];
                  item.sku = saleItem["sku"];
                  item.stockName = saleItem["stockName"];
                  item.qty = saleItem["qty"];
                  item.unitPrice = saleItem["unitPrice"];
                  item.totalPrice = saleItem["totalPrice"];
                  // Add item to sale items with kitchen
                  orderNote.items.push(item);
                }
              }

              // Push order note
              this.sale.orderNotes.push(orderNote);
            }
          }
        })
        .then(() => {
          // Print order note
          if (this.allowMultiKitchen) {
            let orderNotes = this.sale.orderNotes;
            for (let i = 0; i < orderNotes.length; i++) {
              this.printOrderNote(
                "orderNotePrint_" + orderNotes[i].kitchenName
              );
            }
          } else {
            this.printOrderNote("orderNotePrint");
            this.printOrderNote("orderNotePrint");
          }

          // Print invoice
          this.printInvoice("invoicePrint");

          location.reload(true);
        })
        .catch(err => {
          console.log(err);
        });
    },
    printInvoice(divName) {
      var d = new Date();
      var t = d.toLocaleTimeString();
      var mydate = new Date().toString("MMMM yyyy");
      document.getElementById("date").innerHTML = mydate.substr(0, 16) + t;
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
    printOrderNote(divName) {
      var d = new Date();
      var t = d.toLocaleTimeString();
      var mydate = new Date().toString("MMMM yyyy");
      document.getElementById("orderNoteDate").innerHTML =
        mydate.substr(0, 16) + t;
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
    init() {
      if (this.sale) {
        this.sale = new Sale();
      }
      this.getTax();
      let sale = JSON.parse(localStorage.getItem("sale"));
      let user = JSON.parse(localStorage.getItem("user"));
      this.allowMultiKitchen = user.allowMultiKitchen;
      this.sale.counterId = sale.counterId;
      this.sale.counterName = sale.counterName;
      this.sale.shopId = sale.shopId;
      this.sale.shopName = sale.shopName;
      this.sale.phoneNo = sale.phoneNo;
      this.sale.address = sale.address;
      this.sale.username = user.username;
      this.getTableInfosByStatusAndShop();

      // check park items
      var parkItemsLocalStorage = JSON.parse(localStorage.getItem("parkItems"));
      if (parkItemsLocalStorage) {
        this.parkItems = parkItemsLocalStorage;
      }

      //for deep linking
      if (this.$route.query.status) {
        this.queryParams.status = this.$route.query.status;
      }
      if (this.$route.query.exactCategoryName) {
        this.queryParams.exactCategoryName = this.$route.query.exactCategoryName;
      }
      if (this.$route.query.barcode) {
        this.queryParams.barcode = this.$route.query.barcode;
      }
      if (this.$route.query.page) {
        this.pagination.page = this.$route.query.page;
      }
      if (this.$route.query.size) {
        this.pagination.size = this.$route.query.size;
      }
    },
    removeItem(index) {
      this.$delete(this.sale.saleItems, index);
      this.sale.kitchenGroupNames.splice(index, 1);
    },
    parkSale() {
      if (!this.$refs.noteDialog.validate()) {
        return;
      }
      this.showNote = false;

      var parkItemsLocalStorage = JSON.parse(localStorage.getItem("parkItems"));
      if (!parkItemsLocalStorage) {
        parkItemsLocalStorage = [];
      }
      parkItemsLocalStorage.push(this.sale);
      this.parkItems = parkItemsLocalStorage;
      localStorage.setItem("parkItems", JSON.stringify(parkItemsLocalStorage));
      this.init();
    },
    retrieveSale(index) {
      var parkItemsLocalStorage = JSON.parse(localStorage.getItem("parkItems"));
      this.sale = parkItemsLocalStorage[index];
      this.$delete(parkItemsLocalStorage, index);
      this.parkItems = parkItemsLocalStorage;
      localStorage.setItem("parkItems", JSON.stringify(parkItemsLocalStorage));
      this.showParkSales = false;
    },
    closeModal() {
      this.$refs.noteDialog.resetValidation();
      this.showNote = false;
    },
    showTableInfoDialog(saleType) {
      this.showTableInfos = true;
      this.saleType = saleType;
    },
    chooseTable(tableNo) {
      this.tableNo = tableNo;
      this.save();
      this.showTableInfos = false;
    },
    isItemExist(stock) {
      for (let item of this.sale.saleItems) {
        if (item.stockId === stock.id) {
          return true;
        }
      }
    },
    getStockByBarcode(barcode) {
      stockService
        .getStockByBarcodeAndStatus(barcode, "ACTIVE", false)
        .then(res => {
          let stock = res.data;
          if (stock) {
            this.addToCart(stock);
          }
          this.scanBarcode = "";
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
</style>