<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs6>
        <div class="text-xs-left">
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex xs6>
        <div class="text-xs-right">
          <v-btn color="primary" @click="onRegister">
            New Category
            <v-icon right>add_circle</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-card>
          <v-layout row wrap class="pa-3">
            <v-flex sm3>
              <v-text-field
                name="name"
                label="Category Name"
                type="text"
                v-model="queryParams.name"
              ></v-text-field>
            </v-flex>
            <v-flex sm3>
              <v-select
                :items="statusList"
                label="Status"
                v-model="queryParams.status"
                item-text="label"
                item-value="value"
              ></v-select>
            </v-flex>
            <v-flex sm3>
              <v-btn outline color="primary" @click="onSearch">
                Search
                <v-icon right>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 class="mt-1">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="categoryList"
            class="elevation-1"
            :loading="showListLoading"
            :total-items="totalRows"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <td>
                  <div class="mt-3">
                    <v-avatar size="70px" class="mb-2">
                      <img
                        src="../../assets/images/no-image.png"
                        v-if="!props.item.imageUrl"
                        aspect-ratio="1"
                        contain
                        class="grey lighten-2"
                      >
                      <v-img
                        v-if="props.item.imageUrl"
                        :src="props.item.imageUrl"
                        class="grey lighten-2"
                      >
                        <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-layout>
                      </v-img>
                    </v-avatar>
                  </div>
                </td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.createdDateInMilliSeconds | format-ms-date }}</td>
              <td>{{ props.item.createdBy }}</td>
              <td>
                <v-icon v-if="props.item.status=='ACTIVE'" medium color="green">check_circle</v-icon>
                <v-icon v-else medium color="red">cancel</v-icon>
              </td>
              <td>
                <v-btn flat icon color="blue lighten-2" @click="onEdit(props.item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { categoryService } from "../../services/category-service";

export default {
  name: "CategoryList",

  components: {},
  data: () => ({
    showListLoading: false,
    breadcrumbs: [
      {
        text: "User Management",
        disabled: true
      },
      {
        text: "Category",
        disabled: true
      }
    ],
    headers: [
      { text: "Image", align: "left", value: "image", sortable: false },
      { text: "Category Name", align: "left", value: "name" },
      { text: "Created Date", value: "createdDate" },
      { text: "Created By", value: "createdBy" },
      { text: "Status", value: "status", sortable: false },
      { text: "Action", value: "name", sortable: false }
    ],
    rowsPerPageItems: [25, 50, 100],
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 50,
      sortBy: "name",
      totalItems: 0
    },
    categoryList: [],
    totalRows: 0,
    statusList: [
      { label: "Active", value: "ACTIVE" },
      { label: "Inactive", value: "INACTIVE" }
    ],
    queryParams: {
      name: "",
      status: ""
    }
  }),
  watch: {
    pagination: {
      handler() {
        this.getCategoryList();
      },
      deep: true
    }
  },
  created() {
    //for deep linking
    if (this.$route.query.name) {
      this.queryParams.name = this.$route.query.name;
    }
    if (this.$route.query.status) {
      this.queryParams.status = this.$route.query.status;
    }
    if (this.$route.query.page) {
      this.pagination.page = this.$route.query.page;
    }
    if (this.$route.query.size) {
      this.pagination.size = this.$route.query.size;
    }
  },
  methods: {
    onSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.$router.replace({ name: "categories", query: this.queryParams });
      this.getCategoryList();
    },
    getCategoryList() {
      this.showListLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";
      categoryService
        .getCategoryList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        )
        .then(res => {
          if (res.data) {
            this.categoryList = res.data.categoryList;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    onRegister() {
      this.$router.push("/category/new");
    },
    onEdit(category) {
      // go to details page
      this.$router.push("/category/" + category.id);
    }
  }
};
</script>