import axios from 'axios';
import {AuthGuard} from "./helpers/auth-guard";

const instance = axios.create({
    baseURL: 'http://localhost:8080/api'
    // baseURL: 'http://192.168.1.251:8080/wms/api'
});

instance.defaults.headers.common['Authorization'] = AuthGuard.isAuthenticated() ? 'Bearer ' + localStorage.getItem("accessToken") : '';
instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(req => {
    req.headers.Authorization = AuthGuard.isAuthenticated() ? 'Bearer ' + localStorage.getItem("accessToken") : '';
    return req;
});
instance.interceptors.response.use(res => {
    if (res.config.url.includes('/login')) {
        // if login api store authentication
        if (res.data.token) {
            AuthGuard.storeAuthentication(res.data.token);
        }
    }

    return res;
},
    error => {
        if (error.response.status == '400') {
            localStorage.clear();
            window.location.href = '/'
        }
        else if(error.response.data.fieldErrorMessages){
            let obj = error.response.data.fieldErrorMessages;
            let message = obj[Object.keys(obj)[0]];
            error.response.data.message = message;
            throw error;
        }
        else{
            throw error;
        }
    });

export default instance;