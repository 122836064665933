import axios from "../axios-auth";

export const categoryService = {
  getCategoriesByStatus,
  getCategoryList,
  saveCategory,
  getCategoryDetail,
  getAllCategories,
  getCategories // for public
};

function getCategoriesByStatus(status) {
  return axios.get("auth/categories-by-status/" + status);
}

function getCategoryList(searchParams, page, size ,sortedBy) {
  let url = '';
  if(!page){
      page = 0;
  }
  else{
      page -= 1;
  }
  if(!size){
      size = 50;
  }
  if (sortedBy == 'null,asc') {
    url = '/auth/categories?page='+page+'&size='+size;
  } else {
    url = '/auth/categories?page='+page+'&size='+size+'&sort='+sortedBy;
  }
  if (searchParams.name) {
      url += '&name=' + searchParams.name;
  }
  if (searchParams.status) {
      url += '&status=' + searchParams.status;
  }
  return axios.get(url);
}

function saveCategory(category) {
  if (!category.id) {
    // new category
    return axios.post("/auth/category", category);
  } else {
    // edit
    return axios.put("/auth/category", category);
  }
}

function getCategoryDetail(id) {
  return axios.get('/auth/category/'+id);
}

function getAllCategories() {
  return axios.get('/auth/all-categories');
}

// for public
function getCategories() {
  return axios.get('/all-categories');
}
