import axios from '../axios-auth'
import orginal_axios from "axios";

export const reportService = {
    getOrderReportList,
    getOrderSummaryReport,
    OrderReportExport,
    getProductOrderReportList,
    excelExportProductOrderReportList
}

function getOrderReportList(searchParams, page, size, sortedBy) {
    let url = '/auth/order-report?';
    let params = {};
    if (!page) {
        page = 0;
    }
    else {
        page -= 1;
    }
    if (!size) {
        size = 50;
    }

    if (sortedBy == 'null,asc') {
        params["page"] = page;
        params["size"] = size;
    } else {
        params["page"] = page;
        params["size"] = size;
        params["sort"] = sortedBy;
    }

    if (searchParams.orderNo) {
        url += '&orderNo=' + searchParams.orderNo;
    }
    if (searchParams.currencyId) {
        url += '&currencyId=' + searchParams.currencyId;
    }
    if (searchParams.fromOrderDate) {
        url += '&fromOrderDate=' + searchParams.fromOrderDate;
    }
    if (searchParams.toOrderDate) {
        url += '&toOrderDate=' + searchParams.toOrderDate;
    }
    return axios.get(url, { params });
}

function getOrderSummaryReport(searchParams) {

    let url = '/auth/order-summary-report?';

    if (searchParams.orderNo) {
        url += '&orderNo=' + searchParams.orderNo;
    }
    if (searchParams.fromOrderDate) {
        url += '&fromOrderDate=' + searchParams.fromOrderDate;
    }
    if (searchParams.toOrderDate) {
        url += '&toOrderDate=' + searchParams.toOrderDate;
    }

    return axios.get(url);
}

function OrderReportExport(searchParams, page, size) {
    let params = {
    };

    if (!page) {
        page = 0;
    }
    else {
        page -= 1;
    }
    if (!size) {
        size = 50;
    }

    params["page"] = page;
    params["size"] = size;


    if (searchParams.fromOrderDate) {
        params["fromOrderDate"] = searchParams.fromOrderDate;
    }
    if (searchParams.toOrderDate) {
        params["toOrderDate"] = searchParams.toOrderDate;
    }
    if (searchParams.orderNo) {
        params["orderNo"] = searchParams.orderNo;
    }

    return orginal_axios({
        method: "GET",
        url: axios.defaults.baseURL + "/auth/order-report-excel",
        params,
        responseType: "arraybuffer", // important
        headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
    }).then(function (response) {
        return { blob: new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), headers: response.headers }
    });
}

function getProductOrderReportList(searchParams) {
    let url = '/auth/product-order-report';
    let params = {};
    // if(!page){
    //     page = 0;
    // }
    // else{
    //     page -= 1;
    // }
    // if(!size){
    //     size = 50;
    // }
    // if (sortedBy == 'null,asc') {
    //     params["page"] = page;
    //     params["size"] = size;
    // } else {
    //     params["page"] = page;
    //     params["size"] = size;
    //     params["sort"] = sortedBy;
    // }
    if (searchParams.productName) {
        params["productName"] = searchParams.productName;
    }
    if (searchParams.categoryId) {
        params["categoryId"] = searchParams.categoryId;
    }
    if (searchParams.sku) {
        params["sku"] = searchParams.sku;
    }
    if (searchParams.fromOrderDate) {
        params["fromOrderDate"] = searchParams.fromOrderDate;
    }
    if (searchParams.toOrderDate) {
        params["toOrderDate"] = searchParams.toOrderDate;
    }
    return axios.get(url, { params });
}

function excelExportProductOrderReportList(searchParams) {
    let url = '/auth/product-order-report-excel';
    let params = {};

    if (searchParams.productName) {
        params["productName"] = searchParams.productName;
    }
    if (searchParams.categoryId) {
        params["categoryId"] = searchParams.categoryId;
    }
    if (searchParams.sku) {
        params["sku"] = searchParams.sku;
    }
    if (searchParams.fromOrderDate) {
        params["fromOrderDate"] = searchParams.fromOrderDate;
    }
    if (searchParams.toOrderDate) {
        params["toOrderDate"] = searchParams.toOrderDate;
    }
    // return axios.get(url, {params});
    return axios({
        method: "GET",
        url: url,
        params,
        responseType: "arraybuffer", // important
        headers: {
            Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
    }).then(function (response) {
        return { blob: new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), headers: response.headers }
    });
}
