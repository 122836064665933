import axios from "../axios-auth";
import orginal_axios from "axios";

export const orderDeliveryService = {
  order,
  getOrderList,
  getOrderDetail,
  saveOrder,
  trackOrder,
  getOrderExcelExport
};

function order(order) {
  return axios.post("/order", order);
}

function getOrderList(searchParams, page, size, sortedBy) {
  let url = '';
  if (!page) {
    page = 0;
  }
  else {
    page -= 1;
  }
  if (!size) {
    size = 50;
  }
  if (sortedBy == 'null,asc') {
    url = '/auth/all-order?page=' + page + '&size=' + size;
  } else {
    url = '/auth/all-order?page=' + page + '&size=' + size + '&sort=' + sortedBy;
  }
  if (searchParams.fromDate) {
    url += '&fromOrderDate=' + searchParams.fromDate;
  }
  if (searchParams.toDate) {
    url += '&toOrderDate=' + searchParams.toDate;
  }
  if (searchParams.status) {
    url += '&status=' + searchParams.status;
  }
  if (searchParams.customerName) {
    url += '&customerName=' + searchParams.customerName;
  }
  if (searchParams.customerPhoneNo) {
    url += '&customerPhoneNo=' + searchParams.customerPhoneNo;
  }
  if (searchParams.orderNo) {
    url += '&orderNo=' + searchParams.orderNo;
  }
  if (searchParams.currencyId) {
    url += '&currencyId=' + searchParams.currencyId;
  }
  return axios.get(url);
}

function getOrderDetail(id) {
  return axios.get("/auth/order/" + id);
}

function saveOrder(order) {
  return axios.put("/auth/order", order);
}

function trackOrder(searchParams) {
  let url = '/order-tracking';
  url += '?orderNo=' + searchParams.orderNo;
  url += '&phoneNo=' + searchParams.phoneNo;
  return axios.get(url)
}

function getOrderExcelExport(searchParams) {
  let params = {};

  if (searchParams.fromDate) {
    params["fromOrderDate"] = searchParams.fromDate;
  }
  if (searchParams.toDate) {
    params["toOrderDate"] = searchParams.toDate;
  }
  if (searchParams.status) {
    params["status"] = searchParams.status;
  }
  if (searchParams.customerName) {
    params["customerName"] = searchParams.customerName;
  }
  if (searchParams.customerPhoneNo) {
    params["customerPhoneNo"] = searchParams.customerPhoneNo;
  }
  if (searchParams.orderNo) {
    params["orderNo"] = searchParams.orderNo;
  }
  return orginal_axios({
    method: "GET",
    url: axios.defaults.baseURL + "/auth/order-excel-export",
    params,
    responseType: "arraybuffer", // important
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }
  }).then(function (response) {
    return { blob: new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), headers: response.headers }
  });
}