<template>
  <div id="id" style="bsale:1px solid black;width:300px;" v-show="false">
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">{{sale.shopName}}</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">{{sale.address}}</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">Tel: {{sale.phoneNo}}</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">{{sale.saleType}}</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">Invoice #: {{sale.saleNo}}</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px" id="date"></p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">Table #: {{sale.tableNo}}</p>
    </div>
    <div style="float:left;padding-left:11px">
      <p style="font-size:11px;margin:0px" id="counter">Counter: {{sale.counterName}}</p>
    </div>
    <div style="float:right;padding-right:11px">
      <p style="font-size:11px;margin:0px" id="casher">Casher: {{sale.username}}</p>
    </div>
    <div style="clear:both"></div>
    <div style="height:10px"></div>
    <div style="padding-left:5px;padding-right:5px">
      <table
        style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;"
      >
        <thead style="border-bottom:1px solid black;border-top:1px solid black">
          <tr>
            <th></th>
            <th style="text-align:center">Qty</th>
            <th style="text-align:center">Unit</th>
            <th style="text-align:center">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in sale.saleItems" :key="index">
            <td style="text-align:left">{{item.stockName}}</td>
            <td style="text-align:center">{{item.qty}}</td>
            <td style="text-align:right">{{item.unitPrice | numFormat}}</td>
            <td style="text-align:right">{{item.totalPrice | numFormat}}</td>
          </tr>
        </tbody>
      </table>
      <div style="height:10px"></div>
      <table
        style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;border-top:1px solid black"
      >
        <tr>
          <td></td>
          <td></td>
          <td style="text-align:right;font-weight:bold;font-size:11px">Net Amount</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{sale.netAmount | numFormat}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td style="text-align:right;font-weight:bold;font-size:11px">Tax (Add On)</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{sale.taxAmount | numFormat}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td style="text-align:right;font-weight:bold;font-size:11px">Total Amount</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{sale.totalAmount | numFormat}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td style="text-align:right;font-weight:bold;font-size:11px">Recived</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{sale.payAmount | numFormat}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td style="text-align:right;font-weight:bold;font-size:11px">Pay Change</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{sale.payChange | numFormat}}</td>
        </tr>
      </table>
    </div>
    <div style="height:5px;"></div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">Thank You!Please Come Again.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "invoice",
  props: { saleData: Object },
  data: function() {
    return {
      sale: this.saleData
    };
  }
};
</script>

<style>
</style>
