import Vue from "vue";
import App from "./App.vue";
import Vuetify from "vuetify";
import Vuelidate from "vuelidate";
import moment from "moment";
import numFormat from "vue-filter-number-format";


import { router } from "./router";

import "@babel/polyfill";
import "./plugins/vuetify";

import "material-design-icons-iconfont/dist/material-design-icons.css";
import '@fortawesome/fontawesome-free/css/all.css'

import Default from "./components/layout/Default.vue"
import Public from "./components/layout/Public.vue"


// Initialize vuetify
Vue.use(Vuetify, {
  theme: {
    primary: "#2962FF"
  }
});
Vue.use(Vuetify, {
  iconfont: "md"
});

// Initialize vuelidate
Vue.use(Vuelidate);

// Initialize moment for format date
Vue.filter("format-date", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
  }
});
Vue.filter("format-ms-date", function (value) {
  if (value) {
    return moment.unix(value / 1000).format("DD/MM/YYYY hh:mm:ss a");
  }
});
Vue.filter("format-date-only", function (value) {
  if (value) {
    return moment.unix(value / 1000).format("DD/MM/YYYY");
  }
});

// Initialize number format
Vue.filter("numFormat", numFormat);

Vue.component('default-layout', Default)
Vue.component('public-layout', Public)

Vue.config.productionTip = false;
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
