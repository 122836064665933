import axios from "../axios-auth";

export const kitchenService = {
  getKitchenList,
  saveKitchen,
  getKitchenDetail,
  getCategoriesByStatus,
  getStocksByCategory
};

function getKitchenList(searchParams, page, size, sortedBy) {
  let url = '';
  if (!page) {
    page = 0;
  } else {
    page -= 1;
  }
  if (!size) {
    size = 50;
  }
  if (sortedBy == 'null,asc') {
    url = "/auth/kitchens?page=" + page + "&size=" + size;
  } else {
    url = "/auth/kitchens?page=" + page + "&size=" + size + "&sort=" + sortedBy;
  }
  if (searchParams.kitchenName) {
    url += "&kitchenName=" + searchParams.kitchenName;
  }
  if (searchParams.status) {
    url += "&status=" + searchParams.status;
  }
  return axios.get(url);
}

function saveKitchen(kitchen) {
  if (kitchen.id) {
    //edit
    return axios.put("/auth/kitchen", kitchen);
  } else {
    // create new
    return axios.post("/auth/kitchen", kitchen);
  }
}

function getKitchenDetail(id) {
  return axios.get("/auth/kitchen/" + id);
}

function getCategoriesByStatus(status) {
  return axios.get("auth/categories-by-status/" + status);
}

function getStocksByCategory(searchParams) {
  let params = {};
  if (searchParams.categoryName) {
    params["categoryName"] = searchParams.categoryName;
  }
  if (searchParams.shopName) {
    params["shopName"] = searchParams.shopName;
  }
  if (searchParams.kitchenId) {
    params["kitchenId"] = searchParams.kitchenId;
  }
  if (searchParams.status) {
    params["status"] = searchParams.status;
  }
  if (searchParams.menu) {
    params["menu"] = searchParams.menu;
  }
  return axios.get("/auth/stocks-by-category/", { params });
}