export const AuthGuard = {
    getAuthentication,
    isAuthenticated,
    storeAuthentication
};

function getAuthentication() {
    // return authorization header with jwt token
    let accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        return {'Authorization': 'Bearer ' + accessToken};
    } else {
        return {};
    }
}

function isAuthenticated() {
    let accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        return true;
    } else {
        return false;
    }
}

function storeAuthentication(token) {
    localStorage.setItem("accessToken", token);
}