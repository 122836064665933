import axios from '../axios-auth'

export const systemSerializeService = {
    getSystemSerializeList,
    saveSystemSerialize,
    getSystemSerializeDetail
};

function getSystemSerializeList(searchParams, page, size ,sortedBy) {
    let url = '';
    if(!page){
        page = 0;
    }
    else{
        page -= 1;
    }
    if(!size){
        size = 50;
    }
    if (sortedBy == 'null,asc') {
        url = '/auth/system-serializes?page='+page+'&size='+size;
    } else {
        url = '/auth/system-serializes?page='+page+'&size='+size+'&sort='+sortedBy;
    }
    if (searchParams.prefix) {
        url += '&prefix=' + searchParams.prefix;
    }
    if (searchParams.status) {
        url += '&status=' + searchParams.status;
    }
    return axios.get(url);
}

function saveSystemSerialize(systemSerialize) {
    if (systemSerialize.id) {
        //edit
        return axios.put("/auth/system-serialize", systemSerialize);
      } else {
        // create new
        return axios.post("/auth/system-serialize", systemSerialize);
      }
}

function getSystemSerializeDetail(id) {
    return axios.get("/auth/system-serialize/" + id);
}