<template>
  <div id="id" style="bsale:1px solid black;width:300px;" v-show="false">
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">{{invoiceData.title}}</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">{{invoiceData.address}}</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">Tel: {{invoiceData.phoneNo}}</p>
    </div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">Type: Delivery</p>
    </div>
    <!-- <div style="text-align:center">
      <p style="font-size:11px;margin:0px">Invoice #: {{order.saleNo}}</p>
    </div>-->
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px" id="date"></p>
    </div>
    <div style="text-align:center" v-if="order.orderNo">
      <p style="font-size:11px;margin:0px">Order No: {{order.orderNo}}</p>
    </div>
    <!-- <div style="text-align:center">
      <p style="font-size:11px;margin:0px">Table #: {{order.tableNo}}</p>
    </div>
    <div style="float:left;padding-left:11px">
      <p style="font-size:11px;margin:0px" id="counter">Counter: {{order.counterName}}</p>
    </div>
    <div style="float:right;padding-right:11px">
      <p style="font-size:11px;margin:0px" id="casher">Casher: {{order.username}}</p>
    </div>-->
    <div style="clear:both"></div>
    <div style="height:10px"></div>
    <div style="padding-left:5px;padding-right:5px">
      <table
        style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;"
      >
        <thead style="border-bottom:1px solid black;border-top:1px solid black">
          <tr>
            <th></th>
            <th style="text-align:center">Qty</th>
            <th style="text-align:center">Unit</th>
            <th style="text-align:center">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in order.orderHeaderDetails" :key="index">
            <td style="text-align:left">{{item.stock.name}}</td>
            <td style="text-align:center">{{item.orderQty}}</td>
            <td style="text-align:right">{{item.price | numFormat('0.00')}}</td>
            <td style="text-align:right">{{ (item.price * item.orderQty) | numFormat('0.00')}}</td>
          </tr>
        </tbody>
      </table>
      <div style="height:10px"></div>
      <table
        style="font-family: arial, sans-serif;border-collapse: collapse;width: 100%;font-size:11px;border-top:1px solid black"
      >
        <tr>
          <td></td>
          <td></td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >Total Amount ({{order.baseCurrencyCode}})</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{order.printTotalAmount | numFormat('0.00')}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td style="text-align:right;font-weight:bold;font-size:11px">Discount (%)</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{order.discountAmount | numFormat('0.00')}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >Delivery Fee ({{order.baseCurrencyCode}})</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{order.deliveryFee | numFormat('0.00')}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >Net Amount ({{order.baseCurrencyCode}})</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{order.printNetAmount | numFormat('0.00')}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >Exchange Rate ({{order.baseCurrencyCode }})</td>
          <td style="text-align:right;font-weight:bold;font-size:11px">{{ order.exchangeRate }}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >Grand Total ({{order.baseCurrencyCode }})</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{order.customerTotalAmount | numFormat('0.00')}}</td>
        </tr>
        <!-- <tr>
          <td></td>
          <td></td>
          <td style="text-align:right;font-weight:bold;font-size:11px">Recived</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{order.payAmount | numFormat}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td style="text-align:right;font-weight:bold;font-size:11px">Pay Change</td>
          <td
            style="text-align:right;font-weight:bold;font-size:11px"
          >{{order.payChange | numFormat}}</td>
        </tr>-->
      </table>
    </div>
    <div style="height:5px;"></div>
    <div style="text-align:center">
      <p style="font-size:11px;margin:0px">Thank You!</p>
    </div>
    <br />
    <div style="height:10px;"></div>
    <div style="text-align:center">
      <p style="font-size:14px;margin:0px">
        <u>Delivery Note</u>
      </p>
    </div>
    <br />
    <div style="text-align:center">
      <p style="font-size:14px;margin:0px">Order Date: {{ order.orderDate}}</p>
      <p style="font-size:14px;margin:0px">Estimate Date: {{ order.estimateDeliveryDate}}</p>
    </div>
    <br />
    <div style="height:5px"></div>
    <div style="text-align:center">
      <p style="font-size:14px;margin:0px">Customer Information</p>
    </div>
    <table
      style="font-family: arial, sans-serif;width: 100%;font-size:11px;border-collapse: collapse;"
      border="1"
    >
      <tr>
        <td style="width:40%">Customer Name</td>
        <td>{{order.customerName}}</td>
      </tr>
      <tr>
        <td style="width:40%">Phone</td>
        <td>{{order.customerPhoneNo}}</td>
      </tr>
      <tr>
        <td style="width:40%">Remark</td>
        <td>{{order.remark}}</td>
      </tr>
      <tr>
        <td style="width:40%">Township, City</td>
        <td>{{order.townshipName}}, {{order.cityName}}</td>
      </tr>
      <tr>
        <td style="width:40%">Delivery Address</td>
        <td>{{order.deliveryAddress}}</td>
      </tr>
      <tr>
        <td style="width:40%">Billing Address</td>
        <td>{{order.billingAddress}}</td>
      </tr>
    </table>
    <div></div>
  </div>
</template>

<script>
import { systemSettingService } from "../../services/system-setting-service";
export default {
  name: "invoice",
  props: { orderData: Object },
  data: function () {
    return {
      invoiceData: {},
      queryParams: {
        name: "",
        value: "",
      },
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 50,
        sortBy: "name",
        totalItems: 0,
      },
    };
  },
  computed: {
    order() {
      return this.orderData;
    },
  },
  created() {
    this.getInvoiceData();
  },
  methods: {
    async getInvoiceData() {
      try {
        let settingList = [];
        let order = this.pagination.descending ? "desc" : "asc";
        let res = await systemSettingService.getSettings(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        );
        settingList = res.data.systemSettingList;
        this.invoiceData = await settingList.filter((setting) => {
          return setting.name == "INVOICE_DATA";
        })[0];
        let _invData = JSON.parse(this.invoiceData.value);
        this.invoiceData.title = _invData.title;
        this.invoiceData.address = _invData.address;
        this.invoiceData.phoneNo = _invData.phoneNo;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>
