<template>
  <div>
    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      fixed
      app
      id="nav-drawer"
      width="200"
    >
      <vue-perfect-scrollbar class="drawer-menu--scroll pl-2" :settings="scrollSettings">
        <v-list dense>
          <template v-for="(item,index) in items">
            <v-list-group
              v-if="item.children"
              v-model="item.model"
              :key="item.text"
              :prepend-icon="item.model ? item.icon : item['icon-alt']"
              append-icon
            >
              <v-list-tile slot="activator">
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.text }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile v-for="(child, i) in item.children" :key="i">
                <v-list-tile-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ child.text }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list-group>
            <v-subheader v-else-if="item.header" :key="index">{{ item.header }}</v-subheader>
            <v-list-tile v-else :key="item.text" :to="item.to">
              <v-list-tile-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ item.text }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
        </v-list>
      </vue-perfect-scrollbar>
    </v-navigation-drawer>
    <v-toolbar :clipped-left="$vuetify.breakpoint.lgAndUp" color="blue darken-2" dark app fixed>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <v-toolbar-side-icon @click.stop="drawer = !drawer">
          <div class="v-btfn__content">
            <i aria-hidden="true" class="v-icon material-icons">menu</i>
          </div>
        </v-toolbar-side-icon>
        <span class="hidden-sm-and-down">
          <img style="height: 48px;margin-bottom: -14px;" src="@/assets/images/header-logo.png" />
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="text-xs-center">
        <v-chip color="primary" text-color="white">{{ userName }}</v-chip>
      </div>
      <v-menu offset-y origin="center center" :nudge-bottom="10" transition="scale-transition">
        <v-btn icon large flat slot="activator">
          <v-icon large>account_circle</v-icon>
        </v-btn>
        <v-list class="pa-0">
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>lock</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title @click="changePassword()">Change Password</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>fullscreen_exit</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title @click="logout()">Logout</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AdminMenus, ManagerMenus, SaleMenus, SysAdminMenus } from "./menu";

export default {
  name: "NavigationDrawer",
  components: {
    VuePerfectScrollbar
  },
  data: () => ({
    scrollSettings: {
      maxScrollbarLength: 160
    },
    dialog: false,
    drawer: null,
    adminMenus: AdminMenus,
    sysadminMenus: SysAdminMenus,
    managerMenus: ManagerMenus,
    saleMenus: SaleMenus,
    items: [],
    userName: JSON.parse(localStorage.getItem("user")).username
  }),
  created() {
    // control permission
    this.getMenuByPermission();
  },
  methods: {
    getMenuByPermission() {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user.role.roleName == "MANAGER") {
        this.items = this.managerMenus;
      } else if (user.role.roleName == "SALE") {
        this.items = this.saleMenus;
      } else if (user.role.roleName == "ADMIN") {
        this.items = this.adminMenus;
      } else if (user.role.roleName == "SYSADMIN"){
        this.items = this.sysadminMenus;
      }
    },
    logout() {
      localStorage.clear();
      window.history.go();
    },
    changePassword() {
      this.$router.push("/change-password");
    }
  }
};
</script>


<style lang="stylus">
#nav-drawer {
  overflow: hidden;

  .drawer-menu--scroll {
    height: calc(100vh - 48px);
    overflow: auto;
  }
}

.v-list__tile__title {
  cursor: pointer;
}

.v-navigation-drawer .v-list {
  margin-bottom: 30px;
}

.v-list__tile__action {
  min-width: 35px !important;
}
</style>