import axios from "../axios-auth";

export const currencyService = {
    getCurrencyList,
    saveCurrency,
    getCurrencyDetail,
    getActiveCurrency,
    getBaseCurrency
};



function getCurrencyList(searchParams, page, size, sortedBy) {
    let url = '';
    if (!page) {
        page = 0;
    }
    else {
        page -= 1;
    }
    if (!size) {
        size = 50;
    }
    if (sortedBy == 'null,asc') {
        url = '/auth/all-currency?page=' + page + '&size=' + size;
    } else {
        url = '/auth/all-currency?page=' + page + '&size=' + size + '&sort=' + sortedBy;
    }
    if (searchParams.name) {
        url += '&name=' + searchParams.name;
    }

    if (searchParams.code) {
        url += '&code=' + searchParams.code;
    }
    if (searchParams.status) {
        url += '&status=' + searchParams.status;
    }
    return axios.get(url);
}

function saveCurrency(currency) {
    if (!currency.id) {
        // new currency
        return axios.post("/auth/currency", currency);
    } else {
        // edit
        return axios.put("/auth/currency", currency);
    }
}

function getCurrencyDetail(id) {
    return axios.get('/auth/currency/' + id);
}

function getActiveCurrency(status = 'ACTIVE') {
    return axios.get("/currency-by-status/" + status)
}

function getBaseCurrency(isBase) {
    let params = {
        isBase: isBase
    };
    return axios.get("/base-currency" , { params })
}

