<template>
  <v-app>
    <component :is="layout" v-if="layout!='undefined-layout'">
      <router-view></router-view>
    </component>
  </v-app>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    layout() {
      return this.$route.meta.layout + "-layout";
    }
  },
  mounted() {
    console.log(this.layout);
  }
};
</script>