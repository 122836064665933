<template>

    <v-container fluid full-height grid-list-md>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSave">
            <v-layout row wrap>

                <v-flex xs6>
                    <div class="text-xs-left">
                        <v-breadcrumbs :items="breadcrumbs" divider=">">
                            <template slot="item" slot-scope="props">
                                <router-link :to="'/'+props.item.to" :disabled="props.item.disabled"
                                             v-bind:class="{ 'v-breadcrumbs__item v-breadcrumbs__item--disabled': props.item.disabled }">
                                    {{props.item.text}}
                                </router-link>
                            </template>
                        </v-breadcrumbs>
                    </div>
                </v-flex>
                <v-flex xs6>
                    <div class="text-xs-right">
                        <v-btn outline color="primary" @click="goBack">
                            Go Back
                            <v-icon right>arrow_back</v-icon>
                        </v-btn>
                    </div>
                </v-flex>

                <v-flex xs6 offset-sm3>
                    <v-card>
                        <v-layout row wrap class="pa-3">
                            <v-flex sm12 v-if="responseError">
                                <span class="red--text">{{responseError}}</span>
                            </v-flex>
                            <v-flex sm12>
                                <v-text-field
                                        name="username"
                                        label="Username"
                                        v-model="user.username"
                                        type="text"
                                        :disabled="user.id?true:false"
                                        :rules="[
                                                        () => !!user.username || 'Username is required',
                                                    ]">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12 v-if="!user.id">
                                <v-text-field
                                        name="password"
                                        label="Password"
                                        v-model="user.password"
                                        type="password"
                                        :rules="[
                                                        () => !!user.password || 'Password is required',
                                                    ]">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12 v-if="!user.id">
                                <v-text-field
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        v-model="user.confirmPassword"
                                        type="password"
                                        :rules="[
                                                        () => !!user.confirmPassword || 'Confirm Password is required',
                                                        ()=> user.password == user.confirmPassword || 'Password and confirm password does not match'
                                                    ]">
                                </v-text-field>
                            </v-flex>

                            <v-flex sm12>
                                <v-select
                                        :items="roleList"
                                        v-model="user.roleName"
                                        label="Select role name"
                                        item-text="label"
                                        item-value="value"
                                        :rules="[
                                                        () => !!user.roleName || 'Role Name is required'
                                                        ]"
                                ></v-select>
                            </v-flex>
                            <v-flex sm12 v-if="user.roleName && user.roleName != 'ADMIN' && user.roleName != 'CUSTOMER' && user.roleName != 'SYSADMIN'">
                                <v-select
                                        :items="shopNameList"
                                        v-model="user.name"
                                        label="Select shop name"
                                        :rules="[
                                                        () => !!user.name || 'Shop Name is required'
                                                        ]"
                            ></v-select>
                            </v-flex>
                            <v-flex sm6 v-if="user.id">
                                <v-switch
                                        :label="`Status is ${statusFlag?'Active': 'Inactive'}`"
                                        :value="statusFlag"
                                        v-model="statusFlag"
                                ></v-switch>
                            </v-flex>
                            <v-flex sm6 v-if="user.id">
                                <v-btn
                                        flat
                                        color="primary"
                                        @click="showChangePwdModal()"
                                        style="float:right"
                                >
                                    Change Password?
                                </v-btn>
                            </v-flex>

                            <v-flex sm12>
                                <div class="text-xs-center">
                                    <v-btn color="error" @click="clear">
                                        Reset
                                        <v-icon right>clear</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" :loading="saveLoading" :disabled="!valid" type="submit">
                                        Save
                                        <v-icon right>save</v-icon>
                                    </v-btn>

                                </div>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>

            </v-layout>

        </v-form>
        <!----------------- For Change Password Dialog --------------------->

        <v-dialog v-model="openDialog" persistent max-width="350">
            <v-form ref="modalForm" v-model="modalValid" lazy-validation @submit.prevent="changePassword">
                <v-card>
                    <v-card-title primary-title
                                  class="headline blue darken-2">
                        <span class="white--text">Change Password</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <div class="ma-3">
                        <v-flex sm12>
                            <v-text-field
                                    name="username"
                                    label="Username"
                                    v-model="modalUser.username"
                                    type="text"
                                    readonly>
                            </v-text-field>
                        </v-flex>
                        <v-flex sm12>
                            <v-text-field
                                    name="password"
                                    label="Password"
                                    v-model="modalUser.password"
                                    type="password"
                                    :rules="[
                                                        () => !!modalUser.password || 'Password is required',
                                                        () => modalUser.password && modalUser.password.length > 6 || 'Password length must be greater than 6 characters'
                                                    ]">
                            </v-text-field>
                        </v-flex>
                        <v-flex sm12>
                            <v-text-field
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    v-model="modalUser.confirmPassword"
                                    type="password"
                                    :rules="[
                                                        () => !!modalUser.confirmPassword || 'Confirm Password is required',
                                                        ()=> modalUser.password == modalUser.confirmPassword || 'Password and confirm password does not match'
                                                    ]">
                            </v-text-field>
                        </v-flex>
                    </div>
                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red"
                               flat @click.native="openDialog = false">Cancel
                        </v-btn>
                        <v-btn color="primary" flat type="submit">Change Password</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

    </v-container>

</template>

<script>
    import {userService} from "../../services/user-service";
    import {shopService} from '../../services/shop-service';
    import {User} from "../../models/model";
    import VSwitch from "vuetify/lib/components/VSwitch/VSwitch";

    export default {
        name: "UserDetail",
        components: {VSwitch},
        data: () => ({
            openDialog: false,
            valid: true,
            modalValid: true,
            breadcrumbs: [
                {
                    text: 'Users',
                    disabled: false,
                    to: 'users'
                },
                {
                    text: 'User Detail',
                    disabled: true
                }
            ],
            user: new User(),
            oldUser: new User(),
            statusFlag: true,
            roleNameList: [],
            roleList: [{ label: "Admin", value: "ADMIN" }],
            shopNameList: [],
            saveLoading: false,
            responseError: '',
            modalError: '',
            modalUser: new User()
        }),
        created() {
            const id = this.$route.params.id;
            if (id == 'new') {
                // new user
            }
            else {
                this.getUserDetail(id);
            }
            let loggedInUser = JSON.parse(localStorage.getItem("user"));
            if (loggedInUser.role.roleName == "SYSADMIN") {
                this.roleList = [
                    { label: "Admin", value: "ADMIN" }, 
                    { label: "System Admin", value: "SYSADMIN" }
                ];
            }
            this.getAllRoles();
            this.getAllShops();
        },
        methods: {
            changePassword() {
                if (this.$refs.modalForm.validate()) {
                    userService.changePasswordById(this.modalUser)
                        .then(res => {
                            console.log(res);
                            this.goBack();
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
            },
            showChangePwdModal() {
                this.$refs.modalForm.resetValidation();
                this.openDialog = true;
                this.modalUser.username = this.user.username;
                this.modalUser.id = this.user.id;
            },
            getAllRoles() {
                userService.getAllRoles()
                    .then(res => {
                        let roleList = res.data;
                        for (let role of roleList) {
                            this.roleNameList.push(role.roleName);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })

            },
            getAllShops() {
                shopService.getAllShops()
                    .then(res => {
                        let shopList = res.data;
                        for (let shop of shopList) {
                            this.shopNameList.push(shop.name);
                            console.log(shop.name);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })

            },
            getUserDetail(id) {
                userService.getUserDetail(id)
                    .then(res => {
                        this.user = res.data;
                        this.oldUser = Object.assign({}, res.data);
                        this.statusFlag = this.user.status == 'ACTIVE' ? true : false;

                    })
                    .catch(err => {
                        console.log(err);
                    })

            },
            onSave() {
                if (this.$refs.form.validate()) {
                    this.saveLoading = true;
                    this.user.status = this.statusFlag ? 'ACTIVE' : 'INACTIVE';

                    let userObj = {
                        id: this.user.id ? this.user.id : '',
                        username: this.user.username,
                        password: this.user.password,
                        roleName: this.user.roleName,
                        name: this.user.name,
                        status: this.user.status
                    };
                    userService.saveUser(userObj)
                        .then(res => {
                            console.log(res);
                            this.goBack();
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.data) {
                                this.responseError = err.response.data.message;
                            }
                            this.saveLoading = false;
                        });
                }

            },
            clear() {
                this.$refs.form.resetValidation();
                if (!this.user.id) {
                    this.user = new User();
                    this.statusFlag = true;
                }
                else {
                    this.user = Object.assign({}, this.oldUser);
                    this.statusFlag = this.user.status == 'ACTIVE' ? true : false;
                }

                this.responseError = '';
            },
            goBack() {
                this.$router.push('/users');
            }
        }
    }
</script>

<style scoped>

</style>