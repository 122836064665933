<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs6>
        <div class="text-xs-left">
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex xs6>
        <div class="text-xs-right">
          <v-btn color="primary" @click="onRegister">
            New User
            <v-icon right>add_circle</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-card>
          <v-layout row wrap class="pa-3">
            <v-flex sm3>
              <v-text-field
                name="username"
                label="User Name"
                type="text"
                v-model="queryParams.name"
              ></v-text-field>
            </v-flex>
            <v-flex sm3>
              <v-select
                :items="roleList"
                label="Role"
                v-model="queryParams.role"
                item-text="label"
                item-value="value"
              ></v-select>
            </v-flex>
            <v-flex sm3>
              <v-select
                :items="statusList"
                label="Status"
                v-model="queryParams.status"
                item-text="label"
                item-value="value"
              ></v-select>
            </v-flex>

            <v-flex sm3>
              <v-btn outline color="primary" @click="onSearch">
                Search
                <v-icon right>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 class="mt-1">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="userList"
            class="elevation-1"
            :loading="showListLoading"
            :total-items="totalRows"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.username }}</td>
              <td>{{ props.item.roleName }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.createdDateInMilliSeconds | format-ms-date }}</td>
              <td>{{ props.item.createdBy }}</td>
              <td>
                <v-icon v-if="props.item.status=='ACTIVE'" medium color="green">check_circle</v-icon>
                <v-icon v-else medium color="red">cancel</v-icon>
              </td>
              <td>
                <v-btn
                  flat
                  icon
                  color="blue lighten-2"
                  @click="onEdit(props.item)"
                  :disabled="user.role.roleName != 'SYSADMIN' && props.item.roleName == 'SYSADMIN'"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { userService } from "../../services/user-service";

export default {
  name: "UserList",

  components: {},
  data: () => ({
    showListLoading: false,
    user: JSON.parse(localStorage.getItem("user")),
    breadcrumbs: [
      {
        text: "User Management",
        disabled: true
      },
      {
        text: "Users",
        disabled: true
      }
    ],
    headers: [
      {
        text: "Username",
        align: "left",
        value: "username"
      },
      { text: "Role Name", value: "role.roleName" },
      { text: "Shop Name", value: "shopName" },
      { text: "Created Date", value: "createdDate" },
      { text: "Created By", value: "createdBy" },
      { text: "Status", value: "status", sortable: false },
      { text: "Action", value: "name", sortable: false }
    ],
    rowsPerPageItems: [25, 50, 100],
    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 50,
      sortBy: "username",
      totalItems: 0
    },
    userList: [],
    totalRows: 0,
    statusList: [
      { label: "Active", value: "ACTIVE" },
      { label: "Inactive", value: "INACTIVE" }
    ],
    roleList: [{ label: "Admin", value: "ADMIN" }],
    queryParams: {
      name: "",
      role: "",
      status: ""
    }
  }),
  watch: {
    pagination: {
      handler() {
        this.getUserList();
      },
      deep: true
    }
  },
  created() {
    this.onSearch();
    //for deep linking
    if (this.$route.query.name) {
      this.queryParams.name = this.$route.query.name;
    }
    if (this.$route.query.status) {
      this.queryParams.status = this.$route.query.status;
    }
    if (this.$route.query.role) {
      this.queryParams.role = this.$route.query.role;
    }
    if (this.$route.query.page) {
      this.pagination.page = this.$route.query.page;
    }
    if (this.$route.query.size) {
      this.pagination.size = this.$route.query.size;
    }

    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    if (loggedInUser.role.roleName == "SYSADMIN") {
      this.roleList = [
        { label: "Admin", value: "ADMIN" },
        { label: "System Admin", value: "SYSADMIN" }
      ];
    }
  },
  methods: {
    onSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.$router.replace({ name: "users", query: this.queryParams });
      this.getUserList();
    },
    getUserList() {
      this.showListLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";
      userService
        .getUserList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        )
        .then(res => {
          if (res.data) {
            this.userList = res.data.userList;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    onRegister() {
      this.$router.push("/users/new");
    },
    onEdit(user) {
      // go to details page
      this.$router.push("/users/" + user.id);
    }
  }
};
</script>

<style scoped>
</style>