export function User() {
  return {
    id: "",
    username: "",
    password: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    roleName: "",
    name: "",
    status: ""
  };
}

export function Stock() {
  return {
    id: "",
    promotionId: "",
    barcode: "",
    sku: "",
    name: "",
    categoryName: "",
    unitPrice: 1,
    promotionPrice: "",
    imageUrl: "",
    image: "",
    description: "",
    status: "ACTIVE",
    menu: true,
    isPromo: false,
    createdBy: "",
    createdDateInMilliSeconds: 0,
    updatedBy: "",
    updatedDateInMilliSeconds: 0,
    kitchenName: "",
    maxOrderQty: 100,
    stockComboSet: [],
    stockImages: [],
    deleteStockImageIds: [],
    deleteStockComboIds: []
  };
}

export function Category() {
  return {
    id: "",
    name: "",
    status: "ACTIVE",
    imageUrl: "",
    image: "",
  };
}

export function City() {
  return {
    id: "",
    name: "",
    code: "",
    description: ""
  };
}

export function TableInfo() {
  return {
    id: "",
    tableNo: "",
    flowLocation: "",
    shopName: "",
    status: "ACTIVE"
  };
}

export function Kitchen() {
  return {
    id: "",
    kitchenName: "",
    printerName: "",
    description: "",
    ipAddress: "",
    shopName: "",
    categoryName: "",
    status: "ACTIVE",
    kitchenStocks: []
  };
}

export function SystemSerialize() {
  return {
    id: "",
    prefix: "",
    serializeNo: "",
    shopName: "",
    isAutoReset: "",
    status: ""
  };
}

export function TaxManagement() {
  return {
    id: "",
    taxName: "",
    taxPercentage: "",
    taxType: "",
    status: "",
    description: ""
  };
}

export function Sale() {
  return {
    id: "",
    saleNo: "",
    netAmount: 0,
    taxAmount: 0,
    payAmount: 0,
    payChange: 0,
    totalAmount: 0,
    username: "",
    shopId: 0,
    shopName: "",
    counterId: 0,
    counterName: "",
    taxId: 0,
    taxName: "",
    taxType: "",
    taxPercentage: 0,
    saleType: "DINE_IN",
    note: "",
    tableNo: "",
    phoneNo: "",
    address: "",
    saleDate: "",
    saleItems: [],
    kitchenGroupNames: [],
    orderNotes: []
  };
}
export function CounterCash() {
  return {
    id: "",
    counterName: "",
    cashType: "",
    amount: "",
    description: "",
    shopId: "",
    counterId: ""
  };
}
export function OrderHeader() {
  return {
    id: "",
    orderNo: "",
    orderDateInMilliSeconds: 0,
    csRequestDateInMilliSeconds: 0,
    deliveryAddress: "",
    billingAddress: "",
    estimateDeliveryDateInMilliSeconds: 0,
    status: "",
    approvedId: "",
    approvedPerson: "",
    approvedDateInMilliSeconds: 0,
    discountAmount: "",
    remark: "",
    adminRemark: "",
    totalAmount: "",
    orderHeaderDetails: [],
    recaptchaToken: ""
  };
}
export function OrderHeaderDetail() {
  return {
    id: "",
    orderHeaderId: "",
    stockId: "",
    stock: new Stock(),
    orderQty: "",
    deliveryQty: "",
    defectQty: "",
    price: "",
    remark: ""
  };
}

export function Township() {
  return {
    id: "",
    code: "",
    name: "",
    cityId: "",
    deliveryFee: "",
    description: "",
    status: "",
    deliveryAvailable: true
  };
}


export function ServiceCategory() {
  return {
    id: "",
    name: "",
    status: "ACTIVE",
  };
}

export function Service() {
  return {
    id: "",
    name: "",
    status: "ACTIVE",
    description: "",
    serviceCategoryId: 0,
    image: ""
  };
}

export function Promotion() {
  return {
    id: "",
    name: "",
    status: "ACTIVE",
    description: "",
    image: ""
  };
}