import axios from "../axios-auth";

export const taxManagementService = {
  getTaxList,
  saveTax,
  getTaxDetail,
  getTaxByStatus
};

function getTaxList(searchParams, page, size, sortedBy) {
  let url = '';
  if (!page) {
    page = 0;
  } else {
    page -= 1;
  }
  if (!size) {
    size = 50;
  }
  if (sortedBy == 'null,asc') {
    url = "/auth/taxs?page=" + page + "&size=" + size;
  } else {
    url = "/auth/taxs?page=" + page + "&size=" + size + "&sort=" + sortedBy;
  }
  if (searchParams.name) {
    url += "&name=" + searchParams.name;
  }
  if (searchParams.status) {
    url += "&status=" + searchParams.status;
  }
  if (searchParams.taxType) {
    url += "&taxType=" + searchParams.taxType;
  }
  return axios.get(url);
}

function saveTax(tax) {
  if (tax.id) {
    //edit
    return axios.put("/auth/updatetax", tax);
  } else {
    // create new
    return axios.post("/auth/tax", tax);
  }
}

function getTaxDetail(id) {
  return axios.get("/auth/tax/" + id);
}

function getTaxByStatus(status) {
  return axios.get("/auth/get-tax-by-status?status=" + status);
}
