<template>
  <v-dialog v-model="dialog" scrollable persistent transition="dialog-transition">
    <v-card>
      <v-layout row wrap>
        <v-flex xs12 text-xs-right>
          <v-btn color="error" outline @click="$emit('close')">
            <v-icon left>clear</v-icon>Close
          </v-btn>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-layout row wrap class="pa-3">
              <v-flex sm2 pr-2>
                <v-text-field
                  :hide-details="true"
                  name="sku"
                  label="SKU"
                  type="text"
                  v-model="queryParams.sku"
                ></v-text-field>
              </v-flex>
              <v-flex sm2 pr-2>
                <v-text-field
                  :hide-details="true"
                  name="name"
                  label="Name"
                  type="text"
                  v-model="queryParams.name"
                ></v-text-field>
              </v-flex>
              <v-flex sm2 pr-2>
                <v-text-field
                  :hide-details="true"
                  name="categoryName"
                  label="Category Name"
                  type="text"
                  v-model="queryParams.categoryName"
                ></v-text-field>
              </v-flex>
              <v-flex sm2 pr-2>
                <v-select
                  :items="statusList"
                  label="Status"
                  v-model="queryParams.status"
                  item-text="label"
                  item-value="value"
                ></v-select>
              </v-flex>
              <v-flex sm2>
                <v-btn outline color="primary" @click="onSearch">
                  Search
                  <v-icon right>search</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 class="mt-1">
          <v-card>
            <v-data-table
              :headers="headers"
              :items="stockList"
              class="elevation-1"
              :loading="showListLoading"
              :total-items="totalRows"
              :rows-per-page-items="rowsPerPageItems"
              :pagination.sync="pagination"
            >
              <template slot="items" slot-scope="props">
                <tr @click="props.expanded = !props.expanded">
                  <td>
                    <v-btn outline icon color="blue lighten-2" @click="$emit('add', props.item)">
                      <v-icon>add</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <div class="mt-3">
                      <v-avatar size="70px" class="mb-2">
                        <img
                          src="../../assets/images/no-image.png"
                          v-if="!props.item.imageUrl"
                          aspect-ratio="1"
                          contain
                          class="grey lighten-2"
                        />
                        <v-img
                          v-if="props.item.imageUrl"
                          :src="props.item.imageUrl"
                          class="grey lighten-2"
                        >
                          <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-layout>
                        </v-img>
                      </v-avatar>
                    </div>
                  </td>
                  <td>{{ props.item.sku }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.categoryName }}</td>
                  <td>{{ props.item.unitPrice | numFormat}}</td>
                  <td>
                    <p v-html="props.item.description"></p>
                  </td>
                  <td>
                    <v-icon v-if="props.item.menu" medium color="green">check_circle</v-icon>
                    <v-icon v-else medium color="red">cancel</v-icon>
                  </td>
                  <td>
                    <v-icon v-if="props.item.status=='ACTIVE'" medium color="green">check_circle</v-icon>
                    <v-icon v-else medium color="red">cancel</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import { stockService } from "../../services/stock-service";
import { commonService } from "../../services/common-service";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  name: "StockList",
  data: () => ({
    showListLoading: false,
    breadcrumbs: [
      {
        text: "Stocks",
        disabled: true,
      },
    ],
    headers: [
      {
        text: "Image",
        align: "left",
        sortable: false,
        value: "image",
      },
      { text: "Sku", value: "sku" },
      { text: "Name", value: "name" },
      { text: "Category", value: "category" },
      { text: "UnitPrice", value: "unitPrice" },
      { text: "Description", value: "description" },
      { text: "Menu", value: "menu", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Action", value: "name", sortable: false },
    ],
    rowsPerPageItems: [25, 50, 100],
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 50,
      sortBy: "createdDate",
      totalItems: 0,
    },
    stockList: [],
    totalRows: 0,
    openDialog: false,
    buttonLoading: false,
    queryParams: {
      status: "",
      categoryName: "",
      barcode: "",
      sku: "",
      name: "",
    },
    statusList: [
      { label: "Active", value: "ACTIVE" },
      { label: "Inactive", value: "INACTIVE" },
    ],
  }),
  watch: {
    pagination: {
      handler() {
        if (this.dialog) this.getStockList();
      },
      deep: true,
    },
    dialog(v) {
      if (v) this.getStockList();
    },
  },
  methods: {
    onSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.getStockList();
    },
    getStockList() {
      this.showListLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";
      stockService
        .getStockList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy ? this.pagination.sortBy + "," + order : ""
        )
        .then((res) => {
          console.log(res);
          if (res.data) {
            this.stockList = res.data.stocks;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>