import Vue from "vue";
import Router from "vue-router";
import Dashboard from "./components/dashboard/Dashboard";
import UserList from "./components/user/UserList";
import UserDetail from "./components/user/UserDetail";
import SystemSerializeList from "./components/systemSerialize/SystemSerializeList";
import SystemSerializeDetail from "./components/systemSerialize/SystemSerializeDetail";
import ChangePassword from "./components/user/ChangePassword";
import Login from "./components/login/Login";
// import PublicLogin from "./components/orderDelivery/PublicLogin";
import StockList from "./components/stock/StockList";
import StockDetail from "./components/stock/StockDetail";
import CategoryList from "./components/category/CategoryList";
import CategoryDetail from "./components/category/CategoryDetail";
import TableInfoList from "./components/tableInfo/TableInfoList";
import TableInfoDetail from "./components/tableInfo/TableInfoDetail";
import KitchenList from "./components/kitchen/KitchenList";
import KitchenDetail from "./components/kitchen/KitchenDetail";
import InitialSale from "./components/sale/InitialSale";
import SaleList from "./components/sale/SaleList";
import TaxList from "./components/tax/TaxManagementList";
import TaxDetailList from "./components/tax/TaxManagementDetail";
import OrderReport from "./components/reports/OrderReport";
import SaleReportByCounter from "./components/reports/SaleReportByCounter";
import MenuReport from "./components/reports/MenuReport";
import ProductOrderReport from "./components/reports/ProductOrderReport";
import CounterCashList from "./components/countercash/CounterCashList";
import CounterCashDetail from "./components/countercash/CounterCashDetail";
import CounterCashReport from "./components/countercash/CounterCashReport";
import CashInHandReport from "./components/reports/CashInHandReport";
import OrderList from "./components/orderDelivery/OrderList";
import OrderDetail from "./components/orderDelivery/OrderDetail";
import TownshipList from "./components/township/TownshipList.vue";
import TownshipDetail from "./components/township/TownshipDetail.vue";
import CityList from "./components/city/CityList.vue";
import CityDetail from "./components/city/CityDetail.vue";
import SystemSetting from "./components/systemSetting/SystemSetting.vue";

Vue.use(Router);

export const router = new Router({
  routes: [
    { path: "/", redirect: "/home" },
    { path: "/‌admin", redirect: "/login" },

    { path: "/dashboard", name: "dashboard", component: Dashboard, meta: { layout: "default" } },

    { path: "/users", name: "users", component: UserList, meta: { layout: "default" } },
    { path: "/users/:id", name: "users-detail", component: UserDetail, meta: { layout: "default" } },

    {
      path: "/system-serializes",
      name: "system-serializes",
      component: SystemSerializeList, meta: { layout: "default" }
    },
    {
      path: "/system-serialize/:id",
      name: "system-serialize-detail",
      component: SystemSerializeDetail, meta: { layout: "default" }
    },

    { path: "/stocks", name: "stocks", component: StockList, meta: { layout: "default" } },
    { path: "/stock/:id", name: "stock-detail", component: StockDetail, meta: { layout: "default" } },

    { path: "/categories", name: "categories", component: CategoryList, meta: { layout: "default" } },
    { path: "/category/:id", name: "category-detail", component: CategoryDetail, meta: { layout: "default" } },

    { path: "/table-infos", name: "table-infos", component: TableInfoList, meta: { layout: "default" } },
    { path: "/table-info/:id", name: "table-info-detail", component: TableInfoDetail, meta: { layout: "default" } },

    { path: "/kitchens", name: "kitchens", component: KitchenList, meta: { layout: "default" } },
    { path: "/kitchen/:id", name: "kitchen-detail", component: KitchenDetail, meta: { layout: "default" } },

    {
      path: "/change-password",
      name: "change-password",
      component: ChangePassword, meta: { layout: "default" }
    },


    { path: "/login", name: "login", component: Login, meta: { layout: "default" } },
    // { path: "*", redirect: "/product-list" }, // otherwise redirect to home

    { path: "/taxs", name: "taxs", component: TaxList, meta: { layout: "default" } },
    { path: "/taxs/:id", name: "taxs-detail", component: TaxDetailList, meta: { layout: "default" } },

    { path: "/initial-sale", name: "initial-sale", component: InitialSale, meta: { layout: "default" } },
    { path: "/sales", name: "sales", component: SaleList, meta: { layout: "default" } },
    { path: "/order-report", name: "order-report", component: OrderReport, meta: { layout: "default" } },
    { path: "/sale-report-by-counter", name: "sale-report-by-counter", component: SaleReportByCounter, meta: { layout: "default" } },
    { path: "/menu-report", name: "menu-report", component: MenuReport, meta: { layout: "default" } },
    { path: "/product-order-report", name: "product-order-report", component: ProductOrderReport, meta: { layout: "default" } },

    { path: "/counter-cashs", name: "counter-cashs", component: CounterCashList, meta: { layout: "default" } },
    { path: "/countercash/:id", name: "countercash-detail", component: CounterCashDetail, meta: { layout: "default" } },
    { path: "/counter-cash-report", name: "counter-cash-report", component: CounterCashReport, meta: { layout: "default" } },
    { path: "/cash-in-hand-report", name: "cash-in-hand-report", component: CashInHandReport, meta: { layout: "default" } },
    {
      path: "/townships",
      name: "townships",
      component: TownshipList,
      meta: { layout: "default" }
    },
    { path: "/township/:id", name: "township-detail", component: TownshipDetail, meta: { layout: "default" } },
    { path: "/orders", name: "orders", component: OrderList, meta: { layout: "default" } },
    { path: "/order/:id", name: "order-detail", component: OrderDetail, meta: { layout: "default" } },

    { path: "/cities", name: "cities", component: CityList, meta: { layout: "default" } },
    { path: "/city/:id", name: "city-detail", component: CityDetail, meta: { layout: "default" } },

    { path: "/system-setting", name: "system-setting", component: SystemSetting, meta: { layout: "default" } },
    {
      path: "/currency",
      name: "currencyList",
      component: () => import("./components/currency/CurrencyList.vue"),
      meta: { layout: "default" }
    },
    {
      path: "/currency/:id",
      name: "currency-detail",
      component: () => import("./components/currency/CurrencyDetail.vue"),
      meta: { layout: "default" }
    },
    {
      path: "/exchange",
      name: "exchangeList",
      component: () => import("./components/exchange/ExchangeList.vue"),
      meta: { layout: "default" }
    },
    {
      path: "/exchange/:id",
      name: "exchange-detail",
      component: () => import("./components/exchange/ExchangeDetail.vue"),
      meta: { layout: "default" }
    },
    {
      path: "/attachment",
      name: "attachmentList",
      component: () => import("./components/attachment/AttachmentList.vue"),
      meta: { layout: "default" }
    },
    {
      path: "/attachment/:id",
      name: "attachment-detail",
      component: () => import("./components/attachment/AttachmentDetail.vue"),
      meta: { layout: "default" }
    },
    {
      path: "/service-category",
      name: "serviceCategoryList",
      component: () => import("./components/serviceCategory/ServiceCategoryList.vue"),
      meta: { layout: "default" }
    },
    {
      path: "/service-category-detail/:id",
      name: "service-category-detail",
      component: () => import("./components/serviceCategory/ServiceCategoryDetail.vue"),
      meta: { layout: "default" }
    },
    {
      path: "/service",
      name: "serviceList",
      component: () => import("./components/service/ServiceList.vue"),
      meta: { layout: "default" }
    },
    {
      path: "/service-detail/:id",
      name: "service-detail",
      component: () => import("./components/service/ServiceDetail.vue"),
      meta: { layout: "default" }
    },
    {
      path: "/information",
      name: "promotionList",
      component: () => import("./components/promotion/PromotionList.vue"),
      meta: { layout: "default" }
    },
    {
      path: "/information-detail/:id",
      name: "promotion-detail",
      component: () => import("./components/promotion/PromotionDetail.vue"),
      meta: { layout: "default" }
    },
    // { path: "/city/:id", name: "city-detail", component: CityDetail, meta: { layout: "default" } },

    // public order and delivery
    // { path: "/public-login", component: PublicLogin, meta: { layout: "public" } },
    {
      path: "/order",
      redirect: "/home"
    },
    {
      path: "/product",
      redirect: "/product-list"
    },
    {
      path: "/home",
      component: () => import("./components/orderDelivery/Home.vue"),
      meta: { layout: "public" }
    },
    {
      path: "/product-list",
      component: () => import("./components/orderDelivery/ProductList.vue"),
      meta: { layout: "public" }
    },
    {
      path: "/product/:id",
      component: () => import("./components/orderDelivery/ProductDetail.vue"),
      meta: { layout: "public" }
    },
    {
      path: "/cart",
      component: () => import("./components/orderDelivery/CartList.vue"),
      meta: { layout: "public" }
    },
    {
      path: "/favorite",
      component: () => import("./components/orderDelivery/FavList.vue"),
      meta: { layout: "public" }
    },
    {
      path: "/order-tracking",
      component: () => import("./components/orderDelivery/orderTracking.vue"),
      meta: { layout: "public" }
    },
    {
      path: "/category",
      component: () => import("./components/orderDelivery/CategoryList.vue"),
      meta: { layout: "public" }
    },
    {
      path: "/branch",
      component: () => import("./components/orderDelivery/AboutUs.vue"),
      meta: { layout: "public" }
    },

    {
      path: "/contact-us",
      component: () => import("./components/orderDelivery/ContactUs.vue"),
      meta: { layout: "public" }
    },
    {
      path: "/our-services",
      component: () => import("./components/orderDelivery/OurServices.vue"),
      meta: { layout: "public" }
    },
    {
      path: "/service-info",
      name: "ServiceInfo",
      component: () => import("./components/orderDelivery/ServiceInfo.vue"),
      meta: { layout: "public" },
      props: (route) => ({
        ...route.params
      })
    },
    {
      path: "/promotion-events",
      component: () => import("./components/orderDelivery/Promotion&Events.vue"),
      meta: { layout: "public" }
    },


  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("accessToken");
  const user = JSON.parse(localStorage.getItem("user"));

  if (authRequired && !loggedIn) {
    if (to.meta.layout == "public") next();
    else return next("/login");
  } else if (!authRequired && loggedIn) {
    return next("/"); //prevent login reloading event
  } else if (authRequired && loggedIn) {
    if (to.meta.layout == "default" && user.role.roleName == "CUSTOMER") {
      return next("/order")
    }
  }

  next();
});
