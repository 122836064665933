import axios from "../axios-auth";

export const systemSettingService = {
    getSettings,
    updateSetting,
    getSettingById,
    getSystemSetting
};

function getSettings(searchParams, page, size, sortedBy) {
    let url = '';
    if (!page) {
        page = 0;
    } else {
        page -= 1;
    }
    if (!size) {
        size = 50;
    }
    if (sortedBy == 'null,asc') {
        url = "/system-settings?page=" + page + "&size=" + size;
    } else {
        url = "/system-settings?page=" + page + "&size=" + size + "&sort=" + sortedBy;
    }
    if (searchParams.name) {
        url += "&name=" + searchParams.name;
    }
    if (searchParams.value) {
        url += "&value=" + searchParams.value;
    }
    return axios.get(url);
}

function updateSetting(setting) {
    if (setting.id) {
        //edit
        return axios.put("/auth/system-setting", setting);
    } else {
        // create new
        return axios.post("/auth/system-setting", setting);
    }
}

function getSettingById(id) {
    return axios.get("/auth/system-setting/" + id);
}

function getSystemSetting() {
    return axios.get("/system-settings")
}