<template>
  <v-container fluid full-height grid-list-md>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSave">
      <v-layout row wrap>
        <v-flex xs6>
          <div class="text-xs-left">
            <v-breadcrumbs :items="breadcrumbs" divider=">">
              <template slot="item" slot-scope="props">
                <router-link
                  :to="'/'+props.item.to"
                  :disabled="props.item.disabled"
                  v-bind:class="{ 'v-breadcrumbs__item v-breadcrumbs__item--disabled': props.item.disabled }"
                >{{props.item.text}}</router-link>
              </template>
            </v-breadcrumbs>
          </div>
        </v-flex>
        <v-flex xs6>
          <div class="text-xs-right">
            <v-btn outline color="primary" @click="goBack">
              Go Back
              <v-icon right>arrow_back</v-icon>
            </v-btn>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-layout row wrap class="pa-3">
              <v-flex sm12>
                <v-text-field
                  name="TownshipCode"
                  label="Township Code"
                  v-model="township.code"
                  :rules="[() => !!township.code || 'Township code is required']"
                ></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  name="TownshipName"
                  label="Township Name"
                  v-model="township.name"
                  :rules="[() => !!township.name || 'Township name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  name="City"
                  label="Choose City"
                  @click="dialog = true"
                  v-model="selectedCity.name"
                  :rules="[() => !!selectedCity.name || 'City  is required']"
                ></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  name="TownshipName"
                  label="Enter Delivery Fee"
                  v-model="township.deliveryFee"
                  :rules="[() => !!township.deliveryFee || 'Delivery Fee is required']"
                ></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-text-field name="description" label="Description" v-model="township.description"></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-switch
                  :label="`${township.deliveryAvailable?'Delivery Available': 'Delivery Unavailable'}`"
                  :value="township.deliveryAvailable"
                  v-model="township.deliveryAvailable"
                ></v-switch>
              </v-flex>
              <v-flex sm12>
                <v-switch
                  :label="`Status is ${statusFlag?'Active': 'Inactive'}`"
                  :value="statusFlag"
                  v-model="statusFlag"
                ></v-switch>
              </v-flex>
              <v-flex sm12>
                <div class="text-xs-center">
                  <v-btn color="error" @click="clear">
                    Reset
                    <v-icon right>clear</v-icon>
                  </v-btn>
                  <v-btn color="primary" :loading="saveLoading" :disabled="!valid" type="submit">
                    Save
                    <v-icon right>save</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-form>

    <v-layout row wrap justify-center>
      <v-dialog v-model="dialog" max-width="700">
        <v-flex xs12>
          <v-card>
            <v-layout row wrap class="pa-3">
              <v-flex sm3>
                <v-text-field
                  name="townshipcode"
                  label="Township Code"
                  type="text"
                  v-model="queryParams.code"
                ></v-text-field>
              </v-flex>
              <v-flex sm3 offset-sm1>
                <v-text-field
                  name="townshipName"
                  label="Township Name"
                  type="text"
                  v-model="queryParams.name"
                ></v-text-field>
              </v-flex>
              <v-flex sm3 offset-sm1 pt-2>
                <v-btn outline color="primary" @click="onSearch">
                  Search
                  <v-icon right>search</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="cityList"
              class="elevation-1"
              :loading="showListLoading"
              :total-items="totalRows"
              :rows-per-page-items="rowsPerPageItems"
              :pagination.sync="pagination"
            >
              <template slot="items" slot-scope="props">
                <td>
                  <v-flex @click="setCity(props.item)">
                    <v-btn color="primary" flat>{{ props.item.code }}</v-btn>
                  </v-flex>
                </td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.description }}</td>
                <td>{{ props.item.updatedDateInMilliSeconds | format-ms-date }}</td>
                <td>{{ props.item.updatedBy }}</td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { Township } from "../../models/model";
import { cityService } from "../../services/city-service";
import { townshipService } from "../../services/township-services";

export default {
  data: () => ({
    showListLoading: false,
    rowsPerPageItems: [25, 50, 100],
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 50,
      sortBy: "name",
      totalItems: 0
    },
    queryParams: {
      name: "",
      code: ""
    },
    totalRows: 0,
    cityList: [],
    township: new Township(),
    oldTownship: new Township(),
    // statusFlag: true,
    breadcrumbs: [
      {
        text: "Township",
        disabled: false,
        to: "townships"
      },
      {
        text: "Township Detail",
        disabled: true
      }
    ],
    headers: [
      { text: "City Code", align: "left", value: "code" },
      { text: "City Name", value: "name" },
      { text: "Description", value: "description" },
      { text: "Updated Date", value: "updatedDate" },
      { text: "Updated By", value: "updatedBy" }
    ],
    dialog: false,
    valid: true,
    saveLoading: false,
    statusFlag: true,
    selectedCity: [],
    oldSelectedCity: [],
    responseError: ""
  }),
  watch: {
    pagination: {
      handler() {
        this.getCityList();
      },
      deep: true
    }
  },
  created() {
    const id = this.$route.params.id;
    if (id == "new") {
      // new Township
    } else {
      this.getTownshipDetail(id);
    }
  },
  mounted() {
    this.getCityList();
  },
  methods: {
    goBack() {
      this.$router.push("/townships");
    },
    clear() {
      this.$refs.form.resetValidation();
      if (!this.township.id) {
        this.township = new Township();
        this.selectedCity = [];
      } else {
        this.township = Object.assign({}, this.oldTownship);
        this.selectedCity = this.oldSelectedCity;
        this.statusFlag = this.township.status == "ACTIVE" ? true : false;
      }
    },
    onSave() {
      if (this.$refs.form.validate()) {
        this.saveLoading = true;
        this.township.status = this.statusFlag ? "ACTIVE" : "INACTIVE";
        let townshipObj = {
          id: this.township.id ? this.township.id : "",
          name: this.township.name,
          code: this.township.code,
          status: this.township.status,
          cityId: this.township.cityId,
          deliveryFee: this.township.deliveryFee,
          description: this.township.description,
          deliveryAvailable: this.township.deliveryAvailable
        };
        townshipService
          .saveTownship(townshipObj)
          .then(res => {
            console.log(res);
            this.goBack();
          })
          .catch(err => {
            console.log(err);
            if (err.response.data) {
              this.responseError = err.response.data.message;
            }
            this.saveLoading = false;
          });
      }
    },
    getCityList() {
      this.showListLoading = true;
      let order = this.pagination.descending ? "desc" : "asc";
      cityService
        .getCityList(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        )
        .then(res => {
          if (res.data) {
            this.cityList = res.data.cityList;
            this.totalRows = res.data.totalElements;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showListLoading = false;
        });
    },
    setCity(city) {
      this.selectedCity = city;
      this.township.cityId = this.selectedCity.id;
      this.dialog = false;
      this.queryParams = {};
      this.getCityList();
    },
    onSearch() {
      this.queryParams.page = this.pagination.page;
      this.queryParams.size = this.pagination.rowsPerPage;
      this.getCityList();
    },
    getTownshipDetail(id) {
      townshipService
        .getTownshipDetail(id)
        .then(res => {
          let tempObj = res.data;
          let id = tempObj.cityId;
          this.getCityById(id).then(() => {
            this.township = res.data;
            this.statusFlag = this.township.status == "ACTIVE" ? true : false;
          });
          this.oldTownship = Object.assign({}, res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getCityById(id) {
      return new Promise((resolve, reject) => {
        cityService
          .getCityDetail(id)
          .then(res => {
            this.selectedCity = res.data;
            this.oldSelectedCity = Object.assign({}, res.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};
</script>