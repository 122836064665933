<template>
    <v-container fluid full-height grid-list-md>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSave">
            <v-layout row wrap>
                <v-flex xs6>
                    <div class="text-xs-left">
                        <v-breadcrumbs :items="breadcrumbs" divider=">">
                            <template slot="item" slot-scope="props">
                                <router-link :to="'/'+props.item.to" :disabled="props.item.disabled"
                                    v-bind:class="{ 'v-breadcrumbs__item v-breadcrumbs__item--disabled': props.item.disabled }">
                                        {{props.item.text}}
                                </router-link>
                            </template>
                        </v-breadcrumbs>
                    </div>
                </v-flex>
                <v-flex xs6>
                    <div class="text-xs-right">
                        <v-btn outline color="primary" @click="goBack">
                            Go Back
                            <v-icon right>arrow_back</v-icon>
                        </v-btn>
                    </div>
                </v-flex>
                <v-flex xs6 offset-sm3>
                    <v-card>
                        <v-layout row wrap class="pa-3">
                            <v-flex sm12 v-if="responseError">
                                <span class="red--text">{{responseError}}</span>
                            </v-flex>                
                            <v-flex sm12>
                                <v-select
                                        name = "cashType"
                                        label="Cash Type"
                                        :items="cashTypeList"
                                        v-model="counterCash.cashType"
                                        item-text="label"
                                        item-value="value"
                                        :rules="[
                                                () => !!counterCash.cashType || ' Please Select Cash Type'
                                        ]">
                                </v-select>
                           </v-flex>
                           <v-flex sm12>
                                <v-select
                                        :items="counterList"
                                        v-model="counterCash.counterId"
                                        label="Choose Counter..."
                                        item-text="name"
                                        item-value="id"
                                        menu-props="auto"
                                        :rules="[
                                                    () => !!counterCash.counterId || ' Please Select Counter '
                                                ]">
                                </v-select>
                           </v-flex>
                           <v-flex sm12>
                                <v-text-field
                                        name="amount"
                                        label="amount"
                                        min = 0
                                        oninput="this.value = Math.abs(this.value)"
                                        v-model="counterCash.amount"
                                        type="number"
                                        :rules="[
                                                    () => !!counterCash.amount || 'Amount is required',
                                                ]">
                                </v-text-field>
                            </v-flex>
                            <v-flex sm12>
                                <v-textarea 
                                    name="description" 
                                    label="Description" 
                                    v-model="counterCash.description">
                                </v-textarea>
                            </v-flex>
                            <v-flex sm12>
                                <div class="text-xs-center">
                                    <v-btn color="error" @click="clear">
                                        Reset
                                        <v-icon right>clear</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" :loading="saveLoading" :disabled="!valid" type="submit">
                                        Save
                                        <v-icon right>save</v-icon>
                                    </v-btn>
                                </div>
                            </v-flex>
                       </v-layout>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>
<script>
    import { counterCashService } from "../../services/counter-cash-service";
    import { CounterCash } from "../../models/model";
    import VSwitch from "vuetify/lib/components/VSwitch/VSwitch";
    import { counterService } from "../../services/counter-service";

export default {
        name: "CounterCashDetail",
        components: {VSwitch},
        data: () => ({
            openDialog: false,
            valid: true,
            modalValid: true,
            breadcrumbs: [
                    {
                        text: 'CounterCashs',
                        disabled: false,
                        to: 'counter-cashs'
                        },
                    {
                        text: 'Counter Cash Detail',
                        disabled: true
                        }
                ],
        counterCash: new CounterCash(),
        oldCounterCash: new CounterCash(),
        saveLoading: false,
        responseError: '',
        modalError: '',
        counterList: [],
        cashTypeList: [
                { label: 'Float', value: 'FLOAT'},
                { label: 'Pick Up', value: 'PICK_UP' }
            ],
        modalTax: new CounterCash(),
    }),
created() {
    this.getCountersByShopId();
        const id = this.$route.params.id;
        if (id == "new") {
        // new stock
        } else {
            this.getCounterCashDetail(id);
        }
},
methods: {
    getCountersByShopId() {
        let user = JSON.parse(localStorage.getItem("user"));
             counterService
             .getCountersByShopId(user.shopId)
             .then(res => {
                let tempList = res.data;
                for (let temp of tempList) {
                   let counter = {
                        id: temp.id,
                        name: temp.name
                };
                this.counterList.push(counter);
             }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getCounterCashDetail(id){
            counterCashService
            .getCounterCashDetail(id)
            .then(res => {
                this.counterCash = res.data;
                this.oldCounterCash = Object.assign({}, res.data);
                })
                .catch(err => {
                    console.log(err);
                })
        },
        onSave() {
                if (this.$refs.form.validate()) {
                    let user = JSON.parse(localStorage.getItem("user"));
                    this.saveLoading = true;
                    let counterCashObj = {
                        id: this.counterCash.id ? this.counterCash.id : '',
                        cashType: this.counterCash.cashType,
                        amount: this.counterCash.amount,
                        description: this.counterCash.description,
                        shopId : user.shopId,
                        counterId: this.counterCash.counterId
                };
                    counterCashService.saveCounterCash(counterCashObj)
                        .then(res => {
                            console.log(res);
                            this.goBack();
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.data) {
                                this.responseError = err.response.data.message;
                            }
                            this.saveLoading = false;
                        });
                }
          },
          clear() {
                this.$refs.form.resetValidation();
                if (!this.counterCash.id) {
                    this.counterCash = new CounterCash();
                }
                else {
                    this.counterCash = Object.assign({}, this.oldCounterCash);
                }

                this.responseError = '';
            },
            goBack() {
                this.$router.push('/counter-cashs');
            } 

         },
     }
</script>
