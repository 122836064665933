<template>
  <v-container fluid full-height>
    <v-layout row wrap>
    
      <v-flex xs12>
        <div>
          <apexchart height="300" :options="options" :series="series"></apexchart>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-flex mt-2>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs4 pl-2 pt-2>
                  <span class="mr-3">Chart Type:</span>
                  <v-btn-toggle v-model="chartType" mandatory>
                    <v-btn flat value="area">
                      <span class="text-capitalize">Area Chart</span>
                    </v-btn>
                    <v-btn flat value="bar">
                      <span class="text-capitalize">Bar Chart</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
                <v-flex xs3>
                  <v-menu
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      v-model="queryParams.fromDate"
                      label="From Date"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                    <v-date-picker
                      v-model="queryParams.fromDate"
                      :min="minFromDate"
                      :max="queryParams.toDate"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs3>
                  <v-menu
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      v-model="queryParams.toDate"
                      label="To Date"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                    <v-date-picker
                      v-model="queryParams.toDate"
                      :min="queryParams.fromDate"
                      :max="today"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs2 pt-2>
                  <v-btn outline color="primary" @click="onSearch">
                    Search
                    <v-icon right>search</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-flex xs12 class="mt-1">
                <v-card>
                  <v-data-table
                    :headers="headers"
                    :items="visitorList"
                    :rows-per-page-items="rowsPerPageItems"
                    :pagination.sync="pagination"
                    class="elevation-1"
                  >
                    <template slot="items" slot-scope="props">
                      <td>{{ props.item.createdDateInMilliSeconds | ymd-format }}</td>
                      <td>{{ props.item.count }}</td>
                      <td>{{ props.item.publicViewer }}</td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-flex>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { commonService } from "../../services/common-service";
import { userService } from "../../services/user-service";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Dashboard",
  components: { apexchart: VueApexCharts },
  data() {
    return {
      queryParams: {
        fromDate: commonService.formatDate(
          new Date(new Date().setMonth(new Date().getMonth() - 1)),
          "YYYY-MM-DD"
        ),
        toDate: commonService.formatDate(new Date(), "YYYY-MM-DD")
      },
      today: commonService.formatDate(new Date(), "YYYY-MM-DD"),
      visitorList: [],
      headers: [
        {
          text: "Date",
          align: "left",
          value: "createdDateInMilliSeconds",
          sortable: false
        },
        {
          text: "Total Count",
          align: "left",
          value: "count",
          sortable: false
        },
        {
          text: "Public Viewer",
          align: "left",
          value: "publicViewer",
          sortable: false
        }
      ],
      totalRows: 0,
      rowsPerPageItems: [7, 15, 30, 60, 120],
      pagination: {
        rowsPerPageItems: 2
      },
      options: {
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
          curve: "smooth"
        },
        title: {
          text: "Visitor Traffic",
          align: "left"
        },
        chart: {
          id: "judo-website-traffic",
          // type: "area", // bar
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 0.8,
            stops: [0, 50, 100]
          }
        },
        xaxis: {
          type: "datetime"
          // labels: {
          //   format: "yyyy-MM-dd"
          // }
        }
      },
      series: [],
      chartType: "area",
      minFromDate: commonService.formatDate(
        new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        "YYYY-MM-DD"
      )
    };
  },
  filters: {
    "ymd-format": function(value) {
      if (value) {
        return moment.unix(value / 1000).format("YYYY-MM-DD");
      }
    }
  },
  watch: {
    chartType: {
      handler(value) {
        this.prepareChartData(value);
      }
    }
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    visitorCount() {
      userService
        .visitorCount(this.queryParams)
        .then(res => {
          this.visitorList = res.data.visitorCountList;
          this.totalRows = res.data.totalElements;
        })
        .then(() => {
          this.prepareChartData(this.chartType);
        })
        .catch(err => {
          console.log(err);
        });
    },
    onSearch() {
      let startDate = new Date(this.queryParams.fromDate);
      let endDate = new Date(this.queryParams.toDate);
      if (startDate > endDate) {
        console.log("Invalid Date Interval");
      } else this.visitorCount();
    },
    prepareChartData(type) {
      let dataItem = [];
      // let seriesData_totalCount = [];
      this.series = [];

      let startDate = new Date(this.queryParams.fromDate);
      let endDate = new Date(this.queryParams.toDate);

      let dates = this.getDates(startDate, endDate); // get dates between startDate & endDate

      let formatedDateList = []; // to show in chart

      // init traffic daily data - set 0
      dates.forEach(function(date) {
        dataItem = [];
        dataItem.push(commonService.formatDate(new Date(date), "YYYY-MM-DD"));
        dataItem.push(0);
        formatedDateList.push(dataItem);
      });

      // set counted traffic daily data to formatedDateList
      let idx = -1;
      let _date = "";
      this.visitorList.forEach(i => {
        _date = commonService.formatDate(
          new Date(i.createdDateInMilliSeconds),
          "YYYY-MM-DD"
        );
        idx = formatedDateList.findIndex(arr => arr[0] == _date);
        formatedDateList[idx][1] = i.count;
      });

      setTimeout(() => {
        this.series.push({
          name: "Total",
          type: type,
          data: formatedDateList
        });
      }, 80);
    },
    // Returns an array of dates between the two dates
    getDates(startDate, endDate) {
      var dates = [],
        currentDate = startDate,
        addDays = function(days) {
          var date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    }
  }
};
</script>
