import axios from "../axios-auth";

export const cityService = {
  getCityList,
  saveCity,
  getCityDetail,
  getCities, // for public
  getCityByStatus 
};

function getCityList(searchParams, page, size, sortedBy) {
  let url = '';
  if (!page) {
    page = 0;
  }
  else {
    page -= 1;
  }
  if (!size) {
    size = 50;
  }
  if (sortedBy == 'null,asc') {
    url = '/auth/all-city?page=' + page + '&size=' + size;
  } else {
    url = '/auth/all-city?page=' + page + '&size=' + size + '&sort=' + sortedBy;
  }
  if (searchParams.name) {
    url += '&name=' + searchParams.name;
  }
  if (searchParams.code) {
    url += '&code=' + searchParams.code;
  }
  return axios.get(url);
}

function saveCity(city) {
  if (!city.id) {
    // new city
    return axios.post("/auth/city", city);
  } else {
    // edit
    return axios.put("/auth/city", city);
  }
}

function getCityDetail(id) {
  return axios.get('/auth/city/' + id);
}

// for public
function getCities() {
  return axios.get('/cities');
}

function getCityByStatus(status) {
  return axios.get('/city-by-status/' + status);
}
