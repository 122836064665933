<template>
  <div style="background-color: rgba(183, 243, 255, 0.15)">
    <v-toolbar color="white" app fixed>
      <v-toolbar-title class="ml-0 pr-3 pt-1">
        <div @click="goToHome" class="pointer" v-if="systemSetting.logo">
          <img
            v-if="systemSetting.logo.value"
            style="height: 48px"
            :src="systemSetting.logo.value"
          />
          <img
            v-else
            style="height: 48px"
            src="../../assets/images/ayesaeLogo.jpg"
          />
        </div>
      </v-toolbar-title>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn flat to="/"> <v-icon color="#0078bb">home</v-icon></v-btn>
        <v-btn flat to="/category">Our Products</v-btn>
        <v-btn flat to="/our-services">Our Services</v-btn>
        <v-btn flat to="/promotion-events">Information</v-btn>
        <!-- <v-btn flat to="/order-tracking"> Order Tracking </v-btn> -->
        <v-btn flat to="/branch">Branches</v-btn>
        <v-btn flat to="/contact-us"> Contact Us </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <!-- search -->
      <v-text-field
        class="hidden-sm-and-down"
        hide-details
        append-icon="search"
        solo
        single-line
        placeholder="Search"
        v-model="searchText"
        @keyup.enter="search"
      ></v-text-field>

      <!-- mobile -->
      <v-toolbar-items class="hidden-md-and-up">
        <v-btn flat icon @click="mobileBottomSearch = true">
          <v-icon>search</v-icon>
        </v-btn>
        <transition>
          <v-btn icon @click="mobileBottomMenu = true">
            <v-slide-y-transition>
              <v-icon :color="menuIconColor">{{ menuIconValue }}</v-icon>
            </v-slide-y-transition>
          </v-btn>
        </transition>
      </v-toolbar-items>
      <!-- end mobile -->

      <v-toolbar-items class="hidden-sm-and-down">
        <!-- <v-btn flat to="/favorite">
          <v-icon>favorite</v-icon>
        </v-btn>
        <v-btn flat to="/cart">
          <v-badge color="success" right overlap>
            <template v-slot:badge>
              <span>{{ cartList.length }}</span>
            </template>
            <v-icon>shopping_cart</v-icon>
          </v-badge>
        </v-btn> -->
        <!-- <v-btn flat @click="login" v-if="!loggedIn">
          Login
          <v-icon right>person</v-icon>
        </v-btn>
        <v-menu
          offset-y
          origin="center center"
          :nudge-bottom="10"
          transition="scale-transition"
          v-if="loggedIn"
        >
          <v-btn flat slot="activator">
            {{ username }}
            <v-icon right>person</v-icon>
          </v-btn>
          <v-list class="pa-0">
            <v-list-tile @click="logout()">
              <v-list-tile-action>
                <v-icon>settings_power</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Logout</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-menu>-->
      </v-toolbar-items>
    </v-toolbar>
    <v-content>
      <router-view
        @addCart="addCart"
        @addFav="addFav"
        @updateCart="updateCart"
        @updateFavList="updateFavList"
        @IsCustomerLoggedIn="IsCustomerLoggedIn"
        :cartList="cartList"
        :favList="favList"
        :deliveryFeeLimit="systemSetting.deliveryFeeLimit"
        :deliveryFeeStatus="systemSetting.deliveryFeeStatus"
        :deliveryFeeInfo="systemSetting.deliveryFeeInfo"
        :deliveryDateLimit="systemSetting.deliveryDateLimit"
        :categories="systemSetting.categoryList"
        :address="systemSetting.address"
        :mobile="systemSetting.phoneNo"
        :hotline="systemSetting.hotlineValue"
        :socialLinks="systemSetting.socialLinksValue"
      ></router-view>
    </v-content>
    <v-container grid-list-xs id="contact">
      <v-layout row wrap justify-center>
        <v-flex :class="systemSetting.footerCols" pb-3>
          <v-flex title font-weight-bold text-xs-center mb-3>CONTACT US</v-flex>
          <v-layout row>
            <v-flex :class="contactClass">
              <v-icon size="18">location_on</v-icon>
              {{ systemSetting.address.value }}
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex :class="contactClass">
              <v-icon size="18">call</v-icon>
              {{ systemSetting.phoneNo.value }}
            </v-flex>
          </v-layout>
          <v-flex></v-flex>
        </v-flex>
        <v-flex
          :class="systemSetting.footerCols"
          pb-3
          v-if="systemSetting.socialLinksStatus"
        >
          <v-flex title font-weight-bold text-xs-center mb-3
            >SOCIAL LINKS</v-flex
          >
          <v-layout row wrap justify-center>
            <v-flex
              :class="socialLinksClass"
              v-for="link in systemSetting.socialLinksValue"
              :key="link.link"
              xs7
            >
              <v-icon size="18">{{
                link.name == "link"
                  ? "fas fa-external-link-square-alt"
                  : "fab fa-" + link.name + "-square"
              }}</v-icon>
              <span style="display: inline-block; padding-left: 5px">
                <a :href="link.link">{{ link.name | capitalize }}</a>
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          :class="systemSetting.footerCols"
          pb-3
          v-if="systemSetting.openingTimeStatus"
        >
          <v-flex title font-weight-bold text-xs-center mb-3
            >OPENING TIME</v-flex
          >
          <v-layout row justify-center wrap>
            <v-flex :class="openingTimeClass">
              <!-- <v-flex v-for="time in systemSetting.openingTimeValue" :key="time">{{time}}</v-flex> -->
              {{ systemSetting.openingTimeValue }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          :class="systemSetting.footerCols"
          pb-3
          v-if="systemSetting.hotlineStatus"
        >
          <v-flex title font-weight-bold text-xs-center mb-3>HOTLINE</v-flex>
          <v-layout row wrap>
            <v-flex :class="hotlineClass">
              <v-flex
                v-for="hline in systemSetting.hotlineValue"
                :key="hline.name"
                >{{ hline.name ? hline.name + " :" : "" }}
                {{ hline.phone }}</v-flex
              >
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-footer height="auto">
      <v-flex xs12>
        <v-card flat tile color="#FAFAFA" class="text-xs-center">
          <v-divider></v-divider>
          <v-card-text>
            Copyright
            {{
              systemSetting.copyright.year
                ? systemSetting.copyright.year
                : currentYear
            }}
            <strong>
              <template v-if="systemSetting.copyright.text">
                <a
                  v-if="systemSetting.copyright.link"
                  style="text-decoration: none"
                  :href="systemSetting.copyright.link"
                  target="_blank"
                >
                  {{ systemSetting.copyright.text }}
                </a>
                <template v-else>
                  {{ systemSetting.copyright.text }}
                </template>
              </template>
              <template v-else>
                <a
                  style="text-decoration: none"
                  href="http://www.cyberkingict.com/"
                  target="_blank"
                >
                  <img
                    style="height: 24px; margin-bottom: -6px"
                    src="@/assets/images/header-logo.png"
                  />
                  ERP Solutions. All rights reserved.
                </a>
              </template>
            </strong>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-footer>
    <!-- snackbar -->
    <v-snackbar v-model="snackbar" :bottom="true" :timeout="1500">
      {{ snackbarText }}
      <v-btn flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- mobile -->
    <v-bottom-sheet v-model="mobileBottomSearch">
      <v-card>
        <v-card-text>
          <v-container>
            <v-flex>
              <v-text-field
                autofocus
                hide-details
                append-icon="search"
                solo
                single-line
                placeholder="Search"
                v-model="searchText"
                @keyup.enter="search"
              ></v-text-field>
            </v-flex>
          </v-container>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="mobileBottomMenu">
      <v-list>
        <!-- cart -->
        <!-- <v-subheader>Cart</v-subheader>
        <v-list-tile
          :to="{ path: '/favorite' }"
          @click="mobileBottomMenu = false"
        >
          <v-list-tile-avatar>
            <v-icon>favorite</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Favorite</v-list-tile-title>
        </v-list-tile>
        <v-list-tile
          :to="{ path: '/cart' }"
          @click="mobileBottomMenu = false"
          color="success"
          primary
        >
          <v-list-tile-avatar>
            <v-badge color="success" right overlap>
              <template v-slot:badge>
                <span>{{ cartList.length }}</span>
              </template>
              <v-icon>shopping_cart</v-icon>
            </v-badge>
          </v-list-tile-avatar>
          <v-list-tile-title>Shopping Cart</v-list-tile-title>
        </v-list-tile> -->
        <!-- menu -->
        <v-subheader>Menu</v-subheader>
        <v-list-tile :to="{ path: '/' }" @click="mobileBottomMenu = false">
          <v-list-tile-avatar>
            <v-icon>home</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Home</v-list-tile-title>
        </v-list-tile>
        <v-list-tile
          :to="{ path: '/category' }"
          @click="mobileBottomMenu = false"
        >
          <v-list-tile-avatar>
            <v-icon>widgets</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Our Products</v-list-tile-title>
        </v-list-tile>
        <v-list-tile
          :to="{ path: '/our-services' }"
          @click="mobileBottomMenu = false"
        >
          <v-list-tile-avatar>
            <v-icon>flash_on</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Our Services</v-list-tile-title>
        </v-list-tile>
        <v-list-tile
          :to="{ path: '/promotion-events' }"
          @click="mobileBottomMenu = false"
        >
          <v-list-tile-avatar>
            <v-icon>event_available</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Information</v-list-tile-title>
        </v-list-tile>

        <!-- <v-list-tile
          :to="{ path: '/order-tracking' }"
          @click="mobileBottomMenu = false"
        >
          <v-list-tile-avatar>
            <v-icon>track_changes</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Order Tracking</v-list-tile-title>
        </v-list-tile> -->
        <v-list-tile
          :to="{ path: '/branch' }"
          @click="mobileBottomMenu = false"
        >
          <v-list-tile-avatar>
            <v-icon>security</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Branches</v-list-tile-title>
        </v-list-tile>
        <v-list-tile
          :to="{ path: '/contact-us' }"
          @click="mobileBottomMenu = false"
        >
          <v-list-tile-avatar>
            <v-icon>forum</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Contact Us</v-list-tile-title>
        </v-list-tile>

        <!-- Customer -->
        <!-- <v-subheader>Customer</v-subheader>
        <v-list-tile v-if="loggedIn">
          <v-list-tile-avatar>
            <v-icon>account_circle</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>{{username}}</v-list-tile-title>
          <v-list-tile-action>
            <v-btn color="error" icon title="Logout" @click="logout">
              <v-icon dark>settings_power</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile v-else :to="{ path: '/public-login' }" @click="mobileBottomMenu=false">
          <v-list-tile-avatar>
            <v-icon>lock</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Login</v-list-tile-title>
        </v-list-tile>-->
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { systemSettingService } from "../../services/system-setting-service";
import { userService } from "../../services/user-service";

export default {
  data: () => ({
    cartList: [],
    favList: [],
    totAmount: 0,
    snackbar: false,
    snackbarText: "",
    searchText: "",
    mobileBottomSearch: false,
    mobileBottomMenu: false,
    menuIconValue: "menu", //add_shopping_cart
    menuIconColor: "",
    loggedIn: false,
    username: "",
    systemSetting: {
      logo: "",
      favicon: "",
      phoneNo: "",
      address: "",
      deliveryFee: "",
      deliveryDate: "",
      socialLinks: "",
      copyright: "",
      hotline: "",
      openingTime: "",
      categories: [],
      categoryList: [],
      promotion: {},
    },
    logo: {},
    contactClass: "",
    hotlineClass: "",
    openingTimeClass: "",
    socialLinksClass: "",
    currentYear: "",
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 50,
      sortBy: "name",
      totalItems: 0,
    },
    queryParams: {
      name: "",
      value: "",
    },
  }),

  watch: {
    "$route.query.search": {
      handler(search) {
        this.searchText = search;
      },
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  created() {
    if (this.$route.query.search) {
      this.searchText = this.$route.query.search;
      // this.search();
    }
    this.getSystemSetting();
  },
  mounted() {
    if (this.cartList.length > 0) this.calcAmount();
    // const user = JSON.parse(localStorage.getItem("user"));
    // if (user) {
    //   this.IsCustomerLoggedIn(user);
    // } else {
    //   this.loggedIn = false;
    //   this.username = "";
    // }
    this.visitCounter();
  },
  methods: {
    getSystemSetting() {
      let _setting = [];
      let order = this.pagination.descending ? "desc" : "asc";
      this.currentYear = new Date().getFullYear();
      systemSettingService
        .getSettings(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        )
        .then((res) => {
          _setting = res.data.systemSettingList;
        })
        .then(() => {
          this.systemSetting.logo = _setting.filter(
            (setting) => setting.name == "LOGO"
          )[0];
          this.logo = this.systemSetting.logo;

          this.systemSetting.favicon = _setting.filter(
            (setting) => setting.name == "FAVICON"
          )[0];
          var link =
            document.querySelector("link[rel*='icon']") ||
            document.createElement("link");
          link.type = "image/png";
          link.rel = "shortcut icon";
          link.href = this.systemSetting.favicon.value;
          document.getElementsByTagName("head")[0].appendChild(link);

          this.systemSetting.address = _setting.filter(
            (setting) => setting.name == "ADDRESS"
          )[0];

          this.systemSetting.phoneNo = _setting.filter(
            (setting) => setting.name == "PHONE_NO"
          )[0];

          this.systemSetting.deliveryFee = _setting.filter(
            (setting) => setting.name == "DELIVERY_FEE"
          )[0];

          this.systemSetting.deliveryDate = _setting.filter(
            (setting) => setting.name == "DELIVERY_DATE"
          )[0];
          this.systemSetting.socialLinks = _setting.filter(
            (setting) => setting.name == "SOCIAL_LINKS"
          )[0];

          this.systemSetting.copyright = _setting.filter(
            (setting) => setting.name == "COPYRIGHT"
          )[0];

          this.systemSetting.hotline = _setting.filter(
            (setting) => setting.name == "HOTLINE"
          )[0];

          this.systemSetting.openingTime = _setting.filter(
            (setting) => setting.name == "OPENING_TIME"
          )[0];

          this.systemSetting.categories = _setting.filter(
            (setting) => setting.name == "CATEGORY"
          )[0];

          this.systemSetting.promotion = _setting.filter(
            (setting) => setting.name == "PROMOTION"
          )[0];
        })
        .then(() => {
          let _col = 1;

          // social links
          let socialLinksValue = JSON.parse(
            this.systemSetting.socialLinks.value
          );
          this.systemSetting.socialLinksValue = socialLinksValue.links;

          if (socialLinksValue.status == "ACTIVE") {
            _col += 1;
            this.systemSetting.socialLinksStatus = true;
          } else this.systemSetting.socialLinksStatus = false;

          // opening time
          let openingTimeValue = JSON.parse(
            this.systemSetting.openingTime.value
          );
          this.systemSetting.openingTimeValue = openingTimeValue.schedule;

          if (openingTimeValue.status == "ACTIVE") {
            _col += 1;
            this.systemSetting.openingTimeStatus = true;
          } else this.systemSetting.openingTimeStatus = false;

          // hotline
          let hotlineValue = JSON.parse(this.systemSetting.hotline.value);
          this.systemSetting.hotlineValue = hotlineValue.phones;

          if (hotlineValue.status == "ACTIVE") {
            _col += 1;
            this.systemSetting.hotlineStatus = true;
          } else this.systemSetting.hotlineStatus = false;

          this.systemSetting.footerCols = "xs12 sm12 md4 lg4";
          this.contactClass = "text-xs-center";
          this.hotlineClass = "text-xs-center";
          this.openingTimeClass = "text-xs-center";
          this.socialLinksClass = "pl-5 mb-1 text-xs-left";
          this.systemSetting.footerCount = _col;

          // calc cols
          switch (_col) {
            case 1:
              this.systemSetting.footerCols = "xs12 sm12 md4 lg4";
              break;
            case 2:
              this.systemSetting.footerCols = "xs12 sm12 md6 lg6";
              break;
            case 3:
              this.systemSetting.footerCols = "xs12 sm12 md4 lg4";
              break;
            case 4:
              this.systemSetting.footerCols = "xs12 sm12 md3 lg3";
              break;

            default:
              break;
          }

          // extract data
          let copyrightValue = JSON.parse(this.systemSetting.copyright.value);
          if (copyrightValue) {
            this.systemSetting.copyright.year = copyrightValue.year;
            this.systemSetting.copyright.text = copyrightValue.text;
            this.systemSetting.copyright.link = copyrightValue.link;
          }

          let deliveryFeeValue = JSON.parse(
            this.systemSetting.deliveryFee.value
          );
          this.systemSetting.deliveryFeeStatus =
            deliveryFeeValue.status == "ACTIVE" ? true : false;
          this.systemSetting.deliveryFeeLimit = Number.parseInt(
            deliveryFeeValue.totalAmount
          );
          this.systemSetting.deliveryFeeInfo = deliveryFeeValue.info;

          this.systemSetting.deliveryDateLimit = Number.parseInt(
            this.systemSetting.deliveryDate.value
          );

          // category
          let _category = JSON.parse(this.systemSetting.categories.value);
          this.systemSetting.categoryList = _category.list;

          // promotion
          // let _promotionValue = JSON.parse(this.systemSetting.promotion.value);
          // this.systemSetting.promotion.name = _promotionValue.name;
          // this.systemSetting.promotion.description =
          //   _promotionValue.description;
          // this.systemSetting.promotion.imageUrl = _promotionValue.imageUrl;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addCart(item) {
      // change mobile menu icon
      this.menuIconValue = "add_shopping_cart";
      this.menuIconColor = "warning";

      const record = this.cartList.find((p) => p.id === item.id);
      if (!record) {
        item.qty = 1;
        this.cartList.push(item);
      } else {
        let foundIndex = this.cartList.findIndex((x) => x.id == item.id);
        this.cartList[foundIndex].qty++;
      }
      this.calcAmount();
      this.snackbarText = "Your product is added to cart.";
      this.snackbar = true;
      setTimeout(() => {
        // change mobile menu icon
        this.menuIconValue = "shopping_cart";
        this.menuIconColor = "success";
      }, 500);
      setTimeout(() => {
        this.menuIconValue = "menu";
        this.menuIconColor = "";
      }, 1000);
    },
    addFav(item) {
      const record = this.favList.find((p) => p.id === item.id);
      if (!record) {
        item.qty = 1;
        this.favList.push(item);
        this.snackbarText = "Item is successfully added to favorite.";
        this.snackbar = true;
      } else {
        this.snackbarText = "Item is already added to favorite.";
        this.snackbar = true;
      }
    },
    calcAmount() {
      this.totAmount = 0;
      this.cartList.forEach((item) => {
        if (item.isPromo && item.promotion.status === "ACTIVE") {
          item.price = item.promotionPrice * item.qty;
          this.totAmount += item.promotionPrice * item.qty;
        } else {
          item.price = item.unitPrice * item.qty;
          this.totAmount += item.unitPrice * item.qty;
        }
      });
    },
    updateCart(cartList) {
      this.cartList = cartList;
      this.calcAmount();
    },
    updateFavList(favList) {
      this.favList = favList;
    },
    // scrollToEnd() {
    //   var container = this.$el.querySelector("#contact");
    //   window.scrollTo(0, container.offsetTop);
    // },
    search() {
      this.$router.replace({
        path: "/product-list",
        query: { search: this.searchText },
      });
    },
    // login() {
    //   this.$router.push("/public-login");
    // },
    // logout() {
    //   localStorage.clear();
    //   this.loggedIn = false;
    //   window.history.go();
    // },
    IsCustomerLoggedIn(user) {
      this.loggedIn = true;
      this.username = user.username;
    },
    visitCounter() {
      let isPublic = true;
      userService.visitCounter(isPublic);
    },
    goToHome() {
      this.$router.push("/home");
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
