<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSubmit">
    <v-container fluid full-height grid-list-md>
      <v-layout row wrap>
        <v-flex xs6>
          <div class="text-xs-left">
            <v-breadcrumbs :items="breadcrumbs" divider=">">
              <template slot="item" slot-scope="props">
                <router-link
                  :to="'/' + props.item.to"
                  :disabled="props.item.disabled"
                  v-bind:class="{
                    'v-breadcrumbs__item v-breadcrumbs__item--disabled':
                      props.item.disabled,
                  }"
                  >{{ props.item.text }}</router-link
                >
              </template>
            </v-breadcrumbs>
          </div>
        </v-flex>
        <v-flex xs6>
          <div class="text-xs-right">
            <v-btn outline color="primary" @click="goBack">
              Go Back
              <v-icon right>arrow_back</v-icon>
            </v-btn>
          </div>
        </v-flex>
        <v-flex xs6 offset-sm3>
          <v-card>
            <v-layout row wrap class="pa-3">
              <v-flex sm12 v-if="responseError">
                <span class="red--text">{{ responseError }}</span>
              </v-flex>
              <v-flex sm12>
                <div class="dropbox">
                  <input
                    type="file"
                    @change="onFileChanged"
                    class="input-file"
                  />
                  <p v-if="!stock.imageUrl">
                    Drag your file(s) here to begin
                    <br />or click to browse
                  </p>
                  <img v-if="stock.imageUrl" :src="stock.imageUrl" />
                </div>
              </v-flex>

              <v-flex sm12>
                <v-text-field
                  name="barcode"
                  label="Barcode"
                  v-model="stock.barcode"
                  type="text"
                ></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  name="sku"
                  label="SKU"
                  v-model="stock.sku"
                  type="text"
                  :rules="rules.skuRules"
                ></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  name="stockName"
                  label="Stock Name"
                  v-model="stock.name"
                  type="text"
                  :rules="rules.stockNameRules"
                ></v-text-field>
              </v-flex>
              <v-flex sm7>
                <v-select
                  :items="categoryList"
                  v-model="stock.categoryName"
                  label="Choose Category..."
                  item-text="name"
                  item-value="name"
                  menu-props="auto"
                  :rules="rules.categoryNameRules"
                ></v-select>
              </v-flex>
              <v-flex sm5>
                <template>
                  <v-layout row justify-center>
                    <v-btn
                      flat
                      color="primary"
                      @click="showCategoryModel()"
                      style="float: right"
                    >
                      New Category
                      <v-icon right>add_circle</v-icon>
                    </v-btn>
                  </v-layout>
                </template>
              </v-flex>
              <!-- <v-flex sm12 v-if="!stock.combo">
                <v-text-field
                  name="unitPrice"
                  label="Unit Price"
                  min="0"
                  oninput="this.value = Math.abs(this.value)"
                  v-model="stock.unitPrice"
                  type="number"
                  :rules="rules.unitPriceRules"
                ></v-text-field>
              </v-flex>
              <v-flex sm12 v-if="!stock.combo">
                <v-text-field
                  label="Promotion Price"
                  min="0"
                  oninput="this.value = Math.abs(this.value)"
                  v-model="stock.promotionPrice"
                  type="number"
                  :rules="stock.isPromo ? rules.promoPriceRules : []"
                ></v-text-field>
              </v-flex>
              <v-flex sm12 v-if="!stock.combo">
                <v-select
                  :items="promotionList"
                  v-model="stock.promotionId"
                  label="Select Promotion"
                  item-text="name"
                  item-value="id"
                  :rules="stock.isPromo ? rules.requiredRules : []"
                ></v-select>
              </v-flex>
              <v-flex sm12 v-if="!stock.combo">
                <v-text-field
                  name="maxOrderQty"
                  label="Max Order Qty"
                  min="0"
                  oninput="this.value = Math.abs(this.value)"
                  v-model="stock.maxOrderQty"
                  type="number"
                  :rules="rules.maxOrderQtyRules"
                ></v-text-field>
              </v-flex> -->
              <v-flex sm12 v-if="isLoadedEditor">
                <p class="body-1">Description</p>
                <Editor
                  initialEditType="wysiwyg"
                  ref="toastuiEditor"
                  :options="editorOptions"
                  :initialValue="editorText"
                />
              </v-flex>
              <v-flex sm12 mt-5>
                <div>
                  <p class="title">
                    Additional Image
                    <v-btn
                      color="primary"
                      outline
                      small
                      @click="addItem()"
                      title="Add item (Max: 3)"
                    >
                      <v-icon small>add</v-icon>
                    </v-btn>
                  </p>
                </div>

                <v-layout row wrap>
                  <v-flex
                    x12
                    v-for="(image, index) in additionalImages"
                    :key="index"
                  >
                    <input
                      type="file"
                      @change="onImageFileChanged($event, index)"
                      accept="image/jpg, image/png, image/jpeg"
                    />
                    <v-flex pa-3 v-if="stock.id && image.imageUrl">
                      Image Url:
                      <v-btn color icon @click="deleteImage(image.id, index)">
                        <v-icon>clear</v-icon>
                      </v-btn>
                      <br />
                      <a :href="image.imageUrl" target="_blank">{{
                        image.imageUrl
                      }}</a>
                    </v-flex>
                    <!-- <v-img :src="photo.photoUrl" max-height="200px" contain /> -->
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex sm12>
                <v-switch
                  :label="`Status is ${statusFlag ? 'Active' : 'Inactive'}`"
                  :value="statusFlag"
                  v-model="statusFlag"
                ></v-switch>
                <!-- <v-switch
                  :label="`${stock.menu ? 'Menu' : 'Not Menu'}`"
                  :value="stock.menu"
                  v-model="stock.menu"
                ></v-switch> -->
                <v-switch
                  :label="`${stock.combo ? 'Combo' : 'Not Combo'}`"
                  :value="stock.combo"
                  v-model="stock.combo"
                ></v-switch>
                <!-- <v-switch
                  v-if="!stock.combo"
                  :label="`Promotion ${stock.isPromo ? 'Available' : 'Not Available'}`"
                 
                  v-model="stock.isPromo"
                ></v-switch> -->
              </v-flex>
              <v-flex xs12 v-if="stock.combo">
                <div>
                  <p class="title">
                    Stocks
                    <v-btn
                      color="primary"
                      outline
                      small
                      @click="stockListDialog = true"
                    >
                      <v-icon small>add</v-icon>
                    </v-btn>
                  </p>
                </div>
                <div>
                  <v-layout row wrap>
                    <v-flex
                      xs12
                      v-for="(stockcombo, idx) in stock.stockComboSet"
                      :key="stockcombo.childStockId"
                    >
                      <v-card>
                        <v-card-text>
                          <v-layout row wrap>
                            <v-flex xs10 pt-3>
                              <b>{{ stockcombo.childStock.sku }}</b>
                              | {{ stockcombo.childStock.name }}
                            </v-flex>
                            <v-flex xs2>
                              <v-btn
                                color="error"
                                icon
                                flat
                                @click="deleteStockItem(stockcombo.id, idx)"
                              >
                                <v-icon>clear</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>

              <v-flex sm12>
                <div class="text-xs-center">
                  <!-- <v-btn color="error" @click="clear" :loading="resetLoading">
                    Reset
                    <v-icon right>clear</v-icon>
                  </v-btn>-->
                  <v-btn color="primary" :loading="saveLoading" type="submit">
                    Save
                    <v-icon right>save</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <!----------------- For New Category Dialog --------------------->
      <v-dialog v-model="openDialog" persistent max-width="600px">
        <v-form
          ref="modalForm"
          v-model="modalValid"
          lazy-validation
          @submit.prevent="saveCategory"
        >
          <v-card>
            <v-card-title primary-title class="headline blue darken-2">
              <span class="white--text">Create New Category</span>
            </v-card-title>
            <v-divider></v-divider>
            <div class="ma-3">
              <v-flex sm12 v-if="modalError">
                <span class="red--text">{{ modalError }}</span>
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  name="name"
                  label="Category Name"
                  v-model="category.name"
                  type="text"
                  :disabled="category.id ? true : false"
                  :rules="[
                    () => !!category.name || 'Category name is required',
                  ]"
                  >required></v-text-field
                >
              </v-flex>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" flat @click.native="closeModal">
                Cancel
                <v-icon right>clear</v-icon>
              </v-btn>
              <v-btn color="primary" flat type="submit" :disabled="!modalValid">
                Save
                <v-icon right>save</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <StockListDialog
        :dialog="stockListDialog"
        @add="addStockItem($event)"
        @close="stockListDialog = false"
      ></StockListDialog>
    </v-container>
  </v-form>
</template>

<script>
import { stockService } from "../../services/stock-service";
import { categoryService } from "../../services/category-service";
import { commonService } from "../../services/common-service";
import { promotionService } from "../../services/promotion-service";
import VSwitch from "vuetify/lib/components/VSwitch/VSwitch";
import { Stock } from "../../models/model";
import { Category } from "../../models/model";
import { StockFormRules } from "../../helpers/validation-rules";
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
import StockListDialog from "./StockListDialog";

export default {
  name: "StockDetail",
  components: { VSwitch, Editor, StockListDialog },
  data: () => ({
    breadcrumbs: [
      {
        text: "Stocks",
        disabled: false,
        to: "stocks",
      },
      {
        text: "Stock Detail",
        disabled: true,
      },
    ],
    openDialog: false,
    stock: new Stock(),
    oldStock: {},
    category: new Category(),
    oldCategory: new Category(),
    modalValid: true,
    modalError: "",
    statusFlag: true,
    saveLoading: false,
    resetLoading: false,
    responseError: "",
    categoryList: [],
    valid: true,
    rules: new StockFormRules(),
    editorOptions: {
      hideModeSwitch: true,
      toolbarItems: ["bold", "italic", "ul", "ol"],
    },
    editorText: "",
    isLoadedEditor: false,
    additionalImages: [{ image: "" }, { image: "" }, { image: "" }],
    stockListDialog: false,
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 50,
      sortBy: "name",
    },
    promotionList: [],
  }),
  watch: {
    categoryList: function () {
      this.categoryList;
    },
    
  },
  created() {
    this.getAllCategories();

    const id = this.$route.params.id;
    if (id == "new") {
      this.isLoadedEditor = true;
      // new stock
    } else {
      this.getStockDetail(id);
    }
  },

  mounted() {
    this.getAllPromotion();
  },

  methods: {
    getStockDetail(id) {
      stockService
        .getStockDetail(id, false)
        .then((res) => {
          this.stock = res.data;
          if (this.stock.stockImages.length > 0) {
            this.additionalImages = this.stock.stockImages;
          }

          this.statusFlag = this.stock.status == "ACTIVE" ? true : false;
          this.oldStock = JSON.parse(JSON.stringify(res.data));
          this.editorText = this.stock.description;
          this.isLoadedEditor = true;
        })
        .catch((err) => {
          if (err.response.data) {
            this.responseError = err.response.data.message;
          }
        });
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.saveLoading = true;
        this.responseError = "";
        this.stock.description = this.$refs.toastuiEditor.invoke("getHtml");
        this.stock.status = this.statusFlag == true ? "ACTIVE" : "INACTIVE";
        if (!this.stock.image) {
          delete this.stock["image"];
        }
        if (!this.stock.imageUrl) {
          delete this.stock["imageUrl"];
        }
        if (!this.stock.id) {
          this.stock.stockImages = this.additionalImages;
        } else {
          this.stock.stockImages = this.additionalImages;
        }
        if (this.stock.combo) {
          this.stock.unitPrice = 0;
          this.stock.maxOrderQty = 0;
        }

        stockService
          .saveStock(this.stock)
          .then((res) => {
            console.log(res);
            this.saveLoading = false;
            this.goBack();
          })
          .catch((err) => {
            if (err.response.data) {
              this.responseError = err.response.data.message;
              this.saveLoading = false;
            }
          });
      }
    },
    showCategoryModel() {
      this.category = new Category();
      this.openDialog = true;
    },
    closeModal() {
      this.$refs.modalForm.resetValidation();
      this.modalError = "";
      this.openDialog = false;
    },
    saveCategory() {
      if (!this.$refs.modalForm.validate()) {
        return;
      }
      categoryService
        .saveCategory(this.category)
        .then(() => {
          this.getAllCategories();
          this.closeModal();
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data) {
            this.modalError = err.response.data.message;
            return;
          }
        });
    },
    getAllCategories() {
      categoryService
        .getAllCategories("ACTIVE")
        .then((res) => {
          this.categoryList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.stock.imageUrl = URL.createObjectURL(file); // to show preview image
      commonService.getBase64(file).then((data) => {
        this.stock.image = data;
      });
    },
    clear() {
      this.$refs.form.resetValidation();
      if (!this.stock.id) {
        this.statusFlag = true;
        this.stock = new Stock();
      } else {
        this.resetLoading = true;
        this.statusFlag = this.oldStock.status == "ACTIVE" ? true : false;
        this.stock = Object.assign({}, this.oldStock);
        this.isLoadedEditor = false;
        this.editorText = this.oldStock.description;
        setTimeout(() => {
          this.isLoadedEditor = true;
          this.resetLoading = false;
        }, 1000);
      }
      this.responseError = "";
    },
    goBack() {
      this.$router.push("/stocks");
    },
    async onImageFileChanged(event, index) {
      const file = event.target.files[0];
      // this.additionalImages[index].photoUrl = URL.createObjectURL(file); // to show preview image
      // commonService.getBase64(file).then((data) => {
      //   this.sponsorServiceList[index].logo = data;
      try {
        this.additionalImages[index].image = await this.getBase64(file);
      } catch (error) {
        console.log(error);
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result;
          resolve(encoded);
        };
        reader.onerror = (error) => reject(error);
      });
    },
    deleteImage(id, index) {
      if (!this.stock.deleteStockImageIds) this.stock.deleteStockImageIds = [];

      this.$delete(this.additionalImages, index);

      this.stock.deleteStockImageIds.push(id);
    },
    addItem() {
      if (this.additionalImages.length < 3) {
        this.additionalImages.push({ id: "", stockId: "", image: "" });
      }
    },
    addStockItem(item) {
      if (item.id === this.stock.id) return;

      let __isFound = "";
      let __isExisted = "";

      if (this.stock.id) {
        __isFound = this.stock.stockComboSet.find(
          (stockcombo) => stockcombo.childStockId === item.id
        );

        __isExisted = this.oldStock.stockComboSet.find(
          (stockcombo) => stockcombo.childStockId === item.id
        );
      }

      if (__isExisted) {
        // retrieve deleted id
        let __idx = this.stock.deleteStockComboIds.findIndex(
          (deleteStockComboId) => deleteStockComboId === __isExisted.id
        );
        this.$delete(this.stock.deleteStockComboIds, __idx);

        this.stock.stockComboSet.push(__isExisted);
      } else if (!__isFound) {
        this.stock.stockComboSet.push({
          id: "",
          stockId: "",
          childStockId: item.id,
          childStock: item,
        });
      }

      this.stockListDialog = false;
    },
    deleteStockItem(id, index) {
      if (!this.stock.deleteStockComboIds) this.stock.deleteStockComboIds = [];
      this.$delete(this.stock.stockComboSet, index);
      if (id) this.stock.deleteStockComboIds.push(id);

      if (this.stock.stockComboSet.length < 1) this.stock.combo = false;
    },
    async getAllPromotion() {
      let order = this.pagination.descending ? "desc" : "asc";
      try {
        const res = await promotionService.getPromotionList(
          {},
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        );
        this.promotionList = res.data.promotionList;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
</style>