<template>
  <v-container fluid full-height grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <div>
          <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-card>
          <!-- address -->
          <v-layout row wrap>
            <v-flex xs12>
              <v-card-title>
                <span class="title">{{ address.name }}</span>
              </v-card-title>
            </v-flex>
            <v-flex xs9 pl-3>
              <v-text-field
                name="name"
                v-model="address.value"
                :disabled="!isBtnClick.address"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn
                flat
                icon
                color="blue lighten-2"
                @click="isBtnClick.address = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                flat
                icon
                color="red"
                v-if="isBtnClick.address"
                @click="clear('add')"
              >
                <v-icon>clear</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.address"
                @click="save(address)"
                >Update</v-btn
              >
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <!-- logo -->
          <v-layout row wrap v-if="!isAdmin">
            <v-flex xs12>
              <v-card-title>
                <span class="title">{{ logo.name }}</span>
                <span class="pt-2 info--text"
                  >Please check your image size not too big</span
                >
              </v-card-title>
              <span v-if="this.errMessage" class="warning--text pl-3">{{
                this.errMessage
              }}</span>
            </v-flex>
            <v-flex xs9 pl-3>
              <!-- <v-text-field name="name" v-model="logo.value"></v-text-field> -->
              <div class="dropbox" style="width: 350px">
                <input
                  type="file"
                  @change="onFileChanged"
                  class="input-file"
                  :disabled="!isBtnClick.logo"
                />
                <p v-if="!logo.value">
                  Drag your file(s) here to begin
                  <br />or click to browse
                </p>
                <img v-if="logo.value" :src="logo.value" />
              </div>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn
                flat
                icon
                color="blue lighten-2"
                @click="isBtnClick.logo = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.logo"
                @click="save(logo)"
                >Update</v-btn
              >
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <!-- favicon -->
          <v-layout row wrap v-if="!isAdmin">
            <v-flex xs12>
              <v-card-title>
                <span class="title">{{ favicon.name }}</span>
                <span class="pt-2 info--text">Recommended size - 64*64</span>
              </v-card-title>
              <span v-if="this.errMessage" class="warning--text pl-3">{{
                this.errMessage
              }}</span>
            </v-flex>
            <v-flex xs9 pl-3>
              <div class="dropbox" style="width: 200px">
                <input
                  type="file"
                  @change="onFaviconChanged"
                  class="input-file"
                  :disabled="!isBtnClick.favicon"
                />
                <p v-if="!favicon.value">
                  Drag your file(s) here to begin
                  <br />or click to browse
                </p>
                <img v-if="favicon.value" :src="favicon.value" />
              </div>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn
                flat
                icon
                color="blue lighten-2"
                @click="isBtnClick.favicon = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.favicon"
                @click="save(favicon)"
                >Update</v-btn
              >
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <!-- phone number -->
          <v-layout row wrap>
            <v-flex xs12>
              <v-card-title>
                <span class="title">{{ phoneNo.name }}</span>
              </v-card-title>
            </v-flex>
            <v-flex xs9 pl-3>
              <v-text-field
                name="name"
                v-model="phoneNo.value"
                :disabled="!isBtnClick.phoneNo"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn
                flat
                icon
                color="blue lighten-2"
                @click="isBtnClick.phoneNo = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                flat
                icon
                color="red"
                v-if="isBtnClick.phoneNo"
                @click="clear('phNo')"
              >
                <v-icon>clear</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.phoneNo"
                @click="save(phoneNo)"
                >Update</v-btn
              >
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <!-- copyright -->
          <v-layout row wrap v-if="!isAdmin">
            <v-flex xs12>
              <v-card-title>
                <span class="title">{{ copyright.name }}</span>
              </v-card-title>
            </v-flex>
            <v-flex xs4 pl-3>
              <v-text-field
                name="name"
                label="year"
                v-model="copyright.year"
                :disabled="!isBtnClick.copyright"
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field
                name="name"
                label="company name"
                v-model="copyright.companyName"
                :disabled="!isBtnClick.copyright"
              ></v-text-field>
              <v-text-field
                label="Link"
                v-model="copyright.link"
                :disabled="!isBtnClick.copyright"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn
                flat
                icon
                color="blue lighten-2"
                @click="isBtnClick.copyright = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                flat
                icon
                color="red"
                v-if="isBtnClick.copyright"
                @click="isBtnClick.copyright = false"
              >
                <v-icon>clear</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.copyright"
                @click="save(copyright)"
                >Update</v-btn
              >
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <!-- delivery date -->
          <v-layout row wrap v-if="!isAdmin">
            <v-flex xs12>
              <v-card-title>
                <span class="title">{{ deliveryDate.name }} LIMIT</span>
              </v-card-title>
            </v-flex>
            <v-flex xs9 pl-3>
              <v-text-field
                type="number"
                name="name"
                v-model="deliveryDate.value"
                :disabled="!isBtnClick.deliveryDate"
                :rules="[numberRule]"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn
                flat
                icon
                color="blue lighten-2"
                @click="isBtnClick.deliveryDate = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                flat
                icon
                color="red"
                v-if="isBtnClick.deliveryDate"
                @click="clear('ddLimit')"
              >
                <v-icon>clear</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.deliveryDate"
                @click="save(deliveryDate)"
                >Update</v-btn
              >
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <!-- invoice data -->
          <!-- <v-layout row wrap>
            <v-flex xs12>
              <v-card-title>
                <span class="title">{{ invoiceData.name }}</span>
              </v-card-title>
            </v-flex>
            <v-flex xs4 pl-3>
              <v-text-field
                name="title"
                label="Title"
                v-model="invoiceData.title"
                :disabled="!isBtnClick.invoiceData"
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field
                name="address"
                label="Address"
                v-model="invoiceData.address"
                :disabled="!isBtnClick.invoiceData"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn flat icon color="blue lighten-2" @click="isBtnClick.invoiceData = true">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.invoiceData"
                @click="save(invoiceData)"
              >Update</v-btn>
            </v-flex>
            <v-flex xs3 pl-3>
              <v-text-field
                name="phone no"
                label="Phone Number"
                v-model="invoiceData.phoneNo"
                :disabled="!isBtnClick.invoiceData"
              ></v-text-field>
            </v-flex>
          </v-layout> -->
          <v-divider></v-divider>
          <!-- delivery fee -->
          <v-layout row wrap pt-3 v-if="!isAdmin">
            <v-flex xs12>
              <span class="title pl-3">{{ deliveryFee.name }}</span>
              ({{ deliveryFee.status }})
            </v-flex>
            <v-flex xs3 pl-3>
              <v-text-field
                name="name"
                v-model="deliveryFee.totalAmount"
                label="Total Amount"
                :disabled="!isBtnClick.deliveryFee"
              ></v-text-field>
            </v-flex>
            <v-flex xs3 pl-3>
              <v-text-field
                name="info"
                v-model="deliveryFee.info"
                label="DeliveryFee Information"
                :disabled="!isBtnClick.deliveryFee"
              ></v-text-field>
            </v-flex>
            <v-flex v-if="isBtnClick.deliveryFee" xs3 pl-5>
              <v-switch
                :label="`Status is ${
                  statusFlag.deliveryFee ? 'Active' : 'Inactive'
                }`"
                :value="statusFlag.deliveryFee"
                v-model="statusFlag.deliveryFee"
              ></v-switch>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn
                flat
                icon
                color="blue lighten-2"
                @click="isBtnClick.deliveryFee = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                flat
                icon
                color="red"
                v-if="isBtnClick.deliveryFee"
                @click="clear('delFee')"
              >
                <v-icon>clear</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.deliveryFee"
                @click="save(deliveryFee)"
                >Update</v-btn
              >
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <!-- invoice data -->
          <v-layout row wrap v-if="!isAdmin">
            <v-flex xs12>
              <v-card-title>
                <span class="title">{{ invoiceData.name }}</span>
              </v-card-title>
            </v-flex>
            <v-flex xs4 pl-3>
              <v-text-field
                name="title"
                label="Title"
                v-model="invoiceData.title"
                :disabled="!isBtnClick.invoiceData"
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field
                name="address"
                label="Address"
                v-model="invoiceData.address"
                :disabled="!isBtnClick.invoiceData"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn
                flat
                icon
                color="blue lighten-2"
                @click="isBtnClick.invoiceData = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                flat
                icon
                color="red"
                v-if="isBtnClick.invoiceData"
                @click="clear('invData')"
              >
                <v-icon>clear</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.invoiceData"
                @click="save(invoiceData)"
                >Update</v-btn
              >
            </v-flex>
            <v-flex xs3 pl-3>
              <v-text-field
                name="phone no"
                label="Phone Number"
                v-model="invoiceData.phoneNo"
                :disabled="!isBtnClick.invoiceData"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <!-- opening time -->
          <v-layout row wrap pt-3>
            <v-flex xs12>
              <span class="title pl-3">{{ openingTime.name }}</span>
              ({{ openingTime.status }})
            </v-flex>

            <v-flex xs6 pl-3>
              <v-text-field
                name="name"
                v-model="openingTime.schedule"
                label="Schedule"
                :disabled="!isBtnClick.openingTime"
              ></v-text-field>
            </v-flex>
            <v-flex v-if="isBtnClick.openingTime" xs3 pl-5>
              <v-switch
                :label="`Status is ${
                  statusFlag.openingTime ? 'Active' : 'Inactive'
                }`"
                :value="statusFlag.openingTime"
                v-model="statusFlag.openingTime"
              ></v-switch>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn
                flat
                icon
                color="blue lighten-2"
                @click="isBtnClick.openingTime = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                flat
                icon
                color="red"
                v-if="isBtnClick.openingTime"
                @click="clear('openTime')"
              >
                <v-icon>clear</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.openingTime"
                @click="save(openingTime)"
                >Update</v-btn
              >
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <!-- social link -->
          <v-flex xs12>
            <v-layout row wrap pt-2>
              <v-flex xs6 class="text-xs-left">
                <span class="title pl-3">{{ socialLinks.name }}</span>
                ({{ socialLinks.status }})
              </v-flex>

              <v-flex xs6 class="text-xs-right">
                <v-btn
                  flat
                  icon
                  color="blue lighten-2"
                  @click="isBtnClick.socialLinks = true"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
                <v-btn
                  flat
                  icon
                  color="red"
                  v-if="isBtnClick.socialLinks"
                  @click="clear('socialLinks')"
                >
                  <v-icon>clear</v-icon>
                </v-btn>
                <v-btn
                  color="success"
                  @click="addLink()"
                  :disabled="!isBtnClick.socialLinks"
                  >Add row</v-btn
                >
                <v-btn
                  dark
                  color="blue lighten-2"
                  :disabled="!isBtnClick.socialLinks"
                  @click="save(socialLinks)"
                  >Update</v-btn
                >
              </v-flex>
              <v-flex v-if="isBtnClick.socialLinks">
                <v-switch
                  :label="`Status is ${
                    statusFlag.socialLinks ? 'Active' : 'Inactive'
                  }`"
                  :value="statusFlag.socialLinks"
                  v-model="statusFlag.socialLinks"
                ></v-switch>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
              v-for="(social, index) in links"
              :key="index"
              pa-3
            >
              <v-flex xs5>
                <v-text-field
                  label="name"
                  v-model="social.name"
                  :disabled="!isBtnClick.socialLinks"
                ></v-text-field>
              </v-flex>
              <v-flex xs5>
                <v-text-field
                  label="Link"
                  v-model="social.link"
                  :disabled="!isBtnClick.socialLinks"
                ></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-btn
                  icon
                  @click="removeLink(index)"
                  :disabled="!isBtnClick.socialLinks || index === 0"
                >
                  <v-icon>cancel</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-divider></v-divider>
          <!-- hotline -->
          <v-flex xs12>
            <v-layout row wrap pt-2>
              <v-flex xs6 class="text-xs-left">
                <span class="title pl-3">{{ hotline.name }}</span>
                ({{ hotline.status }})
              </v-flex>

              <v-flex xs6 class="text-xs-right">
                <v-btn
                  flat
                  icon
                  color="blue lighten-2"
                  @click="isBtnClick.hotline = true"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
                <v-btn
                  flat
                  icon
                  color="red"
                  v-if="isBtnClick.hotline"
                  @click="clear('hotline')"
                >
                  <v-icon>clear</v-icon>
                </v-btn>
                <v-btn
                  color="success"
                  @click="addHotLine()"
                  :disabled="!isBtnClick.hotline"
                  >Add row</v-btn
                >
                <v-btn
                  dark
                  color="blue lighten-2"
                  :disabled="!isBtnClick.hotline"
                  @click="save(hotline)"
                  >Update</v-btn
                >
              </v-flex>
              <v-flex v-if="isBtnClick.hotline">
                <v-switch
                  :label="`Status is ${
                    statusFlag.hotline ? 'Active' : 'Inactive'
                  }`"
                  :value="statusFlag.hotline"
                  v-model="statusFlag.hotline"
                ></v-switch>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
              v-for="(item, index) in phones"
              :key="index"
              pa-3
            >
              <v-flex xs5>
                <v-text-field
                  label="name"
                  v-model="item.name"
                  :disabled="!isBtnClick.hotline"
                ></v-text-field>
              </v-flex>
              <v-flex xs5>
                <v-text-field
                  label="phone"
                  v-model="item.phone"
                  :disabled="!isBtnClick.hotline"
                ></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-btn
                  icon
                  @click="removeHotLine(index)"
                  :disabled="!isBtnClick.hotline || index === 0"
                >
                  <v-icon>cancel</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- category -->
          <v-layout row wrap>
            <v-flex xs12>
              <v-card-title>
                <span class="title">{{ categories.name }}</span>
              </v-card-title>
            </v-flex>
            <v-flex xs4 pl-3>
              <v-select
                :items="categoryList"
                multiple
                v-model="categories.list"
                item-value="name"
                item-text="name"
                label="Home Cateogries"
                :disabled="!isBtnClick.category"
              ></v-select>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn
                flat
                icon
                color="blue lighten-2"
                @click="isBtnClick.category = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.category"
                @click="save(categories)"
                >Update</v-btn
              >
            </v-flex>
          </v-layout>
          <!-- promotion -->
          <!-- <v-layout row wrap>
            <v-flex xs12>
              <v-card-title>
                <span class="title">{{ promotion.name }}</span>
              </v-card-title>
            </v-flex>
            <v-flex xs4 pl-3>
              <ImageUpload
                :imageUrl="promotion.imageUrl"
                :disabled="!isBtnClick.promotion"
                @change="
                  (data) => {
                    promotion.image = data;
                  }
                "
              />
            </v-flex>
            <v-flex xs4>
              <v-text-field
                name="name"
                label="Name"
                v-model="promotion.title"
                :disabled="!isBtnClick.promotion"
              ></v-text-field>
              <v-textarea
                name="name"
                auto-grow
                label="Description"
                v-model="promotion.description"
                :disabled="!isBtnClick.promotion"
                counter="1000"
              ></v-textarea>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs3>
              <v-btn
                flat
                icon
                color="blue lighten-2"
                @click="isBtnClick.promotion = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                dark
                color="blue lighten-2"
                :disabled="!isBtnClick.promotion"
                @click="save(promotion)"
                >Update</v-btn
              >
            </v-flex>
          </v-layout> -->
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { systemSettingService } from "../../services/system-setting-service";
import { commonService } from "../../services/common-service";
import { categoryService } from "../../services/category-service";

export default {
  data: () => ({
    user: {},
    isAdmin: false,
    numberRule: (v) => {
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
      return "Delivery Date Limit has to be number";
    },
    breadcrumbs: [
      {
        text: "General",
        disabled: true,
      },
      {
        text: "System Setting",
        disabled: true,
      },
    ],
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 50,
      sortBy: "name",
      totalItems: 0,
    },
    queryParams: {
      name: "",
      value: "",
    },
    statusFlag: {
      socialLinks: true,
      deliveryFee: true,
      hotline: true,
      openingTime: true,
    },
    isBtnClick: {
      address: false,
      logo: false,
      favicon: false,
      phoneNo: false,
      deliveryFee: false,
      copyright: false,
      invoiceData: false,
      socialLinks: false,
      hotline: false,
      openingTime: false,
      deliveryDate: false,
      category: false,
      promotion: false,
    },
    oldData: {
      address: "",
      phoneNo: "",
      deliveryDateLimit: "",
      deliveryFee: "",
      openingTime: "",
      invoiceData: "",
      socialLinks: "",
      hotline: "",
    },
    address: {},
    logo: {},
    favicon: {},
    phoneNo: {},
    deliveryFee: {},
    deliveryDate: {},
    copyright: {},
    socialLinks: {},
    invoiceData: {},
    hotline: {},
    openingTime: {},
    links: [],
    phones: [],
    categories: [],
    promotion: [],
    errMessage: "",
    categoryList: [],
  }),

  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user.role.roleName == "ADMIN") this.isAdmin = true;
    this.getSettingList();
    this.getCategories();
  },
  mounted() {},
  methods: {
    async getSettingList() {
      try {
        let settingList = [];
        let order = this.pagination.descending ? "desc" : "asc";
        let res = await systemSettingService.getSettings(
          this.queryParams,
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.pagination.sortBy + "," + order
        );
        settingList = res.data.systemSettingList;
        this.address = settingList.filter((setting) => {
          return setting.name == "ADDRESS";
        })[0];
        this.oldData.address = this.address.value;
        this.logo = settingList.filter((setting) => {
          return setting.name == "LOGO";
        })[0];
        this.favicon = settingList.filter((setting) => {
          return setting.name == "FAVICON";
        })[0];
        // update favicon
        var link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/png";
        link.rel = "shortcut icon";
        link.href = this.favicon.value;
        document.getElementsByTagName("head")[0].appendChild(link);
        // phoneno
        this.phoneNo = settingList.filter((setting) => {
          return setting.name == "PHONE_NO";
        })[0];
        this.oldData.phoneNo = this.phoneNo.value;
        // delivery fee
        this.deliveryFee = settingList.filter((setting) => {
          return setting.name == "DELIVERY_FEE";
        })[0];
        let _tmpObj = JSON.parse(this.deliveryFee.value);
        this.deliveryFee.status = _tmpObj.status;
        this.deliveryFee.totalAmount = _tmpObj.totalAmount;
        this.deliveryFee.info = _tmpObj.info;
        this.oldData.deliveryFee = Object.assign({}, this.deliveryFee);
        this.statusFlag.deliveryFee =
          this.deliveryFee.status == "ACTIVE" ? true : false;
        // copyright
        this.copyright = settingList.filter((setting) => {
          return setting.name == "COPYRIGHT";
        })[0];
        let _tmp = JSON.parse(this.copyright.value);
        this.copyright.year = _tmp.year;
        this.copyright.companyName = _tmp.text;
        this.copyright.link = _tmp.link;
        // social link
        this.socialLinks = settingList.filter((setting) => {
          return setting.name == "SOCIAL_LINKS";
        })[0];
        let _temp = JSON.parse(this.socialLinks.value);
        this.socialLinks.status = _temp.status;
        this.socialLinks.links = _temp.links;
        this.links = Object.assign([], _temp.links);
        this.statusFlag.socialLinks =
          this.socialLinks.status == "ACTIVE" ? true : false;
        this.oldData.socialLinks = Object.assign({}, this.socialLinks);

        // hotline
        this.hotline = settingList.filter((setting) => {
          return setting.name == "HOTLINE";
        })[0];
        let _hot = JSON.parse(this.hotline.value);
        this.hotline.status = _hot.status;
        this.hotline.phones = _hot.phones;
        this.phones = _hot.phones;
        this.statusFlag.hotline =
          this.hotline.status == "ACTIVE" ? true : false;
        this.oldData.hotline = Object.assign({}, this.hotline);
        // openingTime
        this.openingTime = settingList.filter((setting) => {
          return setting.name == "OPENING_TIME";
        })[0];
        let _opt = JSON.parse(this.openingTime.value);
        this.openingTime.status = _opt.status;
        this.openingTime.schedule = _opt.schedule;
        this.oldData.openingTime = Object.assign({}, this.openingTime);
        this.statusFlag.openingTime =
          this.openingTime.status == "ACTIVE" ? true : false;
        // invoiceData
        this.invoiceData = settingList.filter((setting) => {
          return setting.name == "INVOICE_DATA";
        })[0];
        let _invData = JSON.parse(this.invoiceData.value);
        this.invoiceData.title = _invData.title;
        this.invoiceData.address = _invData.address;
        this.invoiceData.phoneNo = _invData.phoneNo;
        this.oldData.invoiceData = Object.assign({}, this.invoiceData);
        // deliver date
        this.deliveryDate = settingList.filter((setting) => {
          return setting.name == "DELIVERY_DATE";
        })[0];
        this.oldData.deliveryDateLimit = this.deliveryDate.value;
        // category
        this.categories = settingList.filter((setting) => {
          return setting.name == "CATEGORY";
        })[0];
        let _categories = JSON.parse(this.categories.value);
        this.categories.list = _categories.list;
        // promotion
        // this.promotion = settingList.filter((setting) => {
        //   return setting.name == "PROMOTION";
        // })[0];

        // let _promotion = JSON.parse(this.promotion.value);
        // this.promotion.title = _promotion.name;
        // this.promotion.description = _promotion.description;
        // this.promotion.imageUrl = _promotion.imageUrl;
      } catch (error) {
        console.log(error);
      }
    },

    save(updateValue) {
      let _checkName = updateValue.name;
      if (_checkName == "COPYRIGHT") {
        let _obj = JSON.parse(updateValue.value);
        _obj.year = updateValue.year;
        _obj.text = updateValue.companyName;
        _obj.link = updateValue.link;
        updateValue.value = JSON.stringify(_obj);
      } else if (_checkName == "SOCIAL_LINKS") {
        updateValue.status = this.statusFlag.socialLinks
          ? "ACTIVE"
          : "INACTIVE";
        let _obj = JSON.parse(updateValue.value);
        _obj.status = updateValue.status;
        _obj.links = updateValue.links;
        updateValue.value = JSON.stringify(_obj);
      } else if (_checkName == "DELIVERY_FEE") {
        updateValue.status = this.statusFlag.deliveryFee
          ? "ACTIVE"
          : "INACTIVE";
        let _obj = JSON.parse(updateValue.value);
        _obj.status = updateValue.status;
        _obj.totalAmount = updateValue.totalAmount;
        _obj.info = updateValue.info;
        updateValue.value = JSON.stringify(_obj);
      } else if (_checkName == "HOTLINE") {
        updateValue.status = this.statusFlag.hotline ? "ACTIVE" : "INACTIVE";
        let _obj = JSON.parse(updateValue.value);
        _obj.status = updateValue.status;
        _obj.phones = updateValue.phones;
        updateValue.value = JSON.stringify(_obj);
      } else if (_checkName == "OPENING_TIME") {
        updateValue.status = this.statusFlag.openingTime
          ? "ACTIVE"
          : "INACTIVE";
        let _obj = JSON.parse(updateValue.value);
        _obj.status = updateValue.status;
        _obj.schedule = updateValue.schedule;
        updateValue.value = JSON.stringify(_obj);
      } else if (_checkName == "INVOICE_DATA") {
        let _obj = JSON.parse(updateValue.value);
        _obj.title = updateValue.title;
        _obj.address = updateValue.address;
        _obj.phoneNo = updateValue.phoneNo;
        updateValue.value = JSON.stringify(_obj);
      } else if (_checkName == "LOGO") {
        if (!this.logo.value) {
          delete this.logo["value"];
        }
      } else if (_checkName == "CATEGORY") {
        let _obj = { list: updateValue.list };
        updateValue.value = JSON.stringify(_obj);
      } else if (_checkName == "PROMOTION") {
        let _obj = JSON.parse(updateValue.value);
        _obj.name = updateValue.title;
        _obj.description = updateValue.description;
        _obj.image = updateValue.image ? updateValue.image : "";
        updateValue.value = JSON.stringify(_obj);
      }
      systemSettingService
        .updateSetting(updateValue)
        .then(() => {
          if (_checkName == "ADDRESS") {
            this.isBtnClick.address = false;
          } else if (_checkName == "LOGO") {
            this.isBtnClick.logo = false;
          } else if (_checkName == "FAVICON") {
            this.isBtnClick.favicon = false;
          } else if (_checkName == "PHONE_NO") {
            this.isBtnClick.phoneNo = false;
          } else if (_checkName == "DELIVERY_DATE") {
            this.isBtnClick.deliveryDate = false;
          } else if (_checkName == "DELIVERY_FEE") {
            this.isBtnClick.deliveryFee = false;
          } else if (_checkName == "COPYRIGHT") {
            this.isBtnClick.copyright = false;
          } else if (_checkName == "SOCIAL_LINKS") {
            this.isBtnClick.socialLinks = false;
          } else if (_checkName == "HOTLINE") {
            this.isBtnClick.hotline = false;
          } else if (_checkName == "OPENING_TIME") {
            this.isBtnClick.openingTime = false;
          } else if (_checkName == "INVOICE_DATA") {
            this.isBtnClick.invoiceData = false;
          } else if (_checkName == "CATEGORY") {
            this.isBtnClick.category = false;
          } else if (_checkName == "PROMOTION") {
            this.isBtnClick.promotion = false;
          }
          // this.getSettingList();
        })
        .catch((err) => {
          if (err.response.data.message == "image == null!") {
            this.errMessage = "Invalid image format => img/png/jpg";
          }
          console.log("dd", this.errMessage);
        });
    },
    addLink() {
      // deep copy
      let dc = JSON.parse(
        JSON.stringify({
          name: "",
          link: "",
        })
      );
      this.links.push(dc);
    },
    removeLink(index) {
      this.$delete(this.links, index);
    },
    addHotLine() {
      // deep copy
      let dc = JSON.parse(
        JSON.stringify({
          name: "",
          phone: "",
        })
      );
      this.phones.push(dc);
    },
    removeHotLine(index) {
      this.$delete(this.phones, index);
    },
    onFileChanged(event) {
      this.errMessage = "";
      const file = event.target.files[0];
      this.logo.value = URL.createObjectURL(file); // to show preview image
      commonService.getBase64(file).then((data) => {
        // this.logo.image = data;
        this.logo.value = data;
      });
    },
    onFaviconChanged(event) {
      this.errMessage = "";
      const file = event.target.files[0];
      this.favicon.value = URL.createObjectURL(file); // to show preview image
      commonService.getBase64(file).then((data) => {
        // this.logo.image = data;
        this.favicon.value = data;
      });
    },
    onPromotionImageChanged(event) {
      this.errMessage = "";
      const file = event.target.files[0];
      this.promotion.imageUrl = URL.createObjectURL(file); // to show preview image
      commonService.getBase64(file).then((data) => {
        // this.image = data;
        this.promotion.image = data;
      });
    },

    clear(setting) {
      let delay = new Promise((resolve) => setTimeout(resolve, 380));

      if (setting === "add") {
        this.address.value = this.oldData.address;
        this.isBtnClick.address = false;
      } else if (setting === "phNo") {
        this.phoneNo.value = this.oldData.phoneNo;
        this.isBtnClick.phoneNo = false;
      } else if (setting === "ddLimit") {
        this.deliveryDate.value = Number.parseInt(
          this.oldData.deliveryDateLimit
        );
        this.isBtnClick.deliveryDate = false;
      } else if (setting === "delFee") {
        this.deliveryFee = {};
        delay.then(() => {
          this.deliveryFee = Object.assign({}, this.oldData.deliveryFee);
        });
        this.statusFlag.deliveryFee =
          this.deliveryFee.status == "ACTIVE" ? true : false;
        this.isBtnClick.deliveryFee = false;
      } else if (setting === "openTime") {
        this.openingTime = {};
        delay.then(() => {
          this.openingTime = Object.assign({}, this.oldData.openingTime);
        });
        this.statusFlag.openingTime =
          this.openingTime.status == "ACTIVE" ? true : false;
        this.isBtnClick.openingTime = false;
      } else if (setting === "invData") {
        this.invoiceData = {};
        delay.then(() => {
          this.invoiceData = Object.assign({}, this.oldData.invoiceData);
        });
        this.isBtnClick.invoiceData = false;
      } else if (setting == "socialLinks") {
        this.socialLinks = Object.assign({}, this.oldData.socialLinks);
        let _temp = JSON.parse(this.socialLinks.value);
        this.socialLinks.status = _temp.status;
        this.socialLinks.links = _temp.links;
        this.links = Object.assign([], _temp.links);
        this.statusFlag.socialLinks =
          this.socialLinks.status == "ACTIVE" ? true : false;
        this.isBtnClick.socialLinks = false;
      } else if (setting == "hotline") {
        this.hotline = Object.assign({}, this.oldData.hotline);
        let _hot = JSON.parse(this.hotline.value);
        this.hotline.status = _hot.status;
        this.hotline.phones = _hot.phones;
        this.phones = _hot.phones;
        this.statusFlag.hotline =
          this.hotline.status == "ACTIVE" ? true : false;
        this.isBtnClick.hotline = false;
      }
    },
    getCategories() {
      categoryService
        .getCategoriesByStatus("ACTIVE")
        .then((res) => {
          this.categoryList = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

